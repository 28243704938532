import {
  $normalizedAccountInfo,
  AccountAssets,
  EditProfile,
  fetchAccountInfoFx,
} from "@entities/account";
import { AccountStatCard } from "@entities/account/ui/AccountStatCard";
import { Button, Content, Icon, PrimaryLayout, SecondaryLayout, Skeleton, Typography } from "@ui";
import { useStore } from "effector-react/scope";
import { $defaultAccount } from "shared/libs/effector-metamask";
import { PrimaryHeader } from "widgets/PrimaryHeader";

import "./model";
import styles from "./styles.module.scss";

export const Profile = () => {
  const accountInfo = useStore($normalizedAccountInfo);
  const accountInfoLoading = useStore(fetchAccountInfoFx.pending);
  const defaultAccount = useStore($defaultAccount);

  return (
    <PrimaryLayout header={<PrimaryHeader />}>
      <Content className={styles.content}>
        <div className={styles.headline}>
          <div>
            <Skeleton active={accountInfoLoading}>
              <Typography weight="medium" kind="title1">
                {accountInfo?.nickname || "Anonymous"}
              </Typography>
            </Skeleton>
            <Typography className={styles.profileAddress} kind="body1">
              {defaultAccount}
            </Typography>
          </div>

          <div className={styles.headlineButtons}>
            <a
              target="_blank"
              rel="noreferrer nofollow"
              href={`https://opensea.io/assets?search[query]=${defaultAccount}`}
            >
              <Button size="extra-small" kind="simple" appendBefore={<Icon.Opensea />}>
                Opensea
              </Button>
            </a>

            {<EditProfile />}
          </div>
        </div>
        <section className={styles.stats}>
          <div className={styles.statsLayout}>
            <AccountStatCard
              kind="secondary"
              loading={accountInfoLoading}
              title="Borrowed"
              weight="bold"
              values={[accountInfo?.timesBorrowed]}
            />

            <AccountStatCard
              kind="secondary"
              loading={accountInfoLoading}
              title="Lent"
              weight="bold"
              values={[accountInfo?.timesLoansGiven]}
            />

            <AccountStatCard
              kind="secondary"
              loading={accountInfoLoading}
              title="Repayment ratio"
              weight="bold"
              values={[accountInfo?.repaymentRatio + "%"]}
            />

            <AccountStatCard
              kind="secondary"
              loading={accountInfoLoading}
              title="Avg borrowed"
              values={accountInfo?.avgBorrowed}
            />
            <AccountStatCard
              kind="secondary"
              loading={accountInfoLoading}
              title="Avg loan size"
              values={accountInfo?.avgLent}
            />

            <AccountStatCard
              kind="secondary"
              title="Avg loan size"
              loading={accountInfoLoading}
              weight="bold"
              values={[
                {
                  title: "Repaid",
                  value: accountInfo?.timesRepaid,
                },
                {
                  title: "Defaulted",
                  value: accountInfo?.timesDefaulted,
                },
              ]}
            />

            <AccountStatCard
              kind="secondary"
              loading={accountInfoLoading}
              title="Loan activity"
              weight="bold"
              values={[
                {
                  title: "Loans given",
                  value: accountInfo?.timesRepaid,
                },
                {
                  title: "Borrowed",
                  value: accountInfo?.timesLoansGiven,
                },
              ]}
            />
          </div>
        </section>
      </Content>
      <SecondaryLayout title="Listed Assets">
        <AccountAssets className={styles.cards} />
      </SecondaryLayout>
    </PrimaryLayout>
  );
};
