import { Pages } from "@pages";

import "./styles/index.scss";
import { useEvent } from "effector-react/scope";
import { useEffect } from "react";
import "./model";
import { clientStarted } from "shared/libs/client-started";

export const Application = () => {
  const handlers = useEvent({ clientStarted });

  useEffect(() => {
    handlers.clientStarted();
  }, []);

  return <Pages />;
};
