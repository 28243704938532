import { EntitiesService } from "@api/entitiesService";
import { Coin } from "@api/entitiesService/types";
import { createEffect, createEvent, createStore, sample } from "effector";

const fetchCoinsFx = createEffect(EntitiesService.fetchCoins);
const getCoins = createEvent();

const $coins = createStore<Coin[]>([]);

const $coinsMap = $coins.map(
  (coins) =>
    coins.reduce((acc, coin) => {
      acc[coin.contractAddress] = coin;

      return acc;
    }, {}) as Record<string, Coin>,
);

const $coinsIsPending = fetchCoinsFx.pending;

$coins.on(fetchCoinsFx.doneData, (_, data) => data);

sample({
  clock: getCoins,
  source: {
    coins: $coins,
    isLoading: fetchCoinsFx.pending,
  },
  filter: ({ coins, isLoading }) => coins.length === 0 && !isLoading,
  target: fetchCoinsFx,
});

const getCoinByAddress = (address: string) => {
  const coins = $coins.getState();
  const coin = coins.find((coin) => coin.contractAddress === address);

  return coin || "unknown coin";
};

export { getCoins, $coins, $coinsMap, $coinsIsPending, getCoinByAddress };
