import { EmptyList } from "@ui";
import { useEvent, useGate, useStore } from "effector-react/scope";
import { IntersectionObserver } from "shared/libs/intersection-observer";

import { AssetCard } from "../../../../entities/assets";
import {
  $ownAssets,
  $ownAssetsLoading,
  ownAssetsListGate,
  fetchMoreOwnAssetsFx,
  $ownAssetsLoaded,
} from "../../model";

import styles from "./styles.module.scss";

export const OwnAssets = () => {
  const ownAssets = useStore($ownAssets);
  const isLoading = useStore($ownAssetsLoading);
  const loaded = useStore($ownAssetsLoaded);

  useGate(ownAssetsListGate, []);

  const handlers = useEvent({
    fetchMoreOwnAssetsFx,
  });

  if (!isLoading && ownAssets.length === 0)
    return <EmptyList firstLine="We will show here your NFTs" />;

  const loadingView =
    isLoading && Array.from({ length: 12 }, (_, i) => <AssetCard key={i} loading />);

  const defaultView = ownAssets.map((asset) => (
    <AssetCard
      status={asset.status}
      key={`${asset.contractAddress}/${asset.tokenID}`}
      image={asset.imageURL || ""}
      title={asset.collectionName}
      subtitle={asset.name}
      tokenId={asset.tokenID}
      contractAddress={asset.contractAddress}
    />
  ));

  return (
    <IntersectionObserver
      onIntersect={handlers.fetchMoreOwnAssetsFx}
      disabled={isLoading || loaded}
    >
      {(ref) => (
        <div className={styles.listLayout}>
          {defaultView}
          {loadingView}
          <span ref={ref} />
        </div>
      )}
    </IntersectionObserver>
  );
};
