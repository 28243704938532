import axios from "axios";
import { runtimeEnv } from "@configs/runTimeEnv";
import { authWithMetamask } from "shared/libs/auth-with-metamask";
import { getCurrentChain, getDefaultAccount } from "shared/libs/effector-metamask";
import { httpCodes } from "shared/libs/http-codes";
import qs from "qs";

const baseURL =
  runtimeEnv.mode === "development"
    ? `http://localhost:${process.env.PORT}/api`
    : `${runtimeEnv.baseUrl}/api`;

const httpClient = axios.create({
  baseURL,
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken",
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
  },
});

httpClient.interceptors.request.use((request) => {
  request.headers = {
    ...request.headers,
  };

  const xAccount = getDefaultAccount();
  const xChainId = getCurrentChain();

  if (xAccount) request.headers["X-Account"] = xAccount;

  if (xChainId !== null && !("X-Chain-ID" in request.headers)) {
    request.headers["X-Chain-ID"] = xChainId;
  }

  // TODO: remove default chain as soon as backend will implement it
  if (request.headers["X-Chain-ID"] === "all") {
    request.headers["X-Chain-ID"] = runtimeEnv.defaultChainId;
  }

  return request;
});

httpClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    if (!axios.isAxiosError(error)) throw error;

    if (error.response?.status === httpCodes.unauthorized) {
      await authWithMetamask({
        nonceUrl: `/auth/nonce`,
        authUrl: `/auth/session`,
        httpClient,
      });

      return axios.request(error.response.config);
    }

    throw error;
  },
);

export { httpClient };
