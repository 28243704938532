import { RefObject, useEffect, useRef } from "react";

type Props = {
  disabled?: boolean;
  onIntersect: () => void;
  children: (ref?: RefObject<any>) => JSX.Element;
  // eslint-disable-next-line no-undef
} & IntersectionObserverInit;

export const IntersectionObserver = ({
  children,
  onIntersect,
  disabled = false,
  threshold = 0,
  root = null,
  rootMargin = "0%",
}: Props) => {
  const elementRef = useRef<Element>(null);

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    if (entry.isIntersecting) {
      onIntersect();
    }
  };

  useEffect(() => {
    const node = elementRef?.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || !node || disabled) return;

    const observer = new window.IntersectionObserver(updateEntry, {
      rootMargin,
      root,
      threshold,
    });

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, threshold, root, rootMargin, disabled]);

  return children(elementRef);
};
