import { routesMap } from "@configs/routing";
import { RevokeOffer } from "@features/offers";
import { $normalizedOwnOffers, ownOffers } from "@entities/offers/model";
import { Columns, Link, Table } from "@ui";
import { useStore } from "effector-react";
import { useEvent } from "effector-react/scope";
import { useEffect, useMemo } from "react";
import { NormalizedOffer } from "shared/libs/normalizeOffer";

export const OwnOffers = () => {
  const offers = useStore($normalizedOwnOffers);
  const pending = useStore(ownOffers.$pending);

  const columns = useMemo(() => {
    const data: Columns<NormalizedOffer> = [
      {
        title: "Asset",
        dataIndex: "collateral.tokenID",
        render: (row) => (
          <Link
            to={routesMap.assetDetails}
            params={{
              tokenId: row.collateral.tokenID,
              contractAddress: row.collateral.contractAddress,
            }}
          >
            {row.collateral.name}
          </Link>
        ),
      },
      {
        title: "Offered to",
        dataIndex: "lender.nickname",
      },
      {
        title: "Duration",
        dataIndex: "duration",
      },
      {
        title: "Loan value",
        dataIndex: "amount",
      },
      {
        title: "Repayment",
        dataIndex: "amountWithAPR",
      },
      {
        title: "APR",
        dataIndex: "apr",
      },
      {
        title: "",
        dataIndex: "nonce",
        render: (row) => <RevokeOffer offerId={row.id} nonce={row.nonce} />,
      },
    ];

    return data;
  }, []);

  const handlers = useEvent({
    get: ownOffers.get,
    reset: ownOffers.reset,
    getMore: ownOffers.getMore,
  });

  useEffect(() => {
    handlers.get();

    return () => handlers.reset();
  }, []);

  return (
    <Table
      onLoanMore={handlers.getMore}
      loading={pending}
      defaultRowsCount={5}
      data={offers}
      columns={columns}
      noDataText="You haven't made offers yet"
      indexKey="nonce"
    />
  );
};
