import { interval } from "patronum/interval";
import { directLoanFixedOfferService } from "@api/directLoanFixedOfferService";
import { LoansService } from "@api/loansService";
import { Loan } from "@api/loansService/types";
import { $coinsMap } from "@entities/coins";
import { $directLoanFixedOfferContract } from "@entities/lend/model";
import { attach, combine, createEffect, createEvent, createStore, merge, sample } from "effector";
import { createGate } from "effector-react";
import { getAddress } from "ethers/lib/utils";
import { createEffectState } from "shared/libs/create-effect-state";
import { createDataList } from "shared/libs/create-data-list";
import { FetchDataListParams } from "shared/libs/create-data-list/types";
import { $defaultAccount } from "shared/libs/effector-metamask";
import { normalizeLoan } from "../../../shared/libs/normalize-loan";
import { calculateLeftTime } from "shared/libs/calculate-left-time";
import { weiToEth } from "shared/libs/wei-to-eth";
import { calculateAPR } from "shared/libs/calculate-apr";
import { $assetContractAddress, $assetTokenId } from "@entities/assets";
import { createTxEffect } from "shared/libs/create-tx-effect";
import { FetchAssetLoansFxParams } from "@features/loans/model/types";
import { secondsToDays } from "shared/libs/format-days";
import { precisions, primaryLazyLoadLimit } from "@configs/constants";

const fetchOwnActiveLoansFx = attach({
  source: $defaultAccount,
  effect: (borrower, params: FetchDataListParams) =>
    LoansService.getAll({
      borrower,
      state: ["Active", "Expired"],
      ...params,
    }),
});

//#region Own active loans
const ownActiveLoansGate = createGate();

const ownActiveLoans = createDataList({
  effect: fetchOwnActiveLoansFx,
  limit: primaryLazyLoadLimit,
});

const $normalizedOwnActiveLoans = combine(
  ownActiveLoans.$data,
  $coinsMap,
  $defaultAccount,
  (data, coins, account) => data.map((loan) => normalizeLoan({ loan, coins, account })),
);

sample({
  clock: [ownActiveLoansGate.open, $defaultAccount],
  filter: ownActiveLoansGate.status,
  target: ownActiveLoans.get,
});

sample({
  clock: ownActiveLoansGate.close,
  target: ownActiveLoans.reset,
});
//#endregion

//#region own loans history
const ownLoansHistoryGate = createGate();

const fetchOwnLoansHistoryFx = attach({
  source: $defaultAccount,
  effect: (borrower, params: FetchDataListParams) =>
    LoansService.getAll({
      borrower,
      state: ["Repaid", "Defaulted"],
      ...params,
    }),
});

const ownLoansHistory = createDataList({
  effect: fetchOwnLoansHistoryFx,
  limit: primaryLazyLoadLimit,
});

const $normalizedOwnLoansHistory = combine(
  ownLoansHistory.$data,
  $coinsMap,
  $defaultAccount,
  (data, coins, account) => data.map((loan) => normalizeLoan({ loan, coins, account })),
);

sample({
  clock: [ownLoansHistoryGate.open, $defaultAccount],
  filter: ownLoansHistoryGate.status,
  target: ownLoansHistory.get,
});

sample({
  clock: ownLoansHistoryGate.close,
  target: ownLoansHistory.reset,
});
//#endregion

//#region Active Debts
const ownActiveDebtsGate = createGate();

const fetchActiveDebts = attach({
  source: $defaultAccount,
  effect: (lender, params: FetchDataListParams) =>
    LoansService.getAll({
      lender,
      state: ["Active", "Expired"],
      ...params,
    }),
});

const ownActiveDebts = createDataList({
  effect: fetchActiveDebts,
  limit: primaryLazyLoadLimit,
});

const $normalizedOwnActiveDebts = combine(
  ownActiveDebts.$data,
  $coinsMap,
  $defaultAccount,
  (data, coins, account) => data.map((loan) => normalizeLoan({ loan, coins, account })),
);

sample({
  clock: [ownActiveDebtsGate.open, $defaultAccount],
  filter: ownActiveDebtsGate.status,
  target: ownActiveDebts.get,
});

sample({
  clock: ownActiveDebtsGate.close,
  target: ownActiveDebts.reset,
});
//#endregion

//#region  own debts history
const ownDebtsHistoryGate = createGate();

const fetchOwnDebtsHistory = attach({
  source: $defaultAccount,
  effect: (lender, params: FetchDataListParams) =>
    LoansService.getAll({
      lender,
      state: ["Repaid", "Defaulted"],
      ...params,
    }),
});

const ownDebtsHistory = createDataList({
  effect: fetchOwnDebtsHistory,
  limit: primaryLazyLoadLimit,
});

const $normalizedOwnDebtsHistory = combine(
  ownDebtsHistory.$data,
  $coinsMap,
  $defaultAccount,
  (data, coins, account) => data.map((loan) => normalizeLoan({ loan, coins, account })),
);

sample({
  clock: [ownDebtsHistoryGate.open, $defaultAccount],
  filter: ownDebtsHistoryGate.status,
  target: ownDebtsHistory.get,
});

sample({
  clock: ownDebtsHistoryGate.close,
  target: ownDebtsHistory.reset,
});
//#endregion

//#region Asset Loan
const fetchAssetLoanFx = createEffect(({ tokenID, contractAddress }: FetchAssetLoansFxParams) =>
  LoansService.get({
    state: ["Active", "Expired"],
    contractAddress,
    tokenID,
  }),
);

const updateAssetLoanFx = createEffect(({ tokenID, contractAddress }: FetchAssetLoansFxParams) =>
  LoansService.get({
    state: ["Active", "Expired"],
    contractAddress,
    tokenID,
  }),
);

const fetchAssetLoanState = createEffectState(fetchAssetLoanFx);

const resetAssetLoan = createEvent();
const $assetLoan = createStore<Loan | null>(null);

$assetLoan.reset(resetAssetLoan);

const $normalizedAssetLoan = combine($coinsMap, $assetLoan, (coinsMap, loan) => {
  if (!loan) return null;

  const creationDate = new Intl.DateTimeFormat("en-US", { month: "short", day: "2-digit" }).format(
    new Date(loan.createdAt * 1000),
  );

  const expirationDate = new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "2-digit",
  }).format(new Date(loan.createdAt * 1000));

  return {
    id: loan.loanID,
    amount: weiToEth(loan.terms.amount),
    amountWithAPR: weiToEth(loan.terms.amountWithAPR, {
      maxDigitsAfterComma: precisions.repayment,
    }),
    collateral: loan.collateral,
    coin: coinsMap[loan.terms.coinAddress],
    state: loan.state,
    creationDate,
    expirationDate,
    secondsLeft: (new Date(loan.expiresAt * 1000).getTime() - new Date().getTime()) / 1000,
    apr: calculateAPR({
      loan: weiToEth(loan.terms.amount),
      repayment: weiToEth(loan.terms.amountWithAPR, {
        maxDigitsAfterComma: precisions.repayment,
      }),
      days: secondsToDays(loan.terms.lendSeconds),
    }),
  };
});

const $assetLoanExpiration = createStore<null | false | string>(null);

const $assetLoanExpired = combine(
  $assetLoanExpiration,
  $assetLoan,
  (loanExpiration) => loanExpiration === false,
);

const startLoanExpirationCountdown = createEvent();
const stopLoanExpirationCountdown = createEvent();

const loanExpirationInterval = interval({
  timeout: 1000,
  start: startLoanExpirationCountdown,
  stop: stopLoanExpirationCountdown,
});

sample({
  source: $assetLoan,
  clock: [$assetLoan, loanExpirationInterval.tick],
  fn: (assetLoan) => assetLoan && calculateLeftTime(assetLoan.expiresAt),
  target: $assetLoanExpiration,
});

sample({
  clock: $assetLoan,
  filter: Boolean,
  target: startLoanExpirationCountdown,
});

sample({
  clock: $assetLoan,
  filter: (v) => !v,
  target: stopLoanExpirationCountdown,
});

sample({
  clock: $assetLoanExpired,
  filter: Boolean,
  target: stopLoanExpirationCountdown,
});

const $isAssetLoanLender = combine(
  $defaultAccount,
  $assetLoan,
  (defaulAccount, loan) =>
    loan && defaulAccount && getAddress(loan?.lender.address) === getAddress(defaulAccount),
);

$assetLoan
  .on(merge([updateAssetLoanFx.doneData, fetchAssetLoanFx.doneData]), (_, data) => data)
  .reset(resetAssetLoan);

//#endregion

//#region Liquidate Loan
const liquidateLoanFx = createTxEffect(
  attach({
    source: $directLoanFixedOfferContract,
    effect: async (contract, loanId: Loan["loanID"]) => {
      if (!contract) return Promise.reject();

      return directLoanFixedOfferService.liquidateLoan(contract, loanId);
    },
  }),
  {
    confirmationsCount: 1,
  },
);

const $liquidatingLoansIds = createStore<Record<Loan["loanID"], boolean>>({});

sample({
  source: $liquidatingLoansIds,
  clock: liquidateLoanFx,
  fn: (current, loanId) => ({
    ...current,
    [loanId]: true,
  }),
  target: $liquidatingLoansIds,
});

sample({
  source: $liquidatingLoansIds,
  clock: liquidateLoanFx.finally,
  fn: (current, { params }) => {
    const copy = { ...current };

    delete copy[params];

    return copy;
  },
  target: $liquidatingLoansIds,
});

//#region Asset loans
const fetchAssetLoansFx = attach({
  source: {
    tokenID: $assetTokenId,
    contractAddress: $assetContractAddress,
  },
  effect: ({ contractAddress, tokenID }, params: FetchDataListParams) => {
    if (contractAddress === null) return Promise.reject();

    return LoansService.getAll({
      contractAddress,
      tokenID,
      state: ["Defaulted", "Repaid"],
      ...params,
    });
  },
});

const assetLoans = createDataList({
  effect: fetchAssetLoansFx,
  limit: primaryLazyLoadLimit,
});

//#endregion

export {
  ownActiveLoans,
  ownLoansHistory,
  ownDebtsHistory,
  ownActiveDebts,
  $normalizedOwnActiveLoans,
  $normalizedOwnLoansHistory,
  $normalizedOwnActiveDebts,
  $normalizedOwnDebtsHistory,
  ownActiveLoansGate,
  ownLoansHistoryGate,
  ownActiveDebtsGate,
  ownDebtsHistoryGate,
  resetAssetLoan,
  $assetLoan,
  $isAssetLoanLender,
  fetchAssetLoanFx,
  liquidateLoanFx,
  $liquidatingLoansIds,
  $normalizedAssetLoan,
  $assetLoanExpiration,
  $assetLoanExpired,
  assetLoans,
  fetchAssetLoanState,
  updateAssetLoanFx,
};
