import { $isAssetOwner } from "@entities/assets";
import { $cancelingAssetsMap } from "@entities/listings";
import {
  $cancelAssetListingModalIsOpen,
  submitListingCancelation,
  revokeListingCancelation,
  $assetDetails,
  cancelListing,
} from "@features/assets/model";
import { Button, Modal, ModalFooter, Typography } from "@ui";
import { useStore } from "effector-react";
import { useEvent } from "effector-react/scope";

import styles from "./styles.module.scss";

export const RevokeListing = () => {
  const isOpen = useStore($cancelAssetListingModalIsOpen);
  const isAssetOwner = useStore($isAssetOwner);
  const assetDetails = useStore($assetDetails);

  const cancelingAssetsMap = useStore($cancelingAssetsMap);
  const handlers = useEvent({
    submitListingCancelation,
    revokeListingCancelation,
    cancelListing,
  });

  if (!assetDetails || !isAssetOwner) return null;

  const isCanceling = cancelingAssetsMap[assetDetails.id];

  return (
    <>
      <Modal
        title="Revoke listing"
        description="Are you sure you want to delete the listing?"
        isOpen={isOpen}
        renderFooter={
          <ModalFooter>
            <Button loading={isCanceling} onClick={handlers.submitListingCancelation}>
              Revoke
            </Button>
            <Button
              disabled={isCanceling}
              onClick={() => handlers.revokeListingCancelation()}
              kind="ghost"
            >
              No
            </Button>
          </ModalFooter>
        }
        onClose={handlers.revokeListingCancelation}
      >
        <Typography className={styles.termItem} kind="body1" weight="light">
          Your nft will be hidden from the issuance of the platform.
        </Typography>
        <Typography className={styles.termItem} kind="body1" weight="light">
          Offers can{"'"}t be submitted to your nft.
        </Typography>
        <Typography className={styles.termItem} kind="body1" weight="light">
          All existing offers will remain active until expiration or cancellation by the lender.
        </Typography>
      </Modal>

      {isAssetOwner && assetDetails.state === "Opened" && (
        <Button kind="secondary" onClick={handlers.cancelListing}>
          Revoke listing
        </Button>
      )}
    </>
  );
};
