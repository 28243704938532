/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  PermittedNFTsAndTypeRegistry,
  PermittedNFTsAndTypeRegistryInterface,
} from "../PermittedNFTsAndTypeRegistry";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_admin",
        type: "address",
      },
      {
        internalType: "address",
        name: "_Hub",
        type: "address",
      },
      {
        internalType: "string[]",
        name: "_definedNftTypes",
        type: "string[]",
      },
      {
        internalType: "address[]",
        name: "_definedNftWrappers",
        type: "address[]",
      },
      {
        internalType: "address[]",
        name: "_permittedNftContracts",
        type: "address[]",
      },
      {
        internalType: "string[]",
        name: "_permittedNftTypes",
        type: "string[]",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "nftContract",
        type: "address",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "nftType",
        type: "bytes32",
      },
    ],
    name: "NFTPermit",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "nftType",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "nftWrapper",
        type: "address",
      },
    ],
    name: "TypeUpdated",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_nftContract",
        type: "address",
      },
    ],
    name: "getNFTPermit",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_nftContract",
        type: "address",
      },
    ],
    name: "getNFTWrapper",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "_nftType",
        type: "bytes32",
      },
    ],
    name: "getNftTypeWrapper",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "hub",
    outputs: [
      {
        internalType: "contract IHub",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_nftContract",
        type: "address",
      },
      {
        internalType: "string",
        name: "_nftType",
        type: "string",
      },
    ],
    name: "setNFTPermit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "_nftContracts",
        type: "address[]",
      },
      {
        internalType: "string[]",
        name: "_nftTypes",
        type: "string[]",
      },
    ],
    name: "setNFTPermits",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "_nftType",
        type: "string",
      },
      {
        internalType: "address",
        name: "_nftWrapper",
        type: "address",
      },
    ],
    name: "setNftType",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string[]",
        name: "_nftTypes",
        type: "string[]",
      },
      {
        internalType: "address[]",
        name: "_nftWrappers",
        type: "address[]",
      },
    ],
    name: "setNftTypes",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class PermittedNFTsAndTypeRegistry__factory {
  static readonly abi = _abi;
  static createInterface(): PermittedNFTsAndTypeRegistryInterface {
    return new utils.Interface(_abi) as PermittedNFTsAndTypeRegistryInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): PermittedNFTsAndTypeRegistry {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as PermittedNFTsAndTypeRegistry;
  }
}
