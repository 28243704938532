import { ListingService } from "@api/listingService";
import { UserService } from "@api/userService";
import { UserInfo } from "@api/userService/types";
import { primaryLazyLoadLimit } from "@configs/constants";
import { routesMap } from "@configs/routing";
import { $coinsMap } from "@entities/coins";
import { attach, combine, createDomain, createEffect, createEvent, sample } from "effector";
import { calculatePercent } from "shared/libs/calculate-percent";
import { createDataList } from "shared/libs/create-data-list";
import { FetchDataListParams } from "shared/libs/create-data-list/types";
import { createEffectState } from "shared/libs/create-effect-state";
import { $defaultAccount } from "shared/libs/effector-metamask";
import { weiToEth } from "shared/libs/wei-to-eth";
import { AccountEditForm } from "./types";

const accountInfoDomain = createDomain();

const fetchAccountInfoFx = createEffect(UserService.getInfo);

const openAccountInfo = createEvent<string>();
const closeAccountInfo = createEvent();

const $accountInfo = accountInfoDomain.createStore<null | UserInfo>(null);
const $selectedAccountAddress = accountInfoDomain.createStore<null | string>(null);
const $accountInfoModalIsOpen = $selectedAccountAddress.map((value) => value !== null);
const fetchAccountInfoState = createEffectState(fetchAccountInfoFx);

const fetchProfileListings = attach({
  source: $defaultAccount,
  effect: (borrower, params: FetchDataListParams) =>
    ListingService.getAll({
      borrower,
      state: "Opened",
      ...params,
    }),
});

const profileListings = createDataList({
  domain: accountInfoDomain,
  effect: fetchProfileListings,
  limit: primaryLazyLoadLimit,
});

const $normalizedAccountInfo = combine($coinsMap, $accountInfo, (coinsMap, accountInfo) => {
  if (!accountInfo) return null;

  return {
    ...accountInfo,
    repaymentRatio: calculatePercent(accountInfo.timesRepaid, accountInfo.timesDefaulted).toFixed(
      2,
    ),
    totalBorrowed: accountInfo.totalBorrowed.map(
      (row) => `${weiToEth(row.sum)} ${coinsMap[row.coinContractAddress]?.currencySymbol}`,
    ),
    totalLent: accountInfo.totalLent.map(
      (row) => `${weiToEth(row.sum)} ${coinsMap[row.coinContractAddress]?.currencySymbol}`,
    ),
    avgBorrowed: accountInfo.avgBorrowed.map(
      (row) => `${weiToEth(row.sum)} ${coinsMap[row.coinContractAddress]?.currencySymbol}`,
    ),
    avgLent: accountInfo.avgLent.map(
      (row) => `${weiToEth(row.sum)} ${coinsMap[row.coinContractAddress]?.currencySymbol}`,
    ),
  };
});

const $accountNickname = $accountInfo.map((account) => account?.nickname || null);

sample({
  clock: openAccountInfo,
  target: [fetchAccountInfoFx, $selectedAccountAddress],
});

sample({
  clock: fetchAccountInfoFx.doneData,
  target: $accountInfo,
});

const editAccount = createEvent();
const closeAccountEditModal = createEvent();
const submitAccountEdit = createEvent<AccountEditForm>();
const $editAccountModalOpen = accountInfoDomain.createStore(false);

const updateAccountInfoFx = createEffect(UserService.updateInfo);

$editAccountModalOpen.on(editAccount, () => true).reset(closeAccountEditModal);

accountInfoDomain.onCreateStore((store) => store.reset(closeAccountInfo));

sample({
  clock: submitAccountEdit,
  target: updateAccountInfoFx,
});

sample({
  source: $accountInfo,
  clock: updateAccountInfoFx.done,
  fn: (accountInfo, { params }) => ({
    ...accountInfo!,
    nickname: params.nickname,
  }),
  target: [closeAccountEditModal, $accountInfo],
});

export {
  $accountInfoModalIsOpen,
  fetchAccountInfoState,
  $selectedAccountAddress,
  openAccountInfo,
  closeAccountInfo,
  $normalizedAccountInfo,
  fetchAccountInfoFx,
  profileListings,
  $editAccountModalOpen,
  editAccount,
  closeAccountEditModal,
  $accountNickname,
  submitAccountEdit,
  updateAccountInfoFx,
};
