import { routesMap } from "@configs/routing";
import { Link, Logo, Typography } from "@ui";

import styles from "./styles.module.scss";

const nav1 = [
  {
    title: "About the service",
    to: routesMap.about,
  },
  {
    title: "Explore",
    to: routesMap.explore,
  },
  {
    title: "Go to app",
    to: routesMap.userAssets,
  },
];

export const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.top}>
      <section>
        <Link to="/" className={styles.logo}>
          <Logo />
          <span className={styles.logoText}>Dip Ocean</span>
        </Link>

        <Typography className={styles.description} kind="body1">
          For business inquiries and collaboration opportunities contact us:{" "}
          <Link to="mailto:hello@dipocean.io">hello@dipocean.io</Link>
        </Typography>
      </section>

      <nav>
        <ul className={styles.list}>
          {nav1.map((nav) => (
            <li key={nav.title}>
              <Link className={styles.link} to={nav.to}>
                {nav.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>

    <div className={styles.bottom}>
      {/* <div>Socials links here</div> */}
      <div className={styles.copyright}>
        <Typography kind="body2">© 2022 Dip Ocean. All rights reserved.</Typography>
      </div>
    </div>
  </footer>
);
