import { AssetCard } from "@entities/assets";
import { listedAssets } from "@features/assets/model";
import { EmptyList } from "@ui";
import { useStore } from "effector-react";
import { useEvent } from "effector-react/scope";
import { BigNumber } from "ethers";
import { IntersectionObserver } from "shared/libs/intersection-observer";

import styles from "./styles.module.scss";

export const ListedAssets = () => {
  const data = useStore(listedAssets.$data);
  const isLoading = useStore(listedAssets.$pending);

  const handlers = useEvent({
    getMore: listedAssets.getMore,
  });

  if (!isLoading && data.length === 0) return <EmptyList />;

  const loadingView =
    isLoading && Array.from({ length: 12 }, (_, i) => <AssetCard key={i} loading />);

  const defaultView = data.map((asset) => (
    <AssetCard
      key={asset.id}
      image={asset.collateral.imageURL || undefined}
      title={asset.collateral.collectionName}
      subtitle={asset.collateral.name}
      tokenId={BigNumber.from(asset.collateral.tokenID).toString()}
      contractAddress={asset.collateral.contractAddress}
    />
  ));

  return (
    <IntersectionObserver onIntersect={handlers.getMore} disabled={isLoading}>
      {(ref) => (
        <div className={styles.listLayout}>
          {defaultView}
          {loadingView}
          <span ref={ref} />
        </div>
      )}
    </IntersectionObserver>
  );
};
