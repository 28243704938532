export const secondsInDay = 86400;
export const primaryLazyLoadLimit = 10;

export const precisions = {
  float: 6,
  displayFloat: 4,
  repayment: 10,
  percent: 2,
  minPrincipalAmount: 4,
};

export const minAmount = 0.0001;
