import { FunctionComponent, PropsWithChildren } from "react";
import styles from "./styles.module.scss";

type Props = PropsWithChildren<{
  header: JSX.Element;
}>;

export const PrimaryLayout: FunctionComponent<Props> = ({ header, children }) => (
  <div className={styles.layout}>
    {header}
    <div className={styles.content}>{children}</div>
  </div>
);
