import { OffersService } from "@api/offersService";
import { Offer } from "@api/offersService/types";
import {
  assetOffers,
  checkAccessesToAssetForListingFx,
  checkIsAssetInLoanFx,
  grantAccessToAssetFxToListFx,
  setAssetContractAddress,
} from "@entities/assets";
import { $directLoanFixedOfferContract } from "@entities/lend/model";
import { attach, combine, createDomain, createEvent, sample } from "effector";
import { BigNumber } from "ethers";
import { createDataList } from "shared/libs/create-data-list";
import { FetchDataListParams } from "shared/libs/create-data-list/types";
import { $defaultAccount } from "shared/libs/effector-metamask";
import { AcceptOfferStagesMap, AssetOffersTableRow } from "./types";
import { calculateAPR } from "shared/libs/calculate-apr";
import { $coins, $coinsMap } from "@entities/coins";
import { getAddress } from "ethers/lib/utils";
import { createEffectState } from "shared/libs/create-effect-state";
import { directLoanFixedOfferService } from "@api/directLoanFixedOfferService";
import { checkAllocatedFundsFx, checkBalanceOfFx } from "@entities/smart-contracts";
import { deleteOfferFx } from "@entities/offers/model/cancelOffer";
import { maskAddress } from "shared/libs/mask-address";
import { calculateLeftTime } from "shared/libs/calculate-left-time";
import { weiToEth } from "shared/libs/wei-to-eth";
import { pluralize } from "shared/libs/pluralize";
import { secondsToDays } from "shared/libs/format-days";
import { normalizeOffer } from "../../../shared/libs/normalizeOffer";
import { routesMap } from "@configs/routing";
import { ToastOptions } from "shared/libs/toast/types";
import { toast } from "shared/libs/toast";
import { precisions, primaryLazyLoadLimit } from "@configs/constants";
import { createTxEffect } from "shared/libs/create-tx-effect";

//#region Own Offers
const fetchOwnOffersFx = attach({
  source: $defaultAccount,
  effect: async (defaultAccount, params: FetchDataListParams) => {
    return OffersService.getAll({
      lender: defaultAccount,
      state: "Valid",
      ...params,
    });
  },
});

const ownOffers = createDataList({
  effect: fetchOwnOffersFx,
  limit: primaryLazyLoadLimit,
});

const $normalizedOwnOffers = combine(ownOffers.$data, $coinsMap, (offers, coins) =>
  offers.map((offer) =>
    normalizeOffer({
      offer,
      coins,
    }),
  ),
);

sample({
  source: ownOffers.$data,
  clock: deleteOfferFx.done,
  fn: (ownOffers, { params }) => ownOffers.filter((offer) => offer.id === params.id),
  filter: routesMap.offers.$isOpened,
  target: ownOffers.set,
});

//#endregion

//#region Received Offers
const fetchReceivedOffersFx = attach({
  source: $defaultAccount,
  effect: async (defaultAccount, params: FetchDataListParams) => {
    return OffersService.getAll({
      borrower: defaultAccount,
      state: "Valid",
      ...params,
    });
  },
});

const receivedOffers = createDataList({
  effect: fetchReceivedOffersFx,
  limit: primaryLazyLoadLimit,
});

const $normalizedReceivedOffers = combine(receivedOffers.$data, $coinsMap, (offers, coins) =>
  offers.map((offer) =>
    normalizeOffer({
      offer,
      coins,
    }),
  ),
);
//#endregion

//#region Accept offer
const acceptOfferDomain = createDomain();
const resetAcceptOfferDomain = createEvent();

const acceptOfferFx = createTxEffect(
  attach({
    source: $directLoanFixedOfferContract,
    effect: async (contract, acceptedOffer: Offer) => {
      if (!contract) return Promise.reject();

      return directLoanFixedOfferService.acceptOffer(contract, acceptedOffer);
    },
  }),
  {
    confirmationsCount: 1,
  },
);

const acceptOffer = createEvent<string>();
const confirmTerms = createEvent();
const grantAccessesToAsset = createEvent();
const confirmOfferAccept = createEvent();

const cancelOfferAccept = createEvent<void>();
const $acceptedOfferId = acceptOfferDomain.createStore("");

const setAcceptOfferStage = createEvent<number>();
const $acceptOfferStage = acceptOfferDomain.createStore(AcceptOfferStagesMap.terms);

$acceptOfferStage.on(setAcceptOfferStage, (_, value) => value);

const setBorrowerHasAsset = createEvent<boolean>();
const $borrowerHasAsset = acceptOfferDomain.createStore(false);

$borrowerHasAsset.on(setBorrowerHasAsset, (_, value) => value);

sample({
  clock: acceptOfferFx.done,
  source: receivedOffers.$data,
  fn: (offers, { params }) => offers.filter((offer) => offer.nonce !== params.nonce),
  target: receivedOffers.set,
});
// Checkouts before offer accept
const grantAccessesToAssetState = createEffectState(grantAccessToAssetFxToListFx, {
  domain: acceptOfferDomain,
});
const acceptOfferState = createEffectState(acceptOfferFx, {
  domain: acceptOfferDomain,
});

$acceptedOfferId.on(acceptOffer, (_, offerId) => offerId);

const $acceptedOffer = combine(
  assetOffers.$data,
  receivedOffers.$data,
  $acceptedOfferId,
  (assetOffers, receivedOffers, offerId) =>
    [...assetOffers, ...receivedOffers].find((offer) => offer.id === offerId) || null,
);

const $normalizedAcceptedOffer = combine(
  $coins,
  $acceptedOffer,
  (coins, offer) =>
    offer && {
      amount: weiToEth(offer?.loanPrincipalAmount),
      amountWithAPR: weiToEth(offer?.maximumRepaymentAmount, {
        maxDigitsAfterComma: precisions.repayment,
      }),
      apr: calculateAPR({
        loan: weiToEth(offer.loanPrincipalAmount),
        repayment: weiToEth(offer.maximumRepaymentAmount, {
          maxDigitsAfterComma: precisions.repayment,
        }),
        days: secondsToDays(offer.loanDuration),
      }),
      lender: offer.lender,
      duration: pluralize(secondsToDays(offer.loanDuration), "day", "s"),
      coin: coins.find((coin) => coin.contractAddress === offer?.loanERC20Denomination),
    },
);

const $acceptedOfferCoin = combine($coins, $acceptedOffer, (coins, offer) =>
  coins.find(
    (coin) =>
      offer && getAddress(coin.contractAddress) === getAddress(offer?.loanERC20Denomination),
  ),
);

const checkAllocatedFundsOfLenderFx = attach({
  source: $acceptedOffer,
  mapParams: (_, offer) => ({
    account: offer!.lender.address,
    amount: offer!.maximumRepaymentAmount,
    contractAddress: offer!.loanERC20Denomination,
  }),
  effect: checkAllocatedFundsFx,
});

const checkBalanceOfLenderFx = attach({
  source: $acceptedOffer,
  mapParams: (_, offer) => ({
    account: offer!.lender.address,
    amount: offer!.loanPrincipalAmount,
    contractAddress: offer!.loanERC20Denomination,
  }),
  effect: checkBalanceOfFx,
});

const checkAllocatedFundsOfLenderState = createEffectState(checkAllocatedFundsOfLenderFx, {
  domain: acceptOfferDomain,
});

const checkBalanceOfLenderState = createEffectState(checkBalanceOfLenderFx);

const $lenderIsChecking = combine(
  checkBalanceOfLenderFx.pending,
  checkAllocatedFundsOfLenderFx.pending,
  (...pending) => pending.some(Boolean),
);

const $offerCheckingPassed = combine(
  checkAllocatedFundsOfLenderState.$status,
  checkBalanceOfLenderState.$status,
  (lenderHasEnoughAllocatedFundsStatus, lenderHasEnoughFunds) => {
    return lenderHasEnoughAllocatedFundsStatus === "succeed" && lenderHasEnoughFunds === "succeed";
  },
);

const $acceptOfferModalIsOpen = combine(
  $acceptedOfferId,
  $offerCheckingPassed,
  (acceptOfferId, offerCheckingPassed) => acceptOfferId !== "" && offerCheckingPassed,
);
// If user selected offer, set asset contract address, to use smart contracts and check the lender's funds
sample({
  clock: $acceptedOffer,
  filter: Boolean,
  fn: (offer) => offer.collateral.contractAddress,
  target: setAssetContractAddress,
});
sample({
  clock: acceptOffer,
  target: [checkBalanceOfLenderFx, checkAllocatedFundsOfLenderFx],
});
// Check whether user gave accesses to asset, before opening confirmation stage
sample({
  clock: confirmTerms,
  target: checkAccessesToAssetForListingFx,
});
sample({
  clock: checkAccessesToAssetForListingFx.fail,
  fn: () => AcceptOfferStagesMap.accessGrant,
  target: setAcceptOfferStage,
});

// Give accesses to asset
sample({
  clock: grantAccessesToAsset,
  target: grantAccessToAssetFxToListFx,
});
sample({
  clock: [grantAccessToAssetFxToListFx.done, checkAccessesToAssetForListingFx.done],
  fn: () => AcceptOfferStagesMap.confirmation,
  target: setAcceptOfferStage,
});
// Confirm offer acceptation
sample({
  source: $acceptedOffer,
  clock: confirmOfferAccept,
  filter: Boolean,
  target: acceptOfferFx,
});

sample({
  clock: [cancelOfferAccept, acceptOfferFx.done],
  target: resetAcceptOfferDomain,
});

sample({
  clock: acceptOfferFx.done,
  fn: (): ToastOptions => ({
    kind: "success",
    title: "Offer has been accepted",
    duration: 3000,
  }),
  target: toast.show,
});

acceptOfferDomain.onCreateStore((store) => store.reset(resetAcceptOfferDomain));

//#endregion

//#region Asset Offers
const $assetOffersTableData = combine(
  assetOffers.$data,
  $coinsMap,
  $defaultAccount,
  (offers, coinsMap, defaultAccount) =>
    offers.map((offer): AssetOffersTableRow => {
      const expiration = calculateLeftTime(offer.expiry);
      const selectedCoin = coinsMap[offer.loanERC20Denomination] || null;

      return {
        id: offer.id,
        lender: {
          nickname:
            getAddress(offer.lender.address) === getAddress(defaultAccount)
              ? "You"
              : offer.lender.nickname ||
                maskAddress(offer.lender.address, { from: 4, to: offer.lender.address.length - 5 }),
          address: offer.lender.address,
        },
        expired: expiration === false,
        expiration: expiration ? `in ${expiration}` : "expired",
        received: new Intl.DateTimeFormat("en-US", {
          month: "short",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        }).format(new Date(offer.createdAt * 1000)),
        duration: pluralize(secondsToDays(offer.loanDuration), "day", "s"),
        loanValue: `${weiToEth(offer.loanPrincipalAmount)} ${selectedCoin.currencySymbol}`,
        repayment: `${weiToEth(offer.maximumRepaymentAmount, {
          maxDigitsAfterComma: precisions.repayment,
        })} ${selectedCoin.currencySymbol}`,
        apr: `${calculateAPR({
          loan: weiToEth(offer.loanPrincipalAmount),
          repayment: weiToEth(offer.maximumRepaymentAmount, {
            maxDigitsAfterComma: precisions.repayment,
          }),
          days: secondsToDays(offer.loanDuration),
        })}%`,
      };
    }),
);
//#endregion
export {
  acceptOffer,
  acceptOfferFx,
  confirmTerms,
  ownOffers,
  $normalizedOwnOffers,
  receivedOffers,
  $normalizedReceivedOffers,
  deleteOfferFx,
  $acceptOfferModalIsOpen,
  $offerCheckingPassed,
  cancelOfferAccept,
  $acceptOfferStage,
  $acceptedOffer,
  setAcceptOfferStage,
  confirmOfferAccept,
  $lenderIsChecking,
  $acceptedOfferCoin,
  $acceptedOfferId,
  grantAccessesToAsset,
  acceptOfferState,
  grantAccessesToAssetState,
  $assetOffersTableData,
  $normalizedAcceptedOffer,
};
