import {
  $isAssetInLoan,
  $isAssetOwner,
  $lenderOffersByAsset,
  checkIsAssetOwnerFx,
  fetchLenderOffersByAssetFx,
} from "@entities/assets/model";
import {
  $assetDetails,
  $makeOfferStage,
  $makeOfferModalIsOpen,
  $normalizedOfferTerms,
} from "@features/assets/model";
import { CreateOfferStage, createOfferStages } from "@features/assets/model/types";
import { Button, Modal } from "@ui";
import { useStore } from "effector-react";
import { useEvent } from "effector-react/scope";
import { AccessGrantView } from "./AccessGrantView";
import { ConfirmationView } from "./ConfirmationView";
import { InitialView } from "./InitialView";
import { makeOffer, cancelOfferMaking } from "@features/assets/model";

import styles from "./styles.module.scss";
import { $connectionStatus } from "shared/libs/effector-metamask";
import { connectionStatuses } from "shared/libs/effector-metamask/constants";
import { useMemo } from "react";

const viewByStage: Record<CreateOfferStage, any> = {
  [createOfferStages.initial]: InitialView,
  [createOfferStages.accessGrant]: AccessGrantView,
  [createOfferStages.confirmation]: ConfirmationView,
};

export const MakeOffer = () => {
  const stage = useStore($makeOfferStage);

  const assetListingTerms = useStore($assetDetails);
  const isAssetOwner = useStore($isAssetOwner);
  const isAssetInLoan = useStore($isAssetInLoan);
  const assetOwnerChecking = useStore(checkIsAssetOwnerFx.pending);
  const ownOffer = useStore($lenderOffersByAsset);
  const makeOfferModalIsOpen = useStore($makeOfferModalIsOpen);
  const ownOfferIsPending = useStore(fetchLenderOffersByAssetFx.pending);
  const connectionStatus = useStore($connectionStatus);
  const offerTerms = useStore($normalizedOfferTerms);

  const descriptionByStage: Record<CreateOfferStage, string> = useMemo(
    () => ({
      [createOfferStages.initial]: "Set your loan offer terms",
      [createOfferStages.accessGrant]: `Dip Ocean needs permission to manage your ${offerTerms?.coin?.currencySymbol}`,
      [createOfferStages.confirmation]: "Please sign the transaction",
    }),
    [offerTerms],
  );

  const handlers = useEvent({
    makeOffer,
    cancelOfferMaking,
  });

  if (
    assetOwnerChecking ||
    isAssetOwner ||
    assetListingTerms?.state !== "Opened" ||
    isAssetInLoan ||
    ownOfferIsPending ||
    ownOffer ||
    connectionStatus !== connectionStatuses.connected
  )
    return null;

  const View = viewByStage[stage];
  const description = descriptionByStage[stage];

  return (
    <div>
      <Modal
        className={styles.modal}
        customBody
        renderFooter={null}
        isOpen={makeOfferModalIsOpen}
        onClose={handlers.cancelOfferMaking}
        title="Make offer"
        description={description}
      >
        <View />
      </Modal>

      <Button onClick={handlers.makeOffer}>Make offer</Button>
    </div>
  );
};
