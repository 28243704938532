import {
  ChangeEvent,
  DetailedHTMLProps,
  forwardRef,
  PropsWithChildren,
  SelectHTMLAttributes,
} from "react";
import styles from "./styles.module.scss";

type DefaultProps = Omit<
  DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
  "value" | "onChange" | "loading" | "ref"
>;

export type SelectProps = PropsWithChildren<{
  value?: string | number;
  onChange?: (value: string) => void;
  loading?: boolean;
  label?: string;
  error?: string;
}> &
  DefaultProps;

export const SelectRoot = forwardRef<HTMLSelectElement, SelectProps>(
  ({ children, loading, value = "", label, placeholder, error, onChange, ...rest }, ref) => {
    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
      onChange?.(e.currentTarget.value);
    };

    return (
      <label>
        {label}
        <select
          ref={ref}
          placeholder={placeholder}
          disabled={loading}
          value={value}
          onChange={handleChange}
          {...rest}
        >
          <option disabled value="">
            {placeholder}
          </option>
          {children}
        </select>
        <span className={styles.error}>{error}</span>
      </label>
    );
  },
);

SelectRoot.displayName = "Select";
