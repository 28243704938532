import { PrimaryLayout, SecondaryLayout } from "@ui";
import { Helmet } from "react-helmet";
import { PrimaryHeader } from "widgets";
import { ListedAssets } from "../../features/assets";

import "./model";

export const LendAssets = () => {
  return (
    <PrimaryLayout header={<PrimaryHeader />}>
      <Helmet title={`Dip Ocean - Explore`} />

      <SecondaryLayout title="Explore">
        <ListedAssets />
      </SecondaryLayout>
    </PrimaryLayout>
  );
};
