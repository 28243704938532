import { Image, Skeleton, Typography } from "@ui";
import { DetailedHTMLProps, FunctionComponent, ImgHTMLAttributes } from "react";

import styles from "./styles.module.scss";

type CustomProps = {
  isLoading: boolean;
  state?: string;
};

type Props = Omit<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  keyof CustomProps
> &
  CustomProps;

export const AssetImage: FunctionComponent<Props> = ({ src, isLoading, state, ...rest }) => {
  return (
    <Skeleton active={isLoading}>
      <div className={styles.imageBox}>
        {(src || isLoading) && <Image loading="lazy" src={src} {...rest} />}
        {state && (
          <Skeleton active={isLoading}>
            <div className={styles.assetStatus}>
              <Typography kind="body2">{state}</Typography>
            </div>
          </Skeleton>
        )}
      </div>
    </Skeleton>
  );
};
