import { useStore } from "effector-react";
import { useEvent } from "effector-react/scope";

import { Button, ModalBody, ModalFooter, ReadOnlyField, Typography } from "@ui";
import {
  $isListingConfirming,
  $listingStage,
  $normalizedListingTerms,
  confirmListing,
  setListingStage,
} from "../../../model";
import { listingStages } from "@features/assets/model/types";

import styles from "./styles.module.scss";

export const ConfirmTermsView = () => {
  const stage = useStore($listingStage);
  const terms = useStore($normalizedListingTerms);
  const isConfirming = useStore($isListingConfirming);

  const handlers = useEvent({
    confirmListing,
    setListingStage,
  });

  const handleBack = () => handlers.setListingStage(listingStages.initial);

  if (stage !== listingStages.confirmation || !terms) return null;

  return (
    <>
      <ModalBody className={styles.layout}>
        <ReadOnlyField label="for" value={`${terms.amount} ${terms.coinSymbol}`} />
        <ReadOnlyField label="duration" value={terms.lendTerms} />
        <ReadOnlyField label="repayment" value={`${terms.amountWithAPR} ${terms.coinSymbol}`} />
        <ReadOnlyField label="apr" value={`${terms.aprPercent}%`} />

        <Typography className={styles.reminder} kind="body2">
          You can de-list your asset at any time
        </Typography>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleBack} kind="ghost">
          Back
        </Button>
        <Button onClick={handlers.confirmListing} loading={isConfirming} type="submit">
          Confirm
        </Button>
      </ModalFooter>
    </>
  );
};
