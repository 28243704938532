import { precisions } from "@configs/constants";
import { $isOfferMaking, $normalizedOfferTerms, makeOfferState } from "@features/assets/model";
import { createOfferStages } from "@features/assets/model/types";
import {
  Button,
  ModalBody,
  ModalFooter,
  Message,
  Typography,
  Card,
  ReadOnlyField,
  FormatFloat,
} from "@ui";
import { useStore } from "effector-react";
import { useEvent } from "effector-react/scope";
import { confirmOfferTerms, setMakeOfferStage } from "../../../model";

import styles from "./styles.module.scss";

export const ConfirmationView = () => {
  const terms = useStore($normalizedOfferTerms);
  const isSubmitting = useStore($isOfferMaking);

  const status = useStore(makeOfferState.$status);
  const error = useStore(makeOfferState.$error);

  const handlers = useEvent({
    confirmOfferTerms,
    setMakeOfferStage,
  });

  if (!terms) return null;

  const handleBack = () => handlers.setMakeOfferStage(createOfferStages.initial);

  return (
    <>
      <ModalBody className={styles.bodyLayout}>
        <div>
          <Message className={styles.topMessage}>
            <Typography kind="body1">
              Please click sign to show the metamask popup and confirm the transaction and begin the
              loan
            </Typography>

            <Typography kind="body1">
              By singing this transaction you agree to our <a href="#">Terms of service</a> and{" "}
              <a href="#">Terms of use</a>
            </Typography>
          </Message>

          <div className={styles.termsBox}>
            <Typography kind="body1">All offer conditions:</Typography>
            <div className={styles.termsLayout}>
              <ReadOnlyField
                kind="secondary"
                label="For"
                value={<FormatFloat value={terms.amount} postFix={terms.coin?.currencySymbol} />}
              />
              <ReadOnlyField kind="secondary" label="Duration" value={`${terms.lendDays} days`} />
              <ReadOnlyField
                kind="secondary"
                label="APR"
                value={<FormatFloat value={terms.apr} postFix={"%"} />}
              />
              <ReadOnlyField
                kind="secondary"
                label="Repayment"
                value={
                  <FormatFloat value={terms.amountWithAPR} postFix={terms.coin?.currencySymbol} />
                }
              />
            </div>
          </div>
        </div>

        <div className={styles.bottomMessage}>
          {status === "pending" && (
            <Message.Pending
              title="Waiting for signature..."
              description="Please approve the transaction in your wallet"
            />
          )}

          {status === "failed" && (
            <Message.Error title="Error executing transaction." description={error} />
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button kind="secondary" disabled={isSubmitting} onClick={handleBack}>
          Back
        </Button>
        <Button loading={isSubmitting} onClick={handlers.confirmOfferTerms}>
          Confirm
        </Button>
      </ModalFooter>
    </>
  );
};
