import { Button, Icon, Message, Modal, Typography } from "@ui";
import { useEvent, useStore } from "effector-react/scope";
import { FunctionComponent, useEffect } from "react";
import { ConfirmationView } from "./ConfirmationView";
import { GrantAccessesView } from "./GrantAccessesView";
import {
  repayLoan,
  cancelLoanRepay,
  $repayLoanIsOpen,
  $repayLoanStage,
} from "@features/loans/model";
import styles from "./styles.module.scss";
import { Loan } from "@api/loansService/types";
import { RepayLoanStages } from "@features/loans/model/types";
import {
  $assetLoan,
  $assetLoanExpired,
  $isAssetLoanLender,
  $normalizedAssetLoan,
} from "@entities/loans/model";
import { $isAssetInLoan } from "@entities/assets";
import { secondsToDays } from "shared/libs/format-days";
import { pluralize } from "shared/libs/pluralize";

type Props = {
  loanId: Loan["id"];
  expired?: boolean;
};

const viewsByStageMap: Record<RepayLoanStages, FunctionComponent<unknown>> = {
  accessGrant: GrantAccessesView,
  confirmation: ConfirmationView,
  initial: () => null,
};

const descriptionByStage: Record<RepayLoanStages, string> = {
  initial: "Are you sure you want to repay the loan?",
  confirmation: "Please sign the transaction",
  accessGrant: "Grant permission to manage asset",
};

export const RepayLoan: FunctionComponent<Props> = ({ loanId, expired }) => {
  const isOpen = useStore($repayLoanIsOpen);
  const currentStage = useStore($repayLoanStage);
  const assetLoanExpired = useStore($assetLoanExpired);
  const isAssetInLoan = useStore($isAssetInLoan);
  const isAssetLoanLender = useStore($isAssetLoanLender);
  const loan = useStore($normalizedAssetLoan);

  const handlers = useEvent({
    repayLoan,
    cancelLoanRepay,
  });

  const View = viewsByStageMap[currentStage];

  useEffect(() => {
    return () => handlers.cancelLoanRepay();
  }, []);

  return (
    <>
      {isAssetInLoan && !assetLoanExpired && (
        <Button disabled={expired} onClick={() => handlers.repayLoan(loanId)}>
          Repay loan
        </Button>
      )}

      {isAssetInLoan && isAssetLoanLender && loan && (
        <Message.Info
          inline
          icon={<Icon.ExclamationTriangle />}
          title={
            <Typography kind="body2">
              If repayment is not made in {pluralize(secondsToDays(loan.secondsLeft), "day", "s")}{" "}
              days, you will be able to claim this NFT
            </Typography>
          }
        />
      )}

      <Modal
        title="In escrow"
        description={descriptionByStage[currentStage]}
        className={styles.modal}
        customBody
        isOpen={isOpen}
        onClose={handlers.cancelLoanRepay}
        renderFooter={null}
      >
        <View />
      </Modal>
    </>
  );
};
