import { FunctionComponent } from "react";
import { PropsWithChildren } from "react";

type Props = PropsWithChildren<{
  value: any;
}>;

export const Option: FunctionComponent<Props> = ({ children, value }) => (
  <option value={value}>{children}</option>
);
