import { Route } from "atomic-router-react/scope";

import { AssetDetails } from "./assetDetails";
import { BorrowAssets } from "./borrower/assets";
import { Home } from "./home";
import { LendAssets } from "./explore";
import { Offers } from "./offers";
import { Loans } from "./loans";

import { routesMap } from "@configs/routing";
import { NotFound } from "./NotFound";
import { Notifications } from "./notifications";
import { About } from "./about";
import { Profile } from "./profile";
import { Helmet } from "react-helmet";

export const Pages = () => (
  <>
    <Helmet title="DipOcean" />
    <Route route={routesMap.home} view={Home} />
    <Route route={routesMap.assetDetails} view={AssetDetails} />
    <Route route={routesMap.offers} view={Offers} />
    <Route route={routesMap.loans} view={Loans} />
    <Route route={routesMap.userAssets} view={BorrowAssets} />
    <Route route={routesMap.explore} view={LendAssets} />
    <Route route={routesMap.errors.notFound} view={NotFound} />
    <Route route={routesMap.notifications} view={Notifications} />
    <Route route={routesMap.about} view={About} />
    <Route route={routesMap.profile} view={Profile} />
  </>
);
