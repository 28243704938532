export enum AcceptOfferStagesMap {
  terms,
  accessGrant,
  confirmation,
}

export type AcceptOfferStages = keyof typeof AcceptOfferStagesMap;

export type AssetOffersTableRow = {
  id: string;
  lender: {
    nickname: string;
    address: string;
  };
  expired: boolean;
  expiration: string;
  received: string;
  duration: string;
  loanValue: string;
  repayment: string;
  apr: string;
};

export type CancelOfferPayload = {
  nonce: string;
  id: string;
};
