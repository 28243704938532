import { Button, Message } from "@ui";
import { useEvent, useStore } from "effector-react/scope";
import { Typography } from "shared/ui/elements";
import { AcceptOfferStagesMap } from "@entities/offers/model/types";
import {
  setAcceptOfferStage,
  grantAccessesToAsset,
  grantAccessesToAssetState,
} from "@entities/offers/model";

import styles from "./styles.module.scss";
import commonStyles from "../styles.module.scss";
import { grantAccessToAssetFxToListFx } from "@entities/assets";
import { TxState } from "shared/libs/create-tx-effect";

export const AccessGrantView = () => {
  const handlers = useEvent({
    setAcceptOfferStage,
    grantAccessesToAsset,
  });
  const state = useStore(grantAccessToAssetFxToListFx.$state);
  const error = useStore(grantAccessesToAssetState.$error);

  const isPending = state === TxState.pending || state === TxState.confirmation;

  const handleBack = () => handlers.setAcceptOfferStage(AcceptOfferStagesMap.terms);
  const handleOk = () => handlers.grantAccessesToAsset();

  return (
    <div className={commonStyles.modalBody}>
      <div className={commonStyles.modalContent}>
        <div className={styles.layout}>
          {
            <Message>
              <Typography kind="body1">
                Please click Grant below to show the metamask popup and grant permission.
              </Typography>
            </Message>
          }
        </div>
      </div>

      {state === TxState.pending && (
        <Message.Pending
          title="Waiting for signature..."
          description="Please click Grant below to show the metamask popup and grant permission."
        />
      )}

      {state === TxState.confirmation && <Message.Pending title="Executing: Grant permission" />}

      {error && <Message.Error title="Error executing transaction" description={error} />}

      <div className={commonStyles.modalFooter}>
        <Button kind="ghost" onClick={handleBack}>
          Back
        </Button>
        <Button loading={isPending} onClick={handleOk}>
          Grant access
        </Button>
      </div>
    </div>
  );
};
