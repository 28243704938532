import { FunctionComponent, PropsWithChildren, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames/bind";

import styles from "./styles.module.scss";
import { runtimeEnv } from "@configs/runTimeEnv";
import { Typography } from "shared/ui/elements";
import { Button } from "shared/ui/atoms";

const cx = classNames.bind(styles);

type ModalBodyProps = PropsWithChildren<{ className?: string }>;

export const ModalBody: FunctionComponent<ModalBodyProps> = ({ children, className }) => (
  <div className={cx("modal-body", className)}>{children}</div>
);

export const ModalFooter: FunctionComponent<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => <div className={cx("modal-footer", className)}>{children}</div>;

type Props = PropsWithChildren<{
  title?: string;
  description?: string;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  onOk?: () => void;
  cancelText?: string;
  okText?: string;
  renderFooter?: JSX.Element | null;
  customBody?: Boolean;
  destroyOnClose?: boolean;
}>;

export const Modal: FunctionComponent<Props> = ({
  children,
  title,
  description,
  onClose,
  isOpen,
  className,
  onOk,
  cancelText = "Back",
  okText = "Submit",
  renderFooter,
  customBody,
  destroyOnClose = true,
}) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(runtimeEnv.isClient);
  }, []);

  useEffect(() => {
    document.body.classList[isOpen ? "add" : "remove"](cx("disable-body-scroll"));
  }, [isOpen]);

  const renderInner = destroyOnClose ? isOpen : true;

  if (!isClient) return null;

  return createPortal(
    <CSSTransition
      in={isOpen}
      timeout={400}
      classNames={{
        enterActive: cx("enter-active"),
        enterDone: cx("enter-done"),
      }}
    >
      <div>
        <div className={cx("overlay")} onClick={onClose} />
        <div className={cx("modal", className)}>
          {renderInner && (
            <>
              <div className={cx("modal-header")}>
                <div>
                  {title && (
                    <Typography weight="bold" kind="title4">
                      {title}
                    </Typography>
                  )}
                  {description && (
                    <Typography className={cx("description")} kind="body1">
                      {description}
                    </Typography>
                  )}
                </div>
                <span className={cx("close-icon")} onClick={onClose}>
                  <svg
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.4041 8.99921L16.7041 2.70921C16.8924 2.52091 16.9982 2.26551 16.9982 1.99921C16.9982 1.73291 16.8924 1.47751 16.7041 1.28921C16.5158 1.10091 16.2604 0.995117 15.9941 0.995117C15.7278 0.995117 15.4724 1.10091 15.2841 1.28921L8.99408 7.58921L2.70408 1.28921C2.51578 1.10091 2.26038 0.995117 1.99408 0.995117C1.72778 0.995117 1.47238 1.10091 1.28408 1.28921C1.09578 1.47751 0.989988 1.73291 0.989988 1.99921C0.989988 2.26551 1.09578 2.52091 1.28408 2.70921L7.58408 8.99921L1.28408 15.2892C1.19035 15.3822 1.11596 15.4928 1.06519 15.6146C1.01442 15.7365 0.988281 15.8672 0.988281 15.9992C0.988281 16.1312 1.01442 16.2619 1.06519 16.3838C1.11596 16.5056 1.19035 16.6162 1.28408 16.7092C1.37704 16.8029 1.48764 16.8773 1.6095 16.9281C1.73136 16.9789 1.86207 17.005 1.99408 17.005C2.12609 17.005 2.2568 16.9789 2.37866 16.9281C2.50052 16.8773 2.61112 16.8029 2.70408 16.7092L8.99408 10.4092L15.2841 16.7092C15.377 16.8029 15.4876 16.8773 15.6095 16.9281C15.7314 16.9789 15.8621 17.005 15.9941 17.005C16.1261 17.005 16.2568 16.9789 16.3787 16.9281C16.5005 16.8773 16.6111 16.8029 16.7041 16.7092C16.7978 16.6162 16.8722 16.5056 16.923 16.3838C16.9737 16.2619 16.9999 16.1312 16.9999 15.9992C16.9999 15.8672 16.9737 15.7365 16.923 15.6146C16.8722 15.4928 16.7978 15.3822 16.7041 15.2892L10.4041 8.99921Z"
                      fill="#AAAAAA"
                    />
                  </svg>
                </span>
              </div>
              {customBody ? children : <ModalBody>{children}</ModalBody>}
              {renderFooter !== undefined ? (
                renderFooter
              ) : (
                <ModalFooter>
                  <Button kind="secondary" onClick={onClose}>
                    {cancelText}
                  </Button>
                  <Button kind="primary" onClick={onOk}>
                    {okText}
                  </Button>
                </ModalFooter>
              )}
            </>
          )}
        </div>
      </div>
    </CSSTransition>,
    document.body,
  );
};
