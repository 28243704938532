import { ChangeEventHandler, forwardRef, InputHTMLAttributes } from "react";
import cn from "classnames/bind";

import styles from "./styles.module.scss";
import { InputProps } from "./types";

const cx = cn.bind(styles);

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ value = "", error, type, onChange, appendAfter, onBlur, className, ...rest }, ref) => {
    const hasError = Boolean(error);
    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
      onChange?.(e.currentTarget.value);
    };

    const handleBlur: InputProps["onBlur"] = (e) => {
      onBlur?.(e);
    };

    return (
      <label className={cx("label", className, { hasError })}>
        <div className={cx("inputBox")}>
          <input
            value={value}
            onChange={handleChange}
            ref={ref}
            onBlur={handleBlur}
            type={type}
            className={cx("input")}
            {...rest}
          />

          {appendAfter && <span className={cx("appendAfter")}>{appendAfter}</span>}
        </div>
      </label>
    );
  },
);

Input.displayName = "Input";
