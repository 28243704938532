import { precisions } from "@configs/constants";
import { useMemo } from "react";
import { accurateToFixed } from "shared/libs/accurate-to-fixed";

type Props = {
  value: number | string;
  postFix?: string;
  precision?: number;
};

export const FormatFloat = ({ value, postFix, precision = precisions.float }: Props) => {
  const fixedValue = useMemo(() => {
    let num = parseFloat(value.toString());

    if (precision) {
      num = parseFloat(accurateToFixed(num, precision));
    }

    return num;
  }, [value, precision]);

  return (
    <span title={String(value)}>
      {fixedValue} {postFix}
    </span>
  );
};
