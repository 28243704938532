import { JSONRPCErrors } from "./errors";
import { Accounts, Chain } from "./types";
import { isProviderRPCError } from "./utils";

const instance = typeof window !== "undefined" && window.ethereum ? window.ethereum : null;

const checkConnection = async () => {
  const wallets = await instance?.request({
    method: "eth_accounts",
  });

  if (!Array.isArray(wallets) || wallets.length === 0) throw Error();

  return true;
};

const requestAccounts = async (): Promise<Accounts> => {
  const accounts = await instance?.request({
    method: "eth_requestAccounts",
  });

  if (!Array.isArray(accounts) || accounts.length === 0) return Promise.reject();

  return accounts;
};

const requestChain = async () => {
  const chainId = await instance?.request({
    method: "eth_chainId",
  });

  if (typeof chainId !== "string") throw Error();

  return chainId;
};

const addChains = async (chains: Array<Chain>) =>
  instance?.request({
    method: "wallet_addEthereumChain",
    params: chains.map((chain) => ({
      ...chain,
      id: `0x${chain.id}`,
    })),
  });

const switchChain = async (chain: Chain) => {
  try {
    await instance?.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${chain.id}` }],
    });
  } catch (err) {
    if (isProviderRPCError(err)) {
      if (err.code === JSONRPCErrors.chainNotFound) {
        await addChains([chain]);
      }

      if (err.code === JSONRPCErrors.userRejectedRequest) throw err;
    }
  }
};

export const metamask = {
  instance,
  switchChain,
  addChains,
  requestAccounts,
  requestChain,
  checkConnection,
};
