import { errorMessages } from "@configs/errorMessages";
import { routesMap } from "@configs/routing";
import {
  $editAccountModalOpen,
  editAccount,
  closeAccountEditModal,
  $accountNickname,
  submitAccountEdit,
  updateAccountInfoFx,
} from "@entities/account/model";
import { AccountEditForm } from "@entities/account/model/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Field, Form, Icon, Input, Modal, ModalBody, ModalFooter } from "@ui";
import { useStore } from "effector-react";
import { useEvent } from "effector-react/scope";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { $defaultAccount } from "shared/libs/effector-metamask";
import * as yup from "yup";

import styles from "./styles.module.scss";

const schema = yup.object().shape({
  nickname: yup.string().required(errorMessages.required),
});

export const EditProfile = () => {
  const isOpen = useStore($editAccountModalOpen);
  const accountNickname = useStore($accountNickname);
  const isConfirming = useStore(updateAccountInfoFx.pending);

  const form = useForm<AccountEditForm>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const handlers = useEvent({
    editAccount,
    closeAccountEditModal,
    submitAccountEdit,
  });

  useEffect(() => {
    if (isOpen) {
      form.setValue("nickname", accountNickname || "");
    } else {
      form.reset;
    }
  }, [isOpen, accountNickname]);

  useEffect(() => {
    return handlers.closeAccountEditModal();
  }, []);

  return (
    <>
      <Modal
        className={styles.modal}
        title="Edit profile"
        isOpen={isOpen}
        onClose={handlers.closeAccountEditModal}
        renderFooter={null}
        customBody
      >
        <Form form={form} onSubmit={handlers.submitAccountEdit}>
          <ModalBody>
            <Field name="nickname" label="Enter your name">
              <Input placeholder={accountNickname === null ? "Anonymous" : accountNickname} />
            </Field>
          </ModalBody>
          <ModalFooter className={styles.modalFooter}>
            <Button loading={isConfirming} wide>
              Save profile
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Button
        size="extra-small"
        kind="simple"
        onClick={() => handlers.editAccount()}
        appendBefore={<Icon.PenOutlined />}
      >
        Edit profile
      </Button>
    </>
  );
};
