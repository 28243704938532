import { NotificationsService } from "@api/notificationsService";
import { attach, combine, createEffect, createEvent, createStore, sample } from "effector";
import { createDataList } from "shared/libs/create-data-list";
import { interval } from "patronum/interval";
import { $defaultAccount } from "shared/libs/effector-metamask";
import { normalizeNotifications } from "../libs/normalizeNotifications";
import { $coinsMap } from "@entities/coins";
import { primaryLazyLoadLimit } from "@configs/constants";

const fetchNotificationsFx = createEffect(NotificationsService.getAll);
const markAsReadFx = createEffect(NotificationsService.markAsRead);
const notifications = createDataList({
  effect: fetchNotificationsFx,
  limit: primaryLazyLoadLimit,
});

const readNotification = createEvent<string>();

const $normalizedNotifications = combine(
  $coinsMap,
  $defaultAccount,
  notifications.$data,
  (coins, account, notifications) =>
    normalizeNotifications({
      coins,
      account,
      notifications,
    }),
);

const $notificationsPreview = $normalizedNotifications.map((data) => data.slice(0, 4));
const $hasUnreadLastNotification = $notificationsPreview.map((notifications) =>
  notifications.some((notification) => !notification?.seen),
);

const openNotificationsPopup = createEvent<unknown>();
const closeNotificationsPopup = createEvent<unknown>();

const $notificationsPopupIsOpen = createStore(false);

$notificationsPopupIsOpen.on(openNotificationsPopup, () => true).reset(closeNotificationsPopup);

const fetchNewNotificationsFx = attach({
  source: notifications.$data,
  effect: ([notification]) =>
    NotificationsService.getAll({
      limit: primaryLazyLoadLimit,
      offset: 0,
      fromTimestamp: notification.createdAt + 1,
    }),
});

const $canFetchNewNotifications = combine(
  fetchNewNotificationsFx.pending,
  fetchNotificationsFx.pending,
  (...pendings) => pendings.every((pending) => !pending),
);

const lookForNotifications = interval({
  timeout: 30 * 1000,
  start: notifications.get,
});

const markAllAsRead = createEvent();

sample({
  clock: markAllAsRead,
  source: notifications.$data,
  fn: ([lastNotification]) => ({
    beforeTimestamp: lastNotification.createdAt + 1,
  }),
  target: markAsReadFx,
});

sample({
  source: notifications.$data,
  clock: readNotification,
  filter: (notifications, id) => !notifications.find((row) => row.id === id)?.seen,
  fn: (_, id) => ({
    id,
  }),
  target: [markAsReadFx],
});

sample({
  clock: readNotification,
  target: closeNotificationsPopup,
});

sample({
  source: notifications.$data,
  clock: readNotification,
  fn: (data, id) => data.map((row) => (row.id === id ? { ...row, seen: true } : row)),
  target: [notifications.set],
});

sample({
  source: notifications.$data,
  clock: markAllAsRead,
  fn: (notifications) =>
    notifications.map((notification) => ({
      ...notification,
      seen: true,
    })),
  target: notifications.set,
});
sample({
  clock: fetchNewNotificationsFx.doneData,
  source: notifications.$data,
  fn: (notifications, newNotifications) => [...newNotifications, ...notifications],
  target: notifications.set,
});

sample({
  clock: lookForNotifications.tick,
  filter: $canFetchNewNotifications,
  target: fetchNewNotificationsFx,
});

sample({
  clock: $defaultAccount,
  filter: Boolean,
  target: notifications.get,
});

export {
  $hasUnreadLastNotification,
  notifications,
  $notificationsPopupIsOpen,
  openNotificationsPopup,
  closeNotificationsPopup,
  $notificationsPreview,
  markAllAsRead,
  $normalizedNotifications,
  readNotification,
  fetchNewNotificationsFx,
};
