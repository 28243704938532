import { forwardRef, useMemo } from "react";
import { daysToSeconds, secondsToDays } from "shared/libs/format-days";
import { Input } from "./Default";
import { InputProps } from "./types";

type CustomProps = {
  value?: number | string;
  label?: string;
  onChange?: (value: string | number) => void;
  error?: string;
};

type Props = CustomProps & Omit<InputProps, keyof CustomProps | "type">;

export const InputDays = forwardRef<HTMLInputElement, Props>(
  ({ value, onChange, ...rest }, ref) => {
    const displayValue = useMemo(() => value && secondsToDays(value), [value]);

    const handleChange = (value: string | number) => {
      const normalizedValue = daysToSeconds(value);

      onChange?.(normalizedValue);
    };

    return <Input {...rest} onChange={handleChange} type="number" value={displayValue} />;
  },
);

InputDays.displayName = "InputDays";
