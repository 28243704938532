export const Logo = () => (
  <svg width="48" height="41" viewBox="0 0 48 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.58951 16.6573C6.53817 8.61186 11.5123 3.11929 17.7941 1.21107C19.6599 0.786011 21.0449 1.46966 21.8526 2.73249C23.0539 4.6107 22.9779 7.77011 21.3067 10.4685C19.6914 13.3566 17.512 14.2076 16.0249 17.0183C16.7498 19.3093 17.9639 20.4642 21.1785 22.3562C19.5135 23.9395 16.6146 24.8317 13.7686 23.7229C12.1667 31.708 15.1153 33.0594 22.6144 33.6508C22.5827 33.7734 22.5529 33.8937 22.5252 34.0118C21.7428 37.3396 22.5336 38.8859 25.5886 41C16.5121 41 7.56376 37.3641 4.35875 23.8003C4.35875 23.8003 3.33319 21.5826 0 21.2731C1.71787 17.0183 4.58951 16.6573 4.58951 16.6573Z"
      fill="url(#paint0_linear_550_4780)"
    />
    <path
      d="M4.58951 16.6573C6.53817 8.61186 11.5123 3.11929 17.7941 1.21107C19.6599 0.786011 21.0449 1.46966 21.8526 2.73249C-1.58855 11.9311 6.09661 35.7067 22.5252 34.0118C21.7428 37.3396 22.5336 38.8859 25.5886 41C16.5121 41 7.56376 37.3641 4.35875 23.8003C4.35875 23.8003 3.33319 21.5826 0 21.2731C1.71787 17.0183 4.58951 16.6573 4.58951 16.6573Z"
      fill="url(#paint1_linear_550_4780)"
    />
    <path
      d="M43.4105 24.3427C41.4618 32.3881 36.4877 37.8807 30.2059 39.7889C28.3401 40.214 26.9551 39.5303 26.1474 38.2675C24.9461 36.3893 25.0221 33.2299 26.6933 30.5315C28.3086 27.6434 30.488 26.7924 31.9751 23.9817C31.2502 21.6907 30.0361 20.5358 26.8215 18.6438C28.4865 17.0605 31.3854 16.1683 34.2314 17.2771C35.8333 9.29199 32.8847 7.9406 25.3856 7.34921C25.4173 7.22656 25.4471 7.10626 25.4748 6.9882C26.2572 3.6604 25.4664 2.11405 22.4114 -1.37047e-05C31.4879 -1.29028e-05 40.4362 3.63592 43.6412 17.1997C43.6412 17.1997 44.6668 19.4174 48 19.7269C46.2821 23.9817 43.4105 24.3427 43.4105 24.3427Z"
      fill="url(#paint2_linear_550_4780)"
    />
    <path
      d="M43.4105 24.3427C41.4618 32.3881 36.4877 37.8807 30.2059 39.7889C28.3401 40.214 26.9551 39.5303 26.1474 38.2675C49.5886 29.0689 41.9034 5.2933 25.4748 6.9882C26.2572 3.6604 25.4664 2.11405 22.4114 -1.37047e-05C31.4879 -1.29028e-05 40.4362 3.63592 43.6412 17.1997C43.6412 17.1997 44.6668 19.4174 48 19.7269C46.2821 23.9817 43.4105 24.3427 43.4105 24.3427Z"
      fill="url(#paint3_linear_550_4780)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_550_4780"
        x1="12.7943"
        y1="1.08484"
        x2="12.7943"
        y2="41"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#36ABFF" />
        <stop offset="1" stopColor="#B5FBFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_550_4780"
        x1="23.8964"
        y1="-0.255978"
        x2="23.8964"
        y2="41"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2E73FF" />
        <stop offset="1" stopColor="#369FFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_550_4780"
        x1="35.2057"
        y1="39.9152"
        x2="35.2057"
        y2="-1.25744e-05"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#36ABFF" />
        <stop offset="1" stopColor="#B5FBFF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_550_4780"
        x1="24.1036"
        y1="41.256"
        x2="24.1036"
        y2="-1.2172e-05"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2E73FF" />
        <stop offset="1" stopColor="#369FFF" />
      </linearGradient>
    </defs>
  </svg>
);
