import styles from "./styles.module.scss";

type EmptyListProps = {
  firstLine?: string;
  secondLine?: string;
  small?: boolean;
};

export const EmptyList = ({
  firstLine = "NFT will be here",
  secondLine = "They'll be here soon.",
  small = false,
}: EmptyListProps) => (
  <div className={styles.wrapper}>
    <svg
      className={styles.pic}
      width={small ? 362 : 482}
      height={small ? 263 : 352}
      viewBox="0 0 482 352"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="222.721"
        height="221.007"
        rx="8.31102"
        transform="matrix(-0.966498 0.256673 0.26098 0.965344 215.759 1.02148)"
        fill="#EBF1FF"
      />
      <rect
        width="222.85"
        height="220.877"
        rx="8.31102"
        transform="matrix(-0.999254 -0.0386317 -0.0393258 0.999226 368.729 128.295)"
        fill="#EBF1FF"
        stroke="white"
        strokeWidth="4.67495"
      />
      <rect
        width="183.395"
        height="181.906"
        rx="8.31102"
        transform="matrix(-0.978722 -0.205189 -0.208722 0.977975 480.5 38.6309)"
        fill="#EBF1FF"
        stroke="white"
        strokeWidth="4.67495"
      />
    </svg>
    {firstLine && <div className={styles.firstRow}>{firstLine}</div>}
    {secondLine && <div className={styles.secondRow}>{secondLine}</div>}
  </div>
);
