import { OffersService } from "@api/offersService";
import { OfferTerms } from "@api/offersService/types";
import { runtimeEnv } from "@configs/runTimeEnv";
import {
  $assetContractAddress,
  $assetTokenId,
  $lenderOffersByAsset,
  assetOffers,
  fetchLenderOffersByAssetFx,
} from "@entities/assets";
import { $coins } from "@entities/coins";
import { checkAllocatedFundsFx, getERC20AllowanceFx } from "@entities/smart-contracts";
import { $serviceFee } from "@entities/lend/model";
import { deleteOfferFx } from "@entities/offers";
import { attach, combine, createDomain, createEffect, createEvent, sample } from "effector";
import { constants } from "ethers";
import { arrayify, solidityKeccak256 } from "ethers/lib/utils";
import { calculateAPR } from "shared/libs/calculate-apr";
import { createEffectState } from "shared/libs/create-effect-state";
import { $signer } from "shared/libs/effector-ethers";
import { $currentChainId, $defaultAccount } from "shared/libs/effector-metamask";
import { ethToWei } from "shared/libs/eth-to-wei";
import { daysToSeconds, secondsToDays } from "shared/libs/format-days";
import { getRandomNonce } from "shared/libs/get-random-nonce";
import { toast } from "shared/libs/toast";
import { ToastOptions } from "shared/libs/toast/types";
import { weiToEth } from "shared/libs/wei-to-eth";
import { OfferForm } from "../ui/MakeOffer/InitialView/types";
import { assetDetailsDomain } from "./details";
import { CreateOfferStage, createOfferStages } from "./types";
import { precisions } from "@configs/constants";
import { createTxEffect } from "shared/libs/create-tx-effect";

//#region Make the offer
const makeOfferDomain = assetDetailsDomain.createDomain();
const resetMakeOfferDomain = createEvent();

const makeOffer = createEvent<unknown>();
const cancelOfferMaking = createEvent();
const submitOfferTerms = createEvent<OfferForm>();
const confirmOfferTerms = createEvent<unknown>();
const getAllowanceForFunds = createEvent();

const setOfferTerms = createEvent<OfferTerms>();
const $offerTerms = makeOfferDomain.createStore<OfferTerms | null>(null);

$offerTerms.on(setOfferTerms, (_, payload) => payload);

const checkAllocatedFundsForOfferFx = attach({
  effect: checkAllocatedFundsFx,
});

const allocateFundForOfferFx = createTxEffect(
  attach({
    effect: getERC20AllowanceFx,
  }),
  {
    confirmationsCount: 1,
  },
);

const checkAllocatedFundsState = createEffectState(checkAllocatedFundsForOfferFx, {
  domain: makeOfferDomain,
});
const allocateFundForOfferState = createEffectState(allocateFundForOfferFx, {
  domain: makeOfferDomain,
});

const $normalizedOfferTerms = combine(
  $coins,
  $offerTerms,
  (coins, offerTerms) =>
    offerTerms && {
      amount: weiToEth(offerTerms.amount),
      amountWithAPR: weiToEth(offerTerms.amountWithAPR, {
        maxDigitsAfterComma: precisions.repayment,
      }),
      apr: calculateAPR({
        loan: weiToEth(offerTerms.amount),
        repayment: weiToEth(offerTerms.amountWithAPR, {
          maxDigitsAfterComma: precisions.repayment,
        }),
        days: secondsToDays(offerTerms.lendSeconds),
      }),
      lendDays: secondsToDays(offerTerms.lendSeconds),
      coin: coins.find((coin) => coin.contractAddress === offerTerms?.coinAddress) || null,
    },
);

const signOfferTermsFx = attach({
  source: {
    signer: $signer,
    offerTerms: $offerTerms,
    currentChainId: $currentChainId,
    defaultAccount: $defaultAccount,
    assetContractAddress: $assetContractAddress,
    assetTokenId: $assetTokenId,
    serviceFee: $serviceFee,
  },
  effect: async ({
    signer,
    offerTerms,
    currentChainId,
    serviceFee,
    defaultAccount,
    assetTokenId,
    assetContractAddress,
  }) => {
    if (
      !signer ||
      !offerTerms ||
      assetContractAddress === null ||
      currentChainId === null ||
      serviceFee === null
    )
      return Promise.reject();

    const rawData = {
      loanERC20Denomination: offerTerms.coinAddress,
      loanPrincipalAmount: offerTerms.amount,
      maximumRepaymentAmount: offerTerms.amountWithAPR,
      nftCollateralContract: assetContractAddress,
      nftCollateralId: assetTokenId,
      referrer: constants.AddressZero,
      loanDuration: 60 * 3,
      fee: serviceFee,
      lender: defaultAccount,
      nonce: getRandomNonce(),
      // TODO: set default expire in env
      expiry: offerTerms.lifeTime || Math.round(new Date().getTime() / 1000 + 86400 * 2),
      loanContractAddress: runtimeEnv.directLoanFixedOfferAddress,
      chainId: currentChainId,
    };

    const hash = solidityKeccak256(
      [
        "address",
        "uint256",
        "uint256",
        "address",
        "uint256",
        "address",
        "uint32",
        "uint16",
        "address",
        "uint256",
        "uint256",
        "address",
        "uint256",
      ],
      [
        rawData.loanERC20Denomination,
        rawData.loanPrincipalAmount,
        rawData.maximumRepaymentAmount,
        rawData.nftCollateralContract,
        rawData.nftCollateralId,
        rawData.referrer,
        rawData.loanDuration,
        rawData.fee,
        rawData.lender,
        rawData.nonce,
        rawData.expiry,
        rawData.loanContractAddress,
        rawData.chainId,
      ],
    );

    const message = arrayify(hash);
    const signature = await signer.signMessage(message);

    return {
      ...rawData,
      signature,
      hash,
    };
  },
});

const makeOfferFx = createEffect(OffersService.createOffer);
const makeOfferState = createEffectState([signOfferTermsFx, makeOfferFx]);

const $makeOfferModalIsOpen = makeOfferDomain.createStore(false);

const setMakeOfferStage = createEvent<CreateOfferStage>();
const $makeOfferStage = makeOfferDomain.createStore<CreateOfferStage>(createOfferStages.initial);

$makeOfferStage.on(setMakeOfferStage, (_, stage) => stage);

const setHasEnoughFundsToOffer = createEvent<boolean>();
const $hasEnoughFundsToOffer = assetDetailsDomain.createStore(false);

$hasEnoughFundsToOffer.on(setHasEnoughFundsToOffer, (_, value) => value);

const $isOfferMaking = combine(signOfferTermsFx.pending, makeOfferFx.pending, (...pendings) =>
  pendings.some(Boolean),
);

sample({
  clock: makeOffer,
  fn: () => true,
  target: $makeOfferModalIsOpen,
});

sample({
  clock: submitOfferTerms,
  fn: (terms) => ({
    amount: ethToWei(terms.amount),
    amountWithAPR: ethToWei(terms.amountWithAPR),
    lendSeconds: daysToSeconds(terms.lendDays),
    coinAddress: terms.coinAddress,
  }),
  target: setOfferTerms,
});
/*
If user accepted desired terms or created his own
We will check whether he gave enough grants, otherwise open access grant page
*/
sample({
  source: $defaultAccount,
  clock: submitOfferTerms,
  fn: (account, terms) => ({
    amount: ethToWei(terms.amount),
    contractAddress: terms.coinAddress,
    account,
  }),
  target: checkAllocatedFundsForOfferFx,
});

// Get allowance for funds, to make offer
sample({
  clock: getAllowanceForFunds,
  source: $offerTerms,
  fn: (terms) => terms?.coinAddress,
  filter: Boolean,
  target: allocateFundForOfferFx,
});

/* If user has enough allocated funds, we will set the next flag as true */
sample({
  clock: [checkAllocatedFundsForOfferFx.finally, allocateFundForOfferFx.finally],
  filter: Boolean,
  fn: ({ status }) =>
    status === "done" ? createOfferStages.confirmation : createOfferStages.accessGrant,
  target: setMakeOfferStage,
});

/* If user has confirmed the terms, ask him to sign them*/
sample({
  clock: confirmOfferTerms,
  target: signOfferTermsFx,
});
/* If user has signed the terms, create the offer*/
sample({
  clock: signOfferTermsFx.doneData,
  target: makeOfferFx,
});

/* If offer has been made, get this offer */
sample({
  clock: makeOfferFx.done,
  target: fetchLenderOffersByAssetFx,
});

sample({
  clock: fetchLenderOffersByAssetFx.doneData,
  filter: $makeOfferModalIsOpen,
  target: resetMakeOfferDomain,
});

//#endregion

sample({
  clock: makeOfferFx.done,
  target: assetOffers.update,
});

sample({
  clock: makeOfferFx.done,
  fn: (): ToastOptions => ({
    title: "Offer has been made",
    kind: "success",
    duration: 3000,
  }),
  target: toast.show,
});

sample({
  clock: makeOfferFx.failData,
  fn: (error): ToastOptions => ({
    title: "Offer making failed",
    description: error.message,
    kind: "error",
    duration: 3000,
  }),
  target: toast.show,
});

sample({
  clock: cancelOfferMaking,
  target: resetMakeOfferDomain,
});

sample({
  source: $lenderOffersByAsset,
  clock: deleteOfferFx.done,
  filter: (offer, { params }) => offer?.id === params.id,
  fn: () => null,
  target: $lenderOffersByAsset,
});

makeOfferDomain.onCreateStore((store) => store.reset(resetMakeOfferDomain));

export {
  $makeOfferStage,
  makeOffer,
  submitOfferTerms,
  $normalizedOfferTerms,
  $hasEnoughFundsToOffer,
  confirmOfferTerms,
  $isOfferMaking,
  $makeOfferModalIsOpen,
  cancelOfferMaking,
  setMakeOfferStage,
  makeOfferState,
  getAllowanceForFunds,
  checkAllocatedFundsState,
  allocateFundForOfferFx,
  allocateFundForOfferState,
  $offerTerms,
};
