import { sample } from "effector";
import { useStore } from "effector-react";
import { $connectionStatus, $requiredChains, switchChain } from "shared/libs/effector-metamask";
import { connectionStatuses } from "shared/libs/effector-metamask/constants";
import { toast } from "shared/libs/toast";
import { ToastOptions } from "shared/libs/toast/types";
import { Button, Typography } from "@ui";
import { useEvent } from "effector-react/scope";

const WrongChainDescription = () => {
  const handleClick = useEvent(switchChain);
  const status = useStore($connectionStatus);
  const requiredChains = useStore($requiredChains);

  return (
    <>
      <Typography kind="body1">
        Wrong network selected, need to switch to {requiredChains?.[0].chainName}
      </Typography>
      <Button
        kind="danger"
        onClick={() => handleClick(null)}
        loading={status === "pending"}
        size="extra-small"
        wide
      >
        Switch chain
      </Button>
    </>
  );
};

sample({
  source: $connectionStatus,
  filter: (status) => status === connectionStatuses.wrongChain,
  fn: (): ToastOptions => ({
    id: "metamask-wrong-chain",
    kind: "error",
    title: "You connected to the wrong chain",
    description: <WrongChainDescription />,
  }),
  target: toast.show,
});

sample({
  clock: $connectionStatus,
  filter: (status) => status !== connectionStatuses.wrongChain,
  fn: () => "metamask-wrong-chain",
  target: toast.hide,
});
