import { AccountInfoModal } from "@entities/account";
import { MetamaskGuard, ProtectedAction } from "@features/metamask";
import { ReceivedOffers, OwnOffers } from "@features/offers";
import { PrimaryLayout, SecondaryLayout, Tabs } from "@ui";
import { useStore } from "effector-react";
import { useEvent } from "effector-react/scope";
import { Helmet } from "react-helmet";
import { PrimaryHeader } from "widgets";
import { OfferTabs, $activeTab, setActiveTab } from "./model";

export const Offers = () => {
  const activeTab = useStore($activeTab);

  const handlers = useEvent({
    setActiveTab,
  });

  return (
    <PrimaryLayout header={<PrimaryHeader />}>
      <Helmet title={`Dip Ocean - Offers`} />
      <MetamaskGuard>
        <SecondaryLayout title="Offers">
          <Tabs value={activeTab} onChange={handlers.setActiveTab}>
            <Tabs.Item title="Made offers" value={OfferTabs.own}>
              <OwnOffers />
            </Tabs.Item>

            <Tabs.Item title="Offers received" value={OfferTabs.received}>
              <ReceivedOffers />
            </Tabs.Item>
          </Tabs>
        </SecondaryLayout>
        <AccountInfoModal />
      </MetamaskGuard>
    </PrimaryLayout>
  );
};
