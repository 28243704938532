export const Eth = () => (
  <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 1V11.7368M7 1L1 9.5M7 1L13 9.5M7 18V11.7368M7 18L1 9.5M7 18L13 9.5M7 11.7368L13 9.5M7 11.7368L1 9.5"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
