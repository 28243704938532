import { Children, FunctionComponent, isValidElement, PropsWithChildren } from "react";
import { Button } from "shared/ui/atoms";

import styles from "./styles.module.scss";

type Value = string | number;

type ItemProps = {
  value: Value;
  title: string;
  children: JSX.Element;
};

const Item: FunctionComponent<ItemProps> = () => null;

type TabProps<T> = PropsWithChildren<{
  value: T;
  onChange: (value: T) => void;
  forceRender?: boolean;
}>;

const Root = <T extends number>({ value, onChange, forceRender, children }: TabProps<T>) => {
  const childrenArray = Children.toArray(children);
  const childProps = childrenArray.map((child) => {
    if (!isValidElement(child) || child.type !== Item) return null;

    return child.props as ItemProps;
  });

  return (
    <div>
      <div className={styles.header}>
        {childProps.map(
          (item) =>
            item && (
              <Button
                key={item.value}
                className={styles.tabItem}
                shape="rounded"
                onClick={() => onChange(item.value as T)}
                size="extra-small"
                kind={item.value === value ? "primary" : "ghost"}
              >
                {item.title}
              </Button>
            ),
        )}
      </div>

      <div className={styles.body}>
        {childProps.map(
          (child) =>
            child &&
            (child.value === value || forceRender) && (
              <div className={styles.content} data-hidden={child.value !== value && forceRender}>
                {child?.children}
              </div>
            ),
        )}
      </div>
    </div>
  );
};

export const Tabs = Object.assign(Root, {
  Item,
});
