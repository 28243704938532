import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { listingStages } from "@features/assets/model/types";
import { errorMessages } from "@configs/errorMessages";
import { Button, Field, Form, InputNumber, ModalBody, ModalFooter, Typography, Radio } from "@ui";
import { useEvent, useStore } from "effector-react";

import { $listingStage, setListingTerms } from "@features/assets/model";

import styles from "./styles.module.scss";
import { useMemo } from "react";
import { calculateAPR } from "shared/libs/calculate-apr";
import { calculateRepayment } from "shared/libs/calculate-repayment";
import { ListingForm } from "./types";
import { $coins } from "@entities/coins";
import { minAmount, precisions } from "@configs/constants";

const schema = yup.object().shape({
  apr: yup.number().max(300, errorMessages.lessThanField("300%")).typeError(""),
  amount: yup
    .number()
    .required(errorMessages.default)
    .min(minAmount, errorMessages.min(minAmount))
    .typeError(""),
  amountWithAPR: yup
    .number()
    .required(errorMessages.default)
    .moreThan(yup.ref("amount"), errorMessages.moreThanField("amount"))
    .typeError(""),
  lendDays: yup
    .number()
    .moreThan(0, errorMessages.moreThanField("0"))
    .max(90, errorMessages.lessThanField("90 days"))
    .required(errorMessages.default)
    .typeError(errorMessages.default),
  coinAddress: yup.string().required(errorMessages.default).typeError(errorMessages.default),
});

export const InitialView = () => {
  const stage = useStore($listingStage);
  const coins = useStore($coins);
  const form = useForm<ListingForm>({
    resolver: yupResolver(schema),
    mode: "onTouched",
    defaultValues: {
      coinAddress: coins[0].contractAddress,
    },
  });

  const coinAddress = form.watch("coinAddress");

  const selectedCoinName = useMemo(
    () => coins.find((coin) => coin.contractAddress === coinAddress),
    [coins, coinAddress],
  );

  const handlers = useEvent({
    setListingTerms,
  });

  const recalculateRepayment = () => {
    const formValues = form.getValues();

    const amount = parseFloat(String(formValues.amount));
    const percent = parseFloat(String(formValues.apr));
    const days = parseInt(String(formValues.lendDays));

    if (amount && percent && days) {
      const amountWithAPR = calculateRepayment({
        amount,
        percent,
        days,
      });

      form.setValue("amountWithAPR", amountWithAPR, { shouldValidate: true });
    }
  };

  const recalculateAPR = () => {
    const formValues = form.getValues();
    const loan = parseFloat(String(formValues.amount));
    const repayment = parseFloat(String(formValues.amountWithAPR));
    const days = parseInt(String(formValues.lendDays));

    if (loan && repayment && days && loan < repayment) {
      const apr = calculateAPR({
        loan: parseFloat(String(formValues.amount)),
        repayment: parseFloat(String(formValues.amountWithAPR)),
        days: parseInt(String(formValues.lendDays)),
      });
      form.setValue("apr", apr, { shouldValidate: true });
    }
  };

  if (stage !== listingStages.initial) return null;

  return (
    <Form className={styles.form} form={form} onSubmit={handlers.setListingTerms}>
      <ModalBody>
        <Field
          className={styles.field}
          label="In which currency are you willing to accept loans?"
          name="coinAddress"
        >
          <Radio.Group>
            {coins.map((coin) => (
              <Radio key={coin.id} value={coin.contractAddress}>
                {coin.currencySymbol}
              </Radio>
            ))}
          </Radio.Group>
        </Field>
        <Field
          onChange={recalculateRepayment}
          className={styles.field}
          label="What loan amount are you willing to accept?"
          name="amount"
          defaultValue={null}
        >
          <InputNumber
            maxDigitAfterComma={precisions.minPrincipalAmount}
            appendAfter={<Typography kind="body1">{selectedCoinName?.currencySymbol}</Typography>}
            placeholder="0.0"
          />
        </Field>
        <Field
          onChange={recalculateRepayment}
          className={styles.field}
          label="Specify the desired loan term"
          name="lendDays"
          defaultValue={null}
        >
          <InputNumber placeholder="0" appendAfter={<Typography kind="body1">days</Typography>} />
        </Field>

        <div className={styles.field}>
          <Typography kind="body1">Enter the annual interest rate on the loan</Typography>
          <div className={styles.aprLayout}>
            <Field onChange={recalculateRepayment} name="apr">
              <InputNumber
                placeholder="0.0"
                maxDigitAfterComma={precisions.percent}
                onChange={recalculateRepayment}
                appendAfter={<Typography kind="body1">%</Typography>}
              />
            </Field>
            <Field name="amountWithAPR" onChange={recalculateAPR}>
              <InputNumber
                maxDigitAfterComma={precisions.repayment}
                appendAfter={
                  <Typography kind="body1">{selectedCoinName?.currencySymbol}</Typography>
                }
                placeholder="0.0"
              />
            </Field>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <Button type="submit" wide kind="primary">
          List as collateral
        </Button>
      </ModalFooter>
    </Form>
  );
};
