import { directLoanFixedOfferService } from "@api/directLoanFixedOfferService";
import { OffersService } from "@api/offersService";
import { assetOffers, resetLenderOffersByAsset } from "@entities/assets";
import { $directLoanFixedOfferContract } from "@entities/lend/model";
import { attach, createEffect, createEvent, createStore, sample } from "effector";
import { createEffectState } from "shared/libs/create-effect-state";
import { createTxEffect } from "shared/libs/create-tx-effect";
import { CancelOfferPayload } from "./types";

const openCancelOfferModal = createEvent();
const closeCancelOfferModal = createEvent();

const $cancelOfferModalIsOpen = createStore(false);

$cancelOfferModalIsOpen.on(openCancelOfferModal, () => true).reset(closeCancelOfferModal);

const cancelOffer = createEvent<CancelOfferPayload>();

const cancelOfferFx = createTxEffect(
  attach({
    source: $directLoanFixedOfferContract,
    effect: async (contract, { nonce }: CancelOfferPayload) => {
      if (!contract) return Promise.reject();

      return directLoanFixedOfferService.cancelOffer(contract, nonce);
    },
  }),
  { confirmationsCount: 1 },
);

const deleteOfferFx = createEffect(OffersService.deleteOffer);

const deleteOfferFxState = createEffectState([cancelOfferFx, deleteOfferFx]);

const setDeletingOffersMap = createEvent<Record<string, boolean>>();
const $deletingOffersMap = createStore<Record<string, boolean>>({});

$deletingOffersMap.on(setDeletingOffersMap, (_, payload) => payload);

sample({
  clock: cancelOffer,
  target: cancelOfferFx,
});

sample({
  clock: cancelOfferFx.done,
  fn: ({ params }) => params,
  target: [deleteOfferFx, assetOffers.update, resetLenderOffersByAsset],
});

sample({
  clock: [cancelOfferFx, deleteOfferFx],
  source: $deletingOffersMap,
  fn: (map, offer) => ({
    ...map,
    [offer.id]: true,
  }),
  target: setDeletingOffersMap,
});

sample({
  clock: deleteOfferFx.done,
  target: [closeCancelOfferModal],
});

sample({
  clock: deleteOfferFx.done,
  source: assetOffers.$data,
  fn: (offers, { params }) => offers.filter((offer) => offer.id !== params.id),
  target: assetOffers.set,
});

sample({
  clock: [cancelOfferFx.finally, deleteOfferFx.finally],
  source: $deletingOffersMap,
  fn: (map, { params }) => {
    const mapCopy = { ...map };

    if (mapCopy[params.id]) delete mapCopy[params.id];

    return mapCopy;
  },
  target: setDeletingOffersMap,
});

export {
  cancelOffer,
  deleteOfferFx,
  $deletingOffersMap,
  $cancelOfferModalIsOpen,
  openCancelOfferModal,
  closeCancelOfferModal,
  cancelOfferFx,
  deleteOfferFxState,
};
