import { httpClient } from "@api/clients/http-client";
import { AssetMetadata, FetchAssetsResponse, GetAssetsParams, GetNFTParams } from "./types";

const fetchAssets = (params: GetAssetsParams): Promise<FetchAssetsResponse> =>
  httpClient.get("/collateral", {
    params,
  });

const fetchAssetMetadata = (params: GetNFTParams): Promise<AssetMetadata> =>
  httpClient.get("/collateral/info", {
    params,
    headers: {
      "X-Chain-ID": "all",
    },
  });

export const AssetsProviderService = {
  fetchAssets,
  fetchAssetMetadata,
};
