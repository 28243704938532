import { FunctionComponent, PropsWithChildren } from "react";
import { Icon, Typography } from "shared/ui/elements";

import styles from "./styles.module.scss";

type Props = PropsWithChildren<{}>;

export const Info: FunctionComponent<Props> = ({ children }) => {
  return (
    <span className={styles.box}>
      <Icon.Help />

      <div className={styles.info}>
        <span className={styles.innerIcon}>
          <Icon.Help />
        </span>
        <Typography kind="body2">{children}</Typography>
      </div>
    </span>
  );
};
