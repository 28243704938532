import { Default } from "./Default";
import { Pending } from "./Pending";
import { Error } from "./Error";
import { Info } from "./Info";

export const Message = Object.assign(Default, {
  Pending,
  Error,
  Info,
});
