import { Children, isValidElement, PropsWithChildren } from "react";
import { Icon, Typography } from "shared/ui/elements";
import styles from "./styles.module.scss";

type ItemProps<T> = PropsWithChildren<{
  title: string;
  value: T;
}>;

const Item = <T,>({ children }: ItemProps<T>) => <>{children}</>;

type RootProps<T> = PropsWithChildren<{
  value: null | T;
  onChange: (value: null | T) => void;
}>;

const Root = <T,>({ children, value, onChange }: RootProps<T>) => {
  const childProps = Children.map(children, (child) => {
    if (!isValidElement(child) || child.type !== Item) return null;

    return child.props as ItemProps<T>;
  });

  return (
    <section className={styles.box}>
      <ul className={styles.list}>
        {childProps?.map(
          (child) =>
            child && (
              <li
                key={String(child.value)}
                className={styles.item}
                data-active={child.value === value}
              >
                <div
                  className={styles.header}
                  onClick={() => onChange(value === child.value ? null : child.value)}
                >
                  <Typography kind="title4" weight="medium">
                    {child.title}
                  </Typography>

                  <span className={styles.icon}>
                    {child.value === value ? <Icon.Minus /> : <Icon.Plus />}
                  </span>
                </div>
                <div className={styles.content}>
                  <div className={styles.innerContent}>{child.children}</div>
                </div>
              </li>
            ),
        )}
      </ul>
    </section>
  );
};

export const Collapse = Object.assign(Root, { Item });
