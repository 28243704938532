import { getObjectValueByPath, ObjectPaths } from "../../../libs/get-value-by-path";
import cn from "classnames/bind";

import styles from "./styles.module.scss";
import { RefObject, useMemo, useRef } from "react";
import { Skeleton, Typography } from "shared/ui/elements";
import { IntersectionObserver } from "shared/libs/intersection-observer";

const cx = cn.bind(styles);

type Column<T extends Object> = {
  dataIndex: ObjectPaths<T>;
  title: string;
  indexKey?: keyof T;
  render?: (row: T, index: number) => JSX.Element | string | number | null;
  className?: string;
};

export type Columns<T> = Column<T>[];

type Props<T> = {
  columns: Columns<T>;
  data: Array<T>;
  indexKey: keyof T;
  defaultRowsCount?: number;
  loading?: boolean;
  noDataText?: string;
  onLoanMore?: () => void;
};

export const Table = <T extends object>({
  columns,
  data,
  indexKey,
  loading = false,
  defaultRowsCount = 1,
  noDataText = "No data",
  onLoanMore = () => {},
}: Props<T>) => {
  const rootRef = useRef<HTMLDivElement>(null);

  const mockDataList = useMemo(() => {
    return Array.from({ length: defaultRowsCount }, () => columns);
  }, [columns]);

  const defaultView = (
    <>
      {data.map((row, rowIndex) => (
        <tr className={cx("row")} key={String(row[indexKey])}>
          {columns.map((column) => (
            <td className={cx("cell")} key={String(column.dataIndex)}>
              <div className={cx("cellInner", column.className)}>
                {column.render
                  ? column.render(row, rowIndex)
                  : String(getObjectValueByPath(row, column.dataIndex))}
              </div>
            </td>
          ))}
        </tr>
      ))}

      {data.length !== 0 && (
        <IntersectionObserver disabled={loading} onIntersect={onLoanMore}>
          {(ref) => <div ref={ref} />}
        </IntersectionObserver>
      )}
    </>
  );

  const skeletonView = useMemo(
    () =>
      loading &&
      mockDataList.map((idx) => (
        <tr className={cx("row")} key={String(idx)}>
          {columns.map((column) => (
            <td className={cx("cell")} key={String(column.dataIndex)}>
              <div className={cx("cellInner", column.className)}>
                <Skeleton as="span" className={cx("skeletonText")} active />
              </div>
            </td>
          ))}
        </tr>
      )),
    [loading, data],
  );

  const noDataView = !loading && data.length === 0 && (
    <tr className={cx("emptyBox")}>
      <Typography kind="body2">{noDataText}</Typography>
    </tr>
  );

  return (
    <div className={cx("box")} ref={rootRef}>
      <table className={cx("table")}>
        <thead className={cx("header")}>
          {columns.map((column) => (
            <tr className={cx("column")} key={String(column.dataIndex)}>
              <td>{column.title}</td>
            </tr>
          ))}
        </thead>
        <tbody className={cx("body")}>
          {defaultView}
          {skeletonView}
          {noDataView}
        </tbody>
      </table>
    </div>
  );
};
