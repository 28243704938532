import { $coinsBalance, updateBalance } from "@features/viewer/model";
import { Button, Icon } from "@ui";
import { useStore } from "effector-react";
import { useEvent } from "effector-react/scope";

import styles from "./styles.module.scss";

export const Wallets = () => {
  const coinsBalance = useStore($coinsBalance);

  const handlers = useEvent({
    updateBalance,
  });

  return (
    <Button kind="simple" size="extra-small" onClick={() => handlers.updateBalance()}>
      <div className={styles.wallet}>
        <Icon.Eth />
        <span className={styles.text}>{coinsBalance[0]?.balance.eth || "0.0"}</span>
      </div>
      <div className={styles.wallet}>
        <Icon.Dai />
        <span>{coinsBalance[1]?.balance.eth || "0.0"}</span>
      </div>
    </Button>
  );
};
