import { getLoanTableColumns } from "@entities/loans/libs/loansTableColumns";
import {
  $normalizedOwnLoansHistory,
  ownLoansHistory,
  ownLoansHistoryGate,
} from "@entities/loans/model";
import { Table } from "@ui";
import { useEvent } from "effector-react";
import { useGate, useStore } from "effector-react/scope";
import { openAccountInfo } from "@entities/account";
import { useMemo } from "react";

export const OwnLoansHistory = () => {
  const data = useStore($normalizedOwnLoansHistory);
  const isPending = useStore(ownLoansHistory.$pending);

  const handlers = useEvent({
    openAccountInfo,
    getMore: ownLoansHistory.getMore,
  });

  const columns = useMemo(
    () =>
      getLoanTableColumns({
        openAccountInfo: handlers.openAccountInfo,
      }),
    [handlers],
  );

  useGate(ownLoansHistoryGate);

  return (
    <Table
      onLoanMore={handlers.getMore}
      loading={isPending}
      defaultRowsCount={5}
      columns={columns}
      data={data}
      indexKey="id"
    />
  );
};
