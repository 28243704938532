import { IERC721Service } from "@api/IERC721Service";
import { OffersService } from "@api/offersService";
import { Offer } from "@api/offersService/types";
import { primaryLazyLoadLimit } from "@configs/constants";
import { runtimeEnv } from "@configs/runTimeEnv";
import { attach, combine, createEvent, createStore, merge, sample } from "effector";
import { getAddress } from "ethers/lib/utils";
import { createDataList } from "shared/libs/create-data-list";
import { FetchDataListParams } from "shared/libs/create-data-list/types";
import { createTxEffect } from "shared/libs/create-tx-effect";

import { $signer } from "../../../shared/libs/effector-ethers";
import { $defaultAccount } from "../../../shared/libs/effector-metamask";
import { IERC721__factory } from "../../../shared/smart-contracts";

const setAssetTokenId = createEvent<string>();
const resetAssetTokenId = createEvent();
const $assetTokenId = createStore("");

$assetTokenId.on(setAssetTokenId, (_, value) => value).reset(resetAssetTokenId);

const setAssetContractAddress = createEvent<string>();
const $assetContractAddress = createStore<null | string>(null);

$assetContractAddress.on(setAssetContractAddress, (_, value) => getAddress(value));

const $IERC721Contract = combine($signer, $assetContractAddress, (signer, contractAddress) => {
  if (!signer || !contractAddress) return null;

  return IERC721__factory.connect(contractAddress, signer);
});

const checkIsAssetOwnerFx = attach({
  source: [$IERC721Contract, $defaultAccount],
  effect: async ([contract, defaultAccount], { tokenID }: { tokenID: string }) => {
    if (!contract || !defaultAccount) return Promise.reject();

    const result = await contract?.ownerOf(tokenID);

    return getAddress(result) === getAddress(defaultAccount);
  },
});

const checkIsAssetInLoanFx = attach({
  source: [$IERC721Contract, $defaultAccount],
  effect: async ([contract, defaultAccount], { tokenID }: { tokenID: string }) => {
    if (!contract || !defaultAccount) return Promise.reject();
    const result = await contract?.ownerOf(tokenID);

    return getAddress(result) === getAddress(runtimeEnv.directLoanFixedOfferAddress);
  },
});

const resetIsAssetOwner = createEvent();
const $isAssetOwner = createStore(false);
const $isAssetInLoan = createStore(false);

$isAssetOwner.on(checkIsAssetOwnerFx.doneData, (_, value) => value).reset(resetIsAssetOwner);
$isAssetInLoan.on(checkIsAssetInLoanFx.doneData, (_, value) => value);

sample({
  clock: $defaultAccount,
  target: resetIsAssetOwner,
});

const fetchAssetOffersFx = attach({
  source: {
    contractAddress: $assetContractAddress,
    tokenID: $assetTokenId,
  },
  effect: async ({ contractAddress, tokenID }, params: FetchDataListParams) => {
    if (!contractAddress || !tokenID) return Promise.reject();

    return OffersService.getAll({
      contractAddress,
      tokenID,
      state: "Valid",
      ...params,
    });
  },
});

const assetOffers = createDataList({
  effect: fetchAssetOffersFx,
  limit: primaryLazyLoadLimit,
});

const resetLenderOffersByAsset = createEvent();
const $lenderOffersByAsset = createStore<Offer | null>(null);

const fetchLenderOffersByAssetFx = attach({
  source: [$defaultAccount, $assetContractAddress, $assetTokenId],
  effect: async ([lender, contractAddress, tokenID]) => {
    try {
      if (!contractAddress || !lender || !tokenID) return Promise.reject();

      const [offer] = await OffersService.getAll({
        lender,
        contractAddress,
        tokenID,
        state: "Valid",
      });

      return offer || null;
    } catch {
      return null;
    }
  },
});

const checkAccessesToAssetForListingFx = attach({
  source: [$IERC721Contract, $defaultAccount],
  effect: ([contract, account]) => IERC721Service.isApprovedForAll(contract, account),
});

const grantAccessToAssetToListFx = createTxEffect(
  attach({
    source: $IERC721Contract,
    effect: IERC721Service.setApprovalForAll,
  }),
  {
    confirmationsCount: 1,
  },
);

$lenderOffersByAsset
  .on(fetchLenderOffersByAssetFx.doneData, (_, data) => data)
  .reset(merge([fetchLenderOffersByAssetFx, resetLenderOffersByAsset, $defaultAccount]));

export {
  $IERC721Contract,
  resetIsAssetOwner,
  setAssetContractAddress,
  checkIsAssetOwnerFx,
  checkIsAssetInLoanFx,
  $isAssetOwner,
  $isAssetInLoan,
  $lenderOffersByAsset,
  fetchLenderOffersByAssetFx,
  $assetTokenId,
  $assetContractAddress,
  setAssetTokenId,
  assetOffers,
  resetLenderOffersByAsset,
  checkAccessesToAssetForListingFx,
  grantAccessToAssetToListFx as grantAccessToAssetFxToListFx,
};
