import { routesMap } from "@configs/routing";
import { createDomain, createEvent, createStore, sample } from "effector";

const offersPageDomain = createDomain();

enum OfferTabs {
  own,
  received,
}

const $activeTab = createStore<OfferTabs>(OfferTabs.own);

const setActiveTab = createEvent<OfferTabs>();

$activeTab.on(setActiveTab, (_, tab) => tab);

offersPageDomain.onCreateStore((store) => store.reset(routesMap.offers.closed));

export { OfferTabs, $activeTab, setActiveTab };
