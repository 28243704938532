/* eslint-disable react/prop-types */
import { Coin, Collateral } from "@api/entitiesService/types";
import { Notification } from "@api/notificationsService/types";
import { routesMap } from "@configs/routing";
import { Link } from "@ui";
import { PropsWithChildren } from "react";
import { normalizeOffer } from "shared/libs/normalizeOffer";
import { calculatePassedTime } from "shared/libs/calculate-passed-time";
import { normalizeLoan } from "shared/libs/normalize-loan";

type Payload = {
  notification: Notification;
  coins: Record<string, Coin>;
  account: string;
};

type NormalizedNotificationType = {
  id: string;
  title: JSX.Element | string;
  description: JSX.Element | string;
  image: Collateral["imageURL"];
  seen: boolean;
  date: string;
  link: (props: PropsWithChildren<{}>) => JSX.Element;
};

type NotificationByType = Record<
  Notification["type"],
  (payload: Payload) => NormalizedNotificationType
>;

const notificationByType: NotificationByType = {
  OfferReceived: ({ notification, coins }) => {
    const offer = normalizeOffer({ offer: notification.data.offer!, coins });

    return {
      id: notification.id,
      title: "You have a new offer",
      seen: notification.seen,
      image: offer.collateral.imageURL,
      date: `${calculatePassedTime(notification.createdAt)} ago`,
      link: ({ children }) => (
        <Link
          to={routesMap.assetDetails}
          params={{
            tokenId: offer.collateral.tokenID,
            contractAddress: offer.collateral.contractAddress,
          }}
        >
          {children}
        </Link>
      ),
      description: (
        <>
          Your have an offer on{" "}
          <Link
            to={routesMap.assetDetails}
            params={{
              tokenId: offer.collateral.tokenID,
              contractAddress: offer.collateral.contractAddress,
            }}
          >
            {offer.collateral.collectionName}, {offer.collateral.name}
          </Link>{" "}
          from <b>{offer.lender.nickname}</b> for {offer.amount} over a period {offer.duration} and
          you will repay up to {offer.amountWithAPR} at an APR of {offer.apr}.To continue the
          process <b>please confirm the loan</b>.
        </>
      ),
    };
  },

  YourOfferAccepted: ({ notification, coins, account }) => {
    const loan = normalizeLoan({
      loan: notification.data.loan!,
      coins: coins,
      account,
    });

    return {
      id: notification.id,
      title: "Your offer has been accepted",
      seen: notification.seen,
      image: loan.collateral.imageURL,
      date: `${calculatePassedTime(notification.createdAt)} ago`,
      link: ({ children }) => (
        <Link
          to={routesMap.assetDetails}
          params={{
            tokenId: loan.collateral.tokenID,
            contractAddress: loan.collateral.contractAddress,
          }}
        >
          {children}
        </Link>
      ),
      description: (
        <>
          Your bid on{" "}
          <Link
            to={routesMap.assetDetails}
            params={{
              tokenId: loan.collateral.tokenID,
              contractAddress: loan.collateral.contractAddress,
            }}
          >
            {loan.collateral.collectionName}, {loan.collateral.name}
          </Link>{" "}
          , has been accepted.
        </>
      ),
    };
  },

  LoanExpiresSoon: ({ notification, coins, account }) => {
    const loan = normalizeLoan({
      loan: notification.data.loan!,
      coins: coins,
      account,
    });

    return {
      id: notification.id,
      title: "Your loan is due soon",
      seen: notification.seen,
      image: loan.collateral.imageURL,
      date: `${calculatePassedTime(notification.createdAt)} ago`,
      link: ({ children }) => (
        <Link
          to={routesMap.assetDetails}
          params={{
            tokenId: loan.collateral.tokenID,
            contractAddress: loan.collateral.contractAddress,
          }}
        >
          {children}
        </Link>
      ),
      description: (
        <>
          Your loan on{" "}
          <Link
            to={routesMap.assetDetails}
            params={{
              contractAddress: loan.collateral.contractAddress,
              tokenId: loan.collateral.tokenID,
            }}
          >
            {loan.collateral.collectionName}, {loan.collateral.name}
          </Link>
          , is almost due.
        </>
      ),
    };
  },

  LoanExpired: ({ notification, coins, account }) => {
    const loan = normalizeLoan({
      loan: notification.data.loan!,
      coins: coins,
      account,
    });

    return {
      id: notification.id,
      title: "Your loan has been foreclosed",
      seen: notification.seen,
      image: loan.collateral.imageURL,
      date: `${calculatePassedTime(notification.createdAt)} ago`,
      link: ({ children }) => (
        <Link
          to={routesMap.assetDetails}
          params={{
            contractAddress: loan.collateral.contractAddress,
            tokenId: loan.collateral.tokenID,
          }}
        >
          {children}
        </Link>
      ),
      description: (
        <>
          Your loan{" "}
          <Link
            to={routesMap.assetDetails}
            params={{
              contractAddress: loan.collateral.contractAddress,
              tokenId: loan.collateral.tokenID,
            }}
          >
            {loan.collateral.collectionName}, {loan.collateral.name}
          </Link>{" "}
          has been foreclosed. This means that the ownership of this piece has not been transferred
          to {loan.lender.nickname}. The loan amount of {loan.terms.amountWithAPR} no longer has to
          be repaid.
        </>
      ),
    };
  },

  YouGotRepayment: ({ notification, coins, account }) => {
    const loan = normalizeLoan({
      loan: notification.data.loan!,
      coins,
      account,
    });

    return {
      id: notification.id,
      title: "Your loan has been repaid",
      seen: notification.seen,
      image: loan.collateral.imageURL,
      date: `${calculatePassedTime(notification.createdAt)} ago`,
      link: ({ children }) => (
        <Link
          to={routesMap.assetDetails}
          params={{
            tokenId: loan.collateral.tokenID,
            contractAddress: loan.collateral.contractAddress,
          }}
        >
          {children}
        </Link>
      ),
      description: (
        <>
          Your loan on{" "}
          <Link
            to={routesMap.assetDetails}
            params={{
              tokenId: loan.collateral.tokenID,
              contractAddress: loan.collateral.contractAddress,
            }}
          >
            {loan.collateral.collectionName}, {loan.collateral.name}
          </Link>{" "}
          , has been repaid. You loaned, {loan.borrower.address} {loan.terms.amount} over a period
          of {loan.terms.duration} and you were repaid {loan.terms.amountWithAPR} at anAPR of{" "}
          {loan.terms.apr}.
        </>
      ),
    };
  },

  YouCanGetCollateral: ({ notification, coins, account }) => {
    const loan = normalizeLoan({
      loan: notification.data.loan!,
      coins,
      account,
    });

    return {
      id: notification.id,
      title: "Your loan has been repaid",
      seen: notification.seen,
      image: loan.collateral.imageURL,
      date: `${calculatePassedTime(notification.createdAt)} ago`,
      link: ({ children }) => (
        <Link
          to={routesMap.assetDetails}
          params={{
            tokenId: loan.collateral.tokenID,
            contractAddress: loan.collateral.contractAddress,
          }}
        >
          {children}
        </Link>
      ),
      description: (
        <>
          Your loan on{" "}
          <Link
            to={routesMap.assetDetails}
            params={{
              tokenId: loan.collateral.tokenID,
              contractAddress: loan.collateral.contractAddress,
            }}
          >
            {loan.collateral.collectionName}, {loan.collateral.name}
          </Link>{" "}
          , has been repaid. You loaned, {loan.borrower.address} {loan.terms.amount} over a period
          of {loan.terms.duration} and you were repaid {loan.terms.amountWithAPR} at anAPR of{" "}
          {loan.terms.apr}.
        </>
      ),
    };
  },

  LoanLiquidated: ({ notification, coins, account }) => {
    const loan = normalizeLoan({
      loan: notification.data.loan!,
      coins,
      account,
    });

    return {
      id: notification.id,
      title: "Your loan has been repaid",
      seen: notification.seen,
      image: loan.collateral.imageURL,
      date: `${calculatePassedTime(notification.createdAt)} ago`,
      link: ({ children }) => (
        <Link
          to={routesMap.assetDetails}
          params={{
            tokenId: loan.collateral.tokenID,
            contractAddress: loan.collateral.contractAddress,
          }}
        >
          {children}
        </Link>
      ),
      description: (
        <>
          Your loan on{" "}
          <Link
            to={routesMap.assetDetails}
            params={{
              tokenId: loan.collateral.tokenID,
              contractAddress: loan.collateral.contractAddress,
            }}
          >
            {loan.collateral.collectionName}, {loan.collateral.name}
          </Link>{" "}
          , has been repaid. You loaned, {loan.borrower.address} {loan.terms.amount} over a period
          of {loan.terms.duration} and you were repaid {loan.terms.amountWithAPR} at anAPR of{" "}
          {loan.terms.apr}.
        </>
      ),
    };
  },
};

type Params = {
  notifications: Notification[];
  coins: Record<string, Coin>;
  account: string;
};

export const normalizeNotifications = ({ notifications, coins, account }: Params) => {
  return notifications.map((notification) =>
    notificationByType[notification.type]!({
      notification,
      account,
      coins,
    }),
  );
};
