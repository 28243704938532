import { runtimeEnv } from "@configs/runTimeEnv";
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  merge,
  sample,
  scopeBind,
} from "effector";
import { utils } from "ethers";

import { getClientScope } from "../get-client-scope";

import { connectionStatuses } from "./constants";
import { metamask } from "./metamask";
import { Accounts, Chain, ConnectionStatus } from "./types";

const initMetamask = createEvent();

//#region Base Units
const connectFx = createEffect(metamask.requestAccounts);
const autoConnectFx = createEffect(metamask.requestAccounts);

const checkConnectionFx = createEffect(metamask.checkConnection);
const disconnected = createEvent();
const requestAccountsFx = createEffect(metamask.requestAccounts);

//#endregion

//#region HasMetamask
const $hasMetamask = createStore<boolean>(false);
const setHasMetamask = createEvent<boolean>();

$hasMetamask.on(setHasMetamask, (_, hasMetamask) => hasMetamask);
//#endregion

//#region Chains
const fetchChainFx = createEffect(metamask.requestChain);
const setRequiredChains = createEvent<Chain[]>();
const $currentChainId = createStore<null | number>(null);
const $requiredChains = createStore<Chain[] | null>(null);

const onChainChanged = createEvent<string>();
const switchChain = createEvent<Chain | null>();
const switchChainFx = createEffect(metamask.switchChain);

$requiredChains.on(setRequiredChains, (_, chains) => chains);

$currentChainId.on(merge([onChainChanged, fetchChainFx.doneData]), (_, chainId) => Number(chainId));

//#endregion

//#region connectionStatus
const $connectionStatus = createStore<ConnectionStatus>("idle");
const setConnectionStatus = createEvent<ConnectionStatus>();

$connectionStatus.on(setConnectionStatus, (_, status) => status);
//#endregion

//#region Accounts
const $accounts = createStore<Accounts>([]);
const $defaultAccount = combine([$accounts, $connectionStatus], ([accounts, connectionStatus]) => {
  if (connectionStatus !== connectionStatuses.connected || accounts.length === 0) return "";

  return utils.getAddress(accounts[0]);
});

const setAccounts = createEvent<Accounts>();
const resetAccounts = createEvent();

$accounts.on(setAccounts, (_, accounts) => accounts).reset(resetAccounts);

sample({
  clock: [requestAccountsFx.doneData, autoConnectFx.doneData, connectFx.doneData],
  target: setAccounts,
});
//#endregion

//#region setConnectionStatus

sample({
  clock: disconnected,
  fn: () => connectionStatuses.detected,
  target: setConnectionStatus,
});

sample({
  clock: [connectFx, requestAccountsFx, switchChainFx],
  fn: () => connectionStatuses.pending,
  target: setConnectionStatus,
});

sample({
  clock: [connectFx.fail, autoConnectFx.fail, requestAccountsFx.fail],
  fn: () => connectionStatuses.failed,
  target: setConnectionStatus,
});

sample({
  clock: [connectFx.done, autoConnectFx.done, requestAccountsFx.done, switchChainFx.done],
  fn: () => connectionStatuses.connected,
  target: setConnectionStatus,
});

sample({
  clock: [switchChainFx.fail, checkConnectionFx.done],
  source: {
    requiredChains: $requiredChains,
    currentChainId: $currentChainId,
  },
  filter: ({ requiredChains, currentChainId }) => {
    if (!requiredChains || !currentChainId) return false;

    return requiredChains.every((chain) => Number(chain.id) !== currentChainId);
  },
  fn: () => connectionStatuses.wrongChain,
  target: setConnectionStatus,
});

sample({
  clock: combine([$connectionStatus, $requiredChains, $currentChainId]),
  filter: ([connectionStatus]) =>
    connectionStatus === connectionStatuses.wrongChain ||
    connectionStatus === connectionStatuses.connected,
  fn: ([_, requiredChains, currentChainId]) => {
    const isChainInWhiteList =
      requiredChains === null ||
      requiredChains?.every((chain) => Number(chain.id) === currentChainId);

    if (!isChainInWhiteList) return connectionStatuses.wrongChain;

    return connectionStatuses.connected;
  },
  target: setConnectionStatus,
});

sample({
  source: $requiredChains,
  clock: switchChain,
  filter: (requiredChains, requiredChain) => Boolean(requiredChains?.length || requiredChain),
  fn: (requiredChains, requiredChain) => requiredChain || requiredChains![0],
  target: switchChainFx,
});

sample({
  source: combine([$requiredChains, $currentChainId]),
  clock: setAccounts,
  filter: (_, accounts) => accounts.length > 0,
  fn: ([requiredChains, currentChainId]) => {
    const isChainInWhiteList =
      requiredChains === null ||
      requiredChains?.every((chain) => Number(chain.id) === currentChainId);

    if (!isChainInWhiteList) return connectionStatuses.wrongChain;

    return connectionStatuses.connected;
  },
  target: setConnectionStatus,
});
//#endregion
// #region Initialization flow
sample({
  clock: initMetamask,
  fn: () => Boolean(metamask),
  target: setHasMetamask,
});

// If user has metamask, try to check, whether he has been connected before
sample({
  clock: setHasMetamask,
  filter: Boolean,
  target: [fetchChainFx, checkConnectionFx],
});

sample({
  clock: checkConnectionFx.fail,
  fn: () => connectionStatuses.detected,
  target: setConnectionStatus,
});

// If user has been connected before - try to connect again without any requests
sample({
  clock: checkConnectionFx.done,
  source: {
    requiredChains: $requiredChains,
    currentChainId: $currentChainId,
  },
  filter: ({ requiredChains, currentChainId }) => {
    const isChainInWhiteList =
      requiredChains === null ||
      requiredChains?.every((chain) => Number(chain.id) === currentChainId);

    return isChainInWhiteList;
  },
  target: autoConnectFx,
});

const handleAccountsChange = (accounts: unknown) => {
  const scope = getClientScope();

  if (Array.isArray(accounts)) {
    scopeBind(setAccounts, { scope })(accounts);

    if (accounts.length === 0) scopeBind(disconnected, { scope })();
  }
};

const handleChainChange = (chainId: unknown) => {
  const scope = getClientScope();

  if (typeof chainId === "string") scopeBind(onChainChanged, { scope })(chainId);
};

$hasMetamask.watch((hasMetamask) => {
  if (hasMetamask) {
    metamask.instance?.on("accountsChanged", handleAccountsChange);
    metamask.instance?.on("chainChanged", handleChainChange);
  }
});

//#endregion

//#region Save to localStorage
$defaultAccount.watch((account) => {
  if (typeof window === "undefined" || !account) return;

  localStorage.setItem("defaultAccount", account);
});

$currentChainId.watch((currentChainId) => {
  if (typeof window === "undefined" || currentChainId === null) return;

  localStorage.setItem("currentChainId", String(Number(currentChainId)));
});

const getDefaultAccount = () =>
  runtimeEnv.isClient ? localStorage.getItem("defaultAccount") : null;

const getCurrentChain = () => (runtimeEnv.isClient ? localStorage.getItem("currentChainId") : null);

//#endregion
export {
  initMetamask,
  $accounts,
  $defaultAccount,
  $hasMetamask,
  $connectionStatus,
  $currentChainId,
  $requiredChains,
  setRequiredChains,
  connectFx,
  switchChain,
  getDefaultAccount,
  getCurrentChain,
};
