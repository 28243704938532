import { routesMap } from "@configs/routing";
import { Columns, Link } from "@ui";
import { NormalizedLoan } from "../../../../shared/libs/normalize-loan";

type Params = {
  openAccountInfo: (userAddress: string) => void;
};

export const getLoanTableColumns = ({ openAccountInfo }: Params): Columns<NormalizedLoan> => [
  {
    dataIndex: "collateral.name",
    title: "Name",
    render: (row) => (
      <Link
        to={routesMap.assetDetails}
        params={{
          contractAddress: row.collateral.contractAddress,
          tokenId: row.collateral.tokenID,
        }}
      >
        {row.collateral.name}
      </Link>
    ),
  },
  {
    dataIndex: "state",
    title: "Status",
  },
  {
    dataIndex: "lender.nickname",
    title: "Lender",
    render: (row) => (
      <Link onClick={() => openAccountInfo(row.lender.address)}>{row.lender.nickname}</Link>
    ),
  },
  {
    dataIndex: "borrower.nickname",
    title: "Borrower",
    render: (row) => (
      <Link onClick={() => openAccountInfo(row.borrower.address)}>{row.borrower.nickname}</Link>
    ),
  },
  {
    dataIndex: "expiresAt",
    title: "Due",
  },
  {
    dataIndex: "terms.amount",
    title: "Loan value",
  },
  {
    dataIndex: "terms.amountWithAPR",
    title: "Repayment value",
  },
  {
    dataIndex: "terms.apr",
    title: "APR",
  },
];
