import { attach, combine, createDomain, createEvent, sample } from "effector";
import { $assetLoan, ownActiveDebts, resetAssetLoan } from "@entities/loans/model";
import { Loan } from "@api/loansService/types";
import {
  $assetTokenId,
  assetOffers,
  checkIsAssetInLoanFx,
  checkIsAssetOwnerFx,
} from "@entities/assets";
import {
  checkAllocatedFundsFx,
  checkBalanceOfFx,
  getERC20AllowanceFx,
} from "@entities/smart-contracts";
import { createEffectState } from "shared/libs/create-effect-state";
import { RepayLoanStages } from "./types";
import { $directLoanFixedOfferContract } from "@entities/lend/model";
import { directLoanFixedOfferService } from "@api/directLoanFixedOfferService";
import { ToastOptions } from "shared/libs/toast/types";
import { toast } from "shared/libs/toast";
import { resetAssetDetails } from "@features/assets";
import { createTxEffect } from "shared/libs/create-tx-effect";

//#region Repay loan
const repayLoanDomain = createDomain();
const resetRepayLoanDomain = createEvent();

const repayLoanStages: Record<RepayLoanStages, RepayLoanStages> = {
  accessGrant: "accessGrant",
  confirmation: "confirmation",
  initial: "initial",
};

const repayLoan = createEvent<Loan["loanID"]>();
const cancelLoanRepay = createEvent();
const $repayLoanStage = repayLoanDomain.createStore<RepayLoanStages>(repayLoanStages.initial);
const $repayingLoanId = repayLoanDomain.createStore<null | Loan["loanID"]>(null);
const getERC20Allowance = createEvent();

const getERC20AllowanceForLoanFx = createTxEffect(getERC20AllowanceFx, {
  confirmationsCount: 1,
});

const $repayingLoan = combine(
  ownActiveDebts.$data,
  $assetLoan,
  $repayingLoanId,
  (ownActiveDebts, assetLoan, repayingLoanId) => {
    if (assetLoan?.loanID === repayingLoanId) return assetLoan;

    return ownActiveDebts.find((debt) => debt.id === repayingLoanId) || null;
  },
);

const checkAllocatedFundsOfBorrowerFx = attach({
  source: $repayingLoan,
  mapParams: (_, loan) => ({
    account: String(loan?.borrower.address),
    amount: loan!.terms.amount,
    contractAddress: loan!.terms.coinAddress,
  }),
  effect: checkAllocatedFundsFx,
});

const checkBalanceOfBorrowerFx = attach({
  source: $repayingLoan,
  mapParams: (_, loan) => ({
    account: loan!.borrower.address,
    amount: loan!.terms.amount,
    contractAddress: loan!.terms.coinAddress,
  }),
  effect: checkBalanceOfFx,
});

const repayLoanFx = createTxEffect(
  attach({
    source: {
      contract: $directLoanFixedOfferContract,
      loan: $repayingLoan,
    },
    effect: async ({ contract, loan }) =>
      directLoanFixedOfferService.payBackLoan(contract, loan?.loanID),
  }),
  {
    confirmationsCount: 1,
  },
);

const checkAllocatedFundsOfBorrowerState = createEffectState(checkAllocatedFundsOfBorrowerFx, {
  domain: repayLoanDomain,
});
const checkBalanceOfBorrowerState = createEffectState(checkBalanceOfBorrowerFx, {
  domain: repayLoanDomain,
});
const getERC20AllowanceForLoanState = createEffectState(getERC20AllowanceForLoanFx, {
  domain: repayLoanDomain,
});
const payBackLoanState = createEffectState(repayLoanFx, {
  domain: repayLoanDomain,
});

const $repayLoanIsOpen = combine(
  $repayingLoanId,
  checkBalanceOfBorrowerState.$status,
  (selectedLoan, checkBalanceOfBorrowerStatus) =>
    selectedLoan !== null && checkBalanceOfBorrowerStatus === "succeed",
);

// On click to repay loan, check whether borrower has enough money
sample({
  clock: repayLoan,
  target: [$repayingLoanId, checkBalanceOfBorrowerFx],
});

// If there are enough money, check whether he has given enough permissions
sample({
  clock: checkBalanceOfBorrowerFx.done,
  target: [checkAllocatedFundsOfBorrowerFx],
});
// Otherwise show notification
sample({
  clock: checkBalanceOfBorrowerFx.failData,
  fn: (error): ToastOptions => ({
    title: "Offer acceptation failed",
    description: error.message,
    kind: "error",
    duration: 3000,
  }),
  target: toast.show,
});
// If there is enough, just open confirmation step
sample({
  clock: checkAllocatedFundsOfBorrowerState.$status,
  filter: (status) => status === "succeed",
  fn: () => repayLoanStages.confirmation,
  target: $repayLoanStage,
});
sample({
  clock: getERC20AllowanceForLoanFx.done,
  fn: () => repayLoanStages.confirmation,
  target: $repayLoanStage,
});
// Otherwise, open accessGrant step
sample({
  clock: checkAllocatedFundsOfBorrowerState.$status,
  filter: (status) => status === "failed",
  fn: () => repayLoanStages.accessGrant,
  target: $repayLoanStage,
});

sample({
  source: $assetLoan,
  clock: getERC20Allowance,
  fn: (loan) => loan.terms.coinAddress,
  filter: Boolean,
  target: getERC20AllowanceFx,
});
sample({
  clock: [cancelLoanRepay, repayLoanFx.done],
  target: resetRepayLoanDomain,
});

sample({
  clock: [repayLoanFx.done],
  fn: (): ToastOptions => ({
    kind: "success",
    title: "Loan has been repaid",
    duration: 3000,
  }),
  target: toast.show,
});

sample({
  clock: repayLoanFx.done,
  source: $assetTokenId,
  fn: (tokenID) => ({
    tokenID,
  }),
  target: [
    checkIsAssetOwnerFx,
    checkIsAssetInLoanFx,
    resetAssetLoan,
    resetAssetDetails,
    assetOffers.get,
  ],
});

repayLoanDomain.onCreateStore((store) => store.reset(resetRepayLoanDomain));
//#endregion

export {
  repayLoan,
  cancelLoanRepay,
  $repayingLoan,
  $repayLoanIsOpen,
  $repayLoanStage,
  getERC20AllowanceForLoanState,
  getERC20AllowanceForLoanFx,
  repayLoanFx,
  payBackLoanState,
  getERC20Allowance,
};
