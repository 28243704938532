import { AssetLoan } from "@features/loans";
import { AssetOffers } from "@features/offers";
import { Button, Content, Headline, Icon, PrimaryLayout, Tabs } from "@ui";

import "./model";

import {
  $assetDetailsLoading,
  $assetMetadata,
  $assetState,
  AssetListing,
} from "../../features/assets";

import styles from "./styles.module.scss";
import { AssetInfo } from "@features/assets/ui/AssetInfo";
import { AssetPrice } from "@features/assets/ui/AssetPrice";
import { DesiredTerms } from "@features/assets/ui/DesiredTerms";
import { useStore } from "effector-react";
import { $activeTab, setActiveTab } from "./model";
import { useEvent } from "effector-react/scope";
import { BottomTabs } from "./types";
import { AssetLoans } from "@features/loans/ui/AssetLoans";
import { AccountInfoModal } from "@entities/account";
import { AssetImage } from "@entities/assets";
import { $connectionStatus } from "shared/libs/effector-metamask";
import { connectionStatuses } from "shared/libs/effector-metamask/constants";
import { ProtectedAction } from "@features/metamask";
import { PrimaryHeader } from "widgets";
import { Helmet } from "react-helmet";

export const AssetDetails = () => {
  const activeTab = useStore($activeTab);
  const metadata = useStore($assetMetadata);
  const loading = useStore($assetDetailsLoading);
  const assetState = useStore($assetState);
  const connectionStatus = useStore($connectionStatus);

  const handlers = useEvent({
    setActiveTab,
  });

  const handleTabChange = (v: unknown) => handlers.setActiveTab(Number(v));

  return (
    <PrimaryLayout header={<PrimaryHeader />}>
      <Helmet title={`Dip Ocean - ${metadata?.name || "Asset"}`} />

      <Content>
        <Headline>
          <Button
            onClick={() => history.back()}
            kind="ghost"
            size="extra-small"
            appendBefore={<Icon.ArrowRight />}
          >
            Back
          </Button>
        </Headline>
        <div className={styles.mainInfo}>
          <AssetImage
            src={metadata?.imageURL ?? undefined}
            alt="Asset image"
            state={assetState}
            isLoading={loading}
          />

          <AccountInfoModal title="Asset Owner Information" />

          <div className={styles.mainInfoRight}>
            <AssetInfo />
            <AssetPrice />
            <AssetListing />
            <DesiredTerms />
            <div>
              {(connectionStatus === connectionStatuses.detected ||
                connectionStatus === connectionStatuses.pending ||
                connectionStatus === connectionStatuses.failed) && (
                <ProtectedAction size="medium" />
              )}
            </div>
            <AssetLoan />
          </div>
        </div>
        <div className={styles.bottomInfo}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tabs.Item value={BottomTabs.offers} title="offers">
              <AssetOffers />
            </Tabs.Item>

            <Tabs.Item value={BottomTabs.loans} title="Previous loans">
              <AssetLoans />
            </Tabs.Item>
          </Tabs>
        </div>
      </Content>
    </PrimaryLayout>
  );
};
