import { cloneElement, createElement, FC, FunctionComponent, PropsWithChildren } from "react";
import cn from "classnames/bind";

import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

type SkeletonProps = {
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  active?: boolean;
  children?: JSX.Element;
};

const Skeleton: FC<SkeletonProps> = ({ as, className, children, active = true }) => {
  const props = {
    className: cx("skeleton", className, children?.props.className),
    "data-skeleton-active": active,
  };

  if (children) {
    return active ? cloneElement(children, props) : children;
  }

  if (as) {
    return createElement(as, props, null);
  }

  throw Error("One of the props (Children or As) are required");
};

export { Skeleton, SkeletonProps };
