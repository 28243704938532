import { useStore } from "effector-react";

import {
  $listingModalIsOpen,
  openListingModal,
  closeListingModal,
  $assetDetailsLoading,
  $assetDetails,
  setListingTerms,
  $listingStage,
  $isCollectionPermitted,
} from "@features/assets/model";

import { $isAssetInLoan, $isAssetOwner } from "../../../../entities/assets/model";

import { Button, Modal } from "@ui";
import { useEvent } from "effector-react/scope";
import { InitialView } from "./InitialView";
import { ConfirmTermsView } from "./ConfirmTermsView";

import styles from "./styles.module.scss";
import { listingStages } from "@features/assets/model/types";

const modalDescriptionByStage: Record<number, string> = {
  [listingStages.initial]: "Proposed loan agreement",
  [listingStages.confirmation]: "Please confirm your terms",
};

export const AssetListing = () => {
  const isAssetOwner = useStore($isAssetOwner);
  const isAssetInLoan = useStore($isAssetInLoan);
  const isListingModalOpen = useStore($listingModalIsOpen);
  const assetDetailsLoading = useStore($assetDetailsLoading);
  const assetDetails = useStore($assetDetails);
  const stage = useStore($listingStage);
  const isCollectionPermitted = useStore($isCollectionPermitted);
  const handlers = useEvent({
    openListingModal,
    closeListingModal,
    setListingTerms,
  });

  if (
    !isAssetOwner ||
    isAssetInLoan ||
    assetDetailsLoading ||
    assetDetails !== null ||
    !isCollectionPermitted
  )
    return null;

  return (
    <div>
      <Modal
        customBody
        renderFooter={null}
        isOpen={isListingModalOpen}
        onClose={handlers.closeListingModal}
        title="List Collateral"
        className={styles.modal}
        description={modalDescriptionByStage[stage]}
      >
        <InitialView />
        <ConfirmTermsView />
      </Modal>

      <Button onClick={() => handlers.openListingModal()}>List as Collateral</Button>
    </div>
  );
};
