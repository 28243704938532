import { Provider } from "effector-react/scope";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "atomic-router-react/scope";

import { createHistoryRouter } from "atomic-router";

import { createClientHistory } from "shared/libs/history";
import { getClientScope } from "shared/libs/get-client-scope";

import { Application } from "./application";
import { allSettled, createEvent, sample } from "effector";
import { routes, notFoundRoute } from "@configs/routing";

const scope = getClientScope();

const ready = createEvent();

const history = createClientHistory(scope);

export const router = createHistoryRouter({
  routes,
  notFoundRoute,
});

sample({
  clock: ready,
  fn: () => history,
  target: router.setHistory,
});

const root = createRoot(document.querySelector("#root")!);

allSettled(ready, { scope }).then(() =>
  root.render(
    <Provider value={scope}>
      <RouterProvider router={router}>
        <Application />
      </RouterProvider>
    </Provider>,
  ),
);
