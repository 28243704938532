type ObjectPaths<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? // @ts-ignore
      `${Key}` | `${Key}.${ObjectPaths<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

const getObjectValueByPath = <T extends Object>(obj: T, path: ObjectPaths<T>) => {
  if (typeof obj !== "object" || obj === null) return obj;

  const keys = path.split(".");

  let current = obj;

  for (const key of keys) {
    const next = current[key];
    current = next;

    if (current === null || typeof current !== "object") break;
  }

  return current;
};

export { ObjectPaths, getObjectValueByPath };
