import { httpClient } from "@api/clients/http-client";
import { CreateOfferPayload, DeleteOfferPayload, GetOffersParams, Offer } from "./types";

const createOffer = (payload: CreateOfferPayload) => httpClient.post("/offer", payload);

const getAll = (params?: GetOffersParams): Promise<Array<Offer>> =>
  httpClient.get("/offer", { params });

const deleteOffer = ({ id }: DeleteOfferPayload) => httpClient.delete(`/offer/${id}`);

export const OffersService = {
  createOffer,
  getAll,
  deleteOffer,
};
