import { CreateListingPayload } from "../../../shared/api/listingService/types";

export type ListingTerms = Pick<CreateListingPayload, "desiredOffer">;

export enum listingStages {
  "initial",
  "confirmation",
}

export type ListingStage = number;

export enum createOfferStages {
  "initial",
  "accessGrant",
  "confirmation",
}

export type CreateOfferStage = number;
