import { FunctionComponent, PropsWithChildren } from "react";
import { Typography } from "shared/ui/elements";

import styles from "./styles.module.scss";

type Props = PropsWithChildren<{
  title?: string;
}>;

export const Headline: FunctionComponent<Props> = ({ title, children }) => (
  <div className={styles.headline}>
    {children || <Typography kind="title3">{title}</Typography>}
  </div>
);
