import {
  createElement,
  DetailedHTMLProps,
  FunctionComponent,
  HTMLAttributes,
  PropsWithChildren,
} from "react";

import styles from "./styles.module.scss";

type Kind = "title1" | "title2" | "title3" | "title4" | "body1" | "body2";

type Weight = "light" | "medium" | "bold";

type Props = PropsWithChildren<{
  kind: Kind;
  weight?: Weight;
  as?: keyof JSX.IntrinsicElements;
}> &
  DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>;

const Typography: FunctionComponent<Props> = ({
  kind,
  weight = "medium",
  children,
  className = "",
  as = "p",
  ...rest
}) => {
  return createElement(
    as,
    {
      "data-weight": weight,
      "data-kind": kind,
      className: [styles.typography, className].join(" "),
      ...rest,
    },
    children,
  );
};

export { Typography };
