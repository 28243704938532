import { FunctionComponent, PropsWithChildren } from "react";
import { Content, Headline } from "shared/ui/atoms";
import cn from "classnames/bind";

import styles from "./styles.module.scss";

const cx = cn.bind(styles);

type Props = PropsWithChildren<{
  title: string;
  className?: string;
}>;

export const SecondaryLayout: FunctionComponent<Props> = ({ title, children, className }) => (
  <Content className={cx("content")}>
    <Headline title={title} />

    <section className={cx("innerBox", className)}>{children}</section>
  </Content>
);
