import { routesMap } from "@configs/routing";
import { Button, Icon, Link, Logo, Tag } from "@ui";

import styles from "./styles.module.scss";
import { useStore } from "effector-react";
import { $maskedViewerAddress } from "@features/viewer/";
import { ProtectedAction } from "@features/metamask";
import { NotificationsPopup } from "@entities/notifications";
import { Wallets } from "@features/viewer";
import { $defaultAccount } from "shared/libs/effector-metamask";

const nav = [
  {
    text: "Explore",
    route: routesMap.explore,
  },
  {
    text: "Assets",
    route: routesMap.userAssets,
  },
  {
    text: "Loans",
    route: routesMap.loans,
  },
  {
    text: "Offers",
    route: routesMap.offers,
  },
  {
    text: "About",
    route: routesMap.about,
  },
];

export const PrimaryHeader = () => {
  const maskedViewerAddress = useStore($maskedViewerAddress);

  return (
    <header className={styles.header}>
      <Link to={routesMap.home}>
        <span className={styles.logo}>
          <Logo />
          <div className={styles.logoText}>Dip Ocean</div>
        </span>
      </Link>

      <div className={styles.rightSide}>
        <nav>
          <ul className={styles.navMenu}>
            {nav.map((navItem, idx) => (
              <li className={styles.navItem} key={idx}>
                <Link kind="secondary" className={styles.navLink} to={navItem.route}>
                  {navItem.text}
                </Link>
              </li>
            ))}
          </ul>
        </nav>

        <ProtectedAction appendBefore={<Icon.Wallet />}>
          <span className={styles.bellBox}>
            <NotificationsPopup />
          </span>

          <div className={styles.walletsBox}>
            <Wallets />
          </div>
          <Link to={routesMap.profile}>
            <Button kind="ghost" size="extra-small" appendBefore={<Icon.Home />}>
              {maskedViewerAddress}
            </Button>
          </Link>
        </ProtectedAction>
      </div>
    </header>
  );
};
