import { runtimeEnv } from "@configs/runTimeEnv";
import { attach } from "effector";
import { parseBytes32String } from "ethers/lib/utils";
import { $signer } from "shared/libs/effector-ethers";
import { PermittedNFTsAndTypeRegistry__factory } from "shared/smart-contracts";
import { CheckIsCollectionPermittedParams } from "./types";

const $permittedNFTsAndTypeRegistry = $signer.map((signer) => {
  if (!signer) return null;

  return PermittedNFTsAndTypeRegistry__factory.connect(
    runtimeEnv.permittedNftsAndTypeRegistryAddress,
    signer,
  );
});

const checkIsCollectionPermittedFx = attach({
  source: $permittedNFTsAndTypeRegistry,
  effect: async (contract, { contractAddress }: CheckIsCollectionPermittedParams) => {
    if (!contract) return Promise.reject();

    const data = await contract.getNFTPermit(contractAddress);

    return Boolean(parseBytes32String(data));
  },
});

export { checkIsCollectionPermittedFx };
