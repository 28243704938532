import { getCoins } from "@entities/coins";
import { fetchNetworksFx } from "@entities/networks/model";
import { sample } from "effector";
import { clientStarted } from "shared/libs/client-started";
import { initMetamask } from "shared/libs/effector-metamask";
import { serverStarted } from "shared/libs/server-started";

sample({
  clock: serverStarted,
  target: [fetchNetworksFx, getCoins],
});

sample({
  clock: clientStarted,
  target: [initMetamask],
});
