import { runtimeEnv } from "@configs/runTimeEnv";
import { IERC721 } from "shared/smart-contracts";

const isApprovedForAll = async (contract: IERC721 | null, defaultAccount: string) => {
  if (!contract) return Promise.reject();

  const isApproved = await contract?.isApprovedForAll(
    defaultAccount,
    runtimeEnv.directLoanFixedOfferAddress,
  );

  if (!isApproved) return Promise.reject(Error("access permission not found"));

  return isApproved;
};

const setApprovalForAll = async (contract: IERC721 | null) => {
  if (!contract) return Promise.reject();

  return contract?.setApprovalForAll(runtimeEnv.directLoanFixedOfferAddress, true);
};

export const IERC721Service = {
  isApprovedForAll,
  setApprovalForAll,
};
