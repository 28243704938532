import {
  createElement,
  DetailedHTMLProps,
  FunctionComponent,
  HTMLAttributes,
  PropsWithChildren,
} from "react";
import cn from "classnames/bind";
import styles from "./styles.module.scss";
import classNames from "classnames";

const cx = cn.bind(styles);

type Props = PropsWithChildren<{
  as?: keyof JSX.IntrinsicElements;
}> &
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

export const Content: FunctionComponent<Props> = ({
  as = "section",
  children,
  className,
  ...otherProps
}) => createElement(as, { ...otherProps, className: cx("box", className) }, children);
