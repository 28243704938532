export const errorMessages = {
  required: "This field is required",
  lessThanField: (field: string) => `Should be less than ${field}`,
  moreThanField: (field: string) => `Should be more than ${field}`,
  min: (value: number) => `Should be more at least ${value}`,
  default: "The data entered above does not meet the conditions",
  alreadyExist: (field: string) => `${field} already exist`,
  shouldBeLessThanBalance: "There aren’t enough funds in your account",
  APRLimit: (limit: number) => `* APR can’t be more than ${limit}% per annum`,
};
