import { Collapse, PrimaryLayout, SecondaryLayout, Typography } from "@ui";
import classNames from "classnames";
import { useStore } from "effector-react";
import { useEvent } from "effector-react/scope";
import { Helmet } from "react-helmet";
import { PrimaryHeader } from "widgets";
import { $activeQuestion, setActiveQuestion } from "./model";
import styles from "./styles.module.scss";

const FAQ = [
  {
    title: "Who is a borrower?",
    content: [
      "A borrower is an individual who pledges their NFTs as collateral for a loan. Lenders can offer borrowers loans backed by their NFTs, with the borrower having the option to accept or reject the offer.",
      "DipOcean allows you to receive wETH or DAI without having to sell your NFT.",
    ],
  },
  {
    title: "Who is a lender?",
    content:
      "The lender is the individual who offers a loan against the borrower's NFT. DipOcean allows lenders to exchange their loans in wETH or DAI for non-fungible tokens. Set your loan amount, APR, and duration to control your risks fully.",
  },
  {
    title: "What are the benefits of listing NFTs?",
    content:
      "Listing your NFT as collateral allows you to receive immediate payment in wETH or DAI. Loans enable you to unlock the value of an asset while also providing liquidity to NFTs.",
  },
  {
    title: "Why should I make offers and lend crypto assets?",
    content:
      "Providing loans earns you interest. You’ll also control the loan terms (loan value, APR, loan duration). And if the borrower defaults, you receive the collateral.",
  },
  {
    title: "What NFT collections do we support?",
    content: [
      "DipOcean currently supports more than 30 NFT collections, including:",
      <ul key="list" className={styles.list}>
        <li>Ethereum Name Service</li>
        <li>Otherdeed for Otherside</li>
        <li>Bored Ape Yacht Club</li>
        <li>CryptoPunks</li>
        <li>Tykes</li>
        <li>y00ts Yacht Club</li>
      </ul>,
    ],
  },
  {
    title: "What commission does the platform take?",
    content: [
      "The platform has a commission structure that is measured in percentages. This commission is applied to the lender's interest when the loan is repaid.",
      "The commission is currently set at 5%.",
    ],
  },
];

const wrapLines = (
  content: string | JSX.Element | (string | JSX.Element)[],
  idx?: number,
): JSX.Element =>
  content instanceof Array ? (
    <>{content.map(wrapLines)}</>
  ) : (
    <Typography key={idx} kind="body1">
      {content}
    </Typography>
  );

export const About = () => {
  const activeQuestion = useStore($activeQuestion);

  const handlers = useEvent({
    setActiveQuestion,
  });

  return (
    <PrimaryLayout header={<PrimaryHeader />}>
      <Helmet title={`Dip Ocean - About`} />
      <SecondaryLayout title="About">
        <article className={classNames(styles.aboutText, styles.text)}>
          <Typography kind="body1">
            DipOcean is a peer-to-peer platform that allows you to use non-fungible tokens (NFTs) as
            collateral for a loan or to offer loans to NFT owners. Loans are secured by tokens based
            on the ERC721 standard. The ERC1155 standard will be added in the future.
          </Typography>
          <Typography kind="body1">
            Here are our most frequently asked questions. If you still have any questions after
            reading it, please email our team at{" "}
            <a href="mailto:hi@dipocean.com">hi@dipocean.com</a>.
          </Typography>
        </article>
        <Collapse value={activeQuestion} onChange={handlers.setActiveQuestion}>
          {FAQ.map((item, idx) => (
            <Collapse.Item title={item.title} value={idx} key={idx}>
              <div className={styles.text}>{wrapLines(item.content)}</div>
            </Collapse.Item>
          ))}
        </Collapse>
      </SecondaryLayout>
    </PrimaryLayout>
  );
};
