import {
  combine,
  createDomain,
  createEffect,
  createEvent,
  Domain,
  Effect,
  merge,
  sample,
} from "effector";
import { FetchDataListParams, Sort } from "./types";

type Params<T> = {
  effect: Effect<FetchDataListParams, T[], Error>;
  limit: number;
  domain?: Domain;
};

const defaultDomain = createDomain();

export const createDataList = <T extends Object>({
  effect,
  limit,
  domain = defaultDomain,
}: Params<T>) => {
  const getFx = createEffect(effect);
  const getMoreFx = createEffect(effect);
  const sortFx = createEffect(effect);
  const updateFx = createEffect(effect);

  const get = createEvent();
  const getMore = createEvent();
  const update = createEvent();
  const sort = createEvent<Sort<T>>();
  const reset = createEvent();
  const set = createEvent<Array<T>>();

  const $currentOffset = domain.createStore(0);
  const $currentSort = domain.createStore<Sort<T> | null>(null);
  const $data = domain.createStore<Array<T>>([]);
  const $allDataFetched = domain.createStore(false);
  const $hasError = domain.createStore(false);

  const $pending = combine(
    [getFx.pending, getMoreFx.pending, sortFx.pending, updateFx.pending],
    (pendings) => pendings.some(Boolean),
  );

  $currentOffset
    .on(
      merge([getFx.done, sortFx.done, getMoreFx.done]),
      (current, { params }) => Number(params.offset) + limit,
    )
    .on(set, (_, data) => data.length)
    .reset(merge([get, sort]));

  $data
    .on(merge([set, getFx.doneData, sortFx.doneData, updateFx.doneData]), (_, data) => data)
    .on(getMoreFx.doneData, (current, data) => [...current, ...data])
    .reset(reset);

  $allDataFetched
    .on(
      merge([getFx.doneData, getMoreFx.doneData, sortFx.doneData, updateFx.doneData]),
      (_, result) => limit > result.length,
    )
    .on([getFx.fail, getMoreFx.fail, sortFx.fail, updateFx.fail], () => true)
    .reset(merge([get, sort]));

  $hasError.on(merge([getFx.fail, getMoreFx.fail, sortFx.fail, updateFx.fail]), () => true);

  sample({
    clock: get,
    source: {
      currentOffset: $currentOffset,
      currentSort: $currentSort,
    },
    fn: ({ currentSort, currentOffset }) => ({
      limit,
      offset: currentOffset,
      sortBy: currentSort ? JSON.stringify(currentSort) : undefined,
    }),
    target: getFx,
  });

  sample({
    clock: getMore,
    filter: $allDataFetched.map((v) => !v),
    source: {
      currentOffset: $currentOffset,
      currentSort: $currentSort,
    },
    fn: ({ currentSort, currentOffset }) => ({
      limit,
      offset: currentOffset,
      sortBy: currentSort ? JSON.stringify(currentSort) : undefined,
    }),
    target: getMoreFx,
  });

  sample({
    clock: sort,
    source: {
      currentOffset: $currentOffset,
      currentSort: $currentSort,
    },
    fn: ({ currentSort, currentOffset }) => ({
      limit,
      offset: currentOffset,
      sortBy: currentSort ? JSON.stringify(currentSort) : undefined,
    }),
    target: sortFx,
  });

  sample({
    clock: update,
    source: {
      currentOffset: $currentOffset,
      currentSort: $currentSort,
    },
    fn: ({ currentSort, currentOffset }) => ({
      limit: currentOffset,
      offset: 0,
      sortBy: currentSort ? JSON.stringify(currentSort) : undefined,
    }),
    target: updateFx,
  });

  return {
    $data,
    $allDataFetched,
    $pending,
    get,
    getMore,
    sort,
    update,
    reset,
    set,
    $hasError,
  };
};
