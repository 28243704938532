import { Button, Message, ModalBody, ModalFooter, ReadOnlyField, Typography } from "@ui";
import { useEvent, useStore } from "effector-react/scope";
import styles from "./styles.module.scss";
import { repayLoanFx, payBackLoanState, cancelLoanRepay } from "@features/loans/model";
import { TxState } from "shared/libs/create-tx-effect";
import { $normalizedAssetLoan } from "@entities/loans/model";

export const ConfirmationView = () => {
  const state = useStore(repayLoanFx.$state);
  const error = useStore(payBackLoanState.$error);
  const loan = useStore($normalizedAssetLoan);

  const loading = state === TxState.confirmation || state === TxState.pending;

  const handlers = useEvent({
    payBackLoanFx: repayLoanFx,
    cancelLoanRepay,
  });

  if (!loan) return null;

  return (
    <>
      <ModalBody className={styles.layout}>
        <Message>
          <Typography kind="body1">Sign the transition</Typography>
          <Typography kind="body1">
            Please click “Sign” to access the MetaMask popup and confirm the transaction to begin
            the loan
            <br />
            <br />
            By signing this transaction you agree to our <a href="#">Terms of service</a> and{" "}
            <a href="#">Terms of use</a>
          </Typography>
        </Message>

        <ReadOnlyField kind="secondary" label="Lender" value={loan.amountWithAPR} />

        {state === TxState.pending && (
          <Message.Pending
            title="Waiting for signature..."
            description="Please approve the transaction in your wallet"
          />
        )}

        {state === TxState.confirmation && <Message.Pending title="Executing: Repay loan" />}

        {error && <Message.Error title="Error executing transaction" description={error} />}
      </ModalBody>

      <ModalFooter>
        <Button disabled={loading} onClick={() => handlers.cancelLoanRepay()} kind="secondary">
          Back
        </Button>
        <Button loading={loading} onClick={() => handlers.payBackLoanFx()}>
          Sign
        </Button>
      </ModalFooter>
    </>
  );
};
