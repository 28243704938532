import { Button, ReadOnlyField } from "@ui";
import { useEvent, useStore } from "effector-react/scope";
import { confirmTerms, cancelOfferAccept, $normalizedAcceptedOffer } from "@entities/offers";
import { Typography } from "shared/ui/elements";

import commonStyles from "../styles.module.scss";
import styles from "./styles.module.scss";
import { checkAccessesToAssetForListingFx } from "@entities/assets";

export const TermsView = () => {
  const offer = useStore($normalizedAcceptedOffer);
  const isAccessesChecking = useStore(checkAccessesToAssetForListingFx.pending);

  const handlers = useEvent({
    confirmTerms,
    cancelOfferAccept,
  });

  const handleBack = () => handlers.cancelOfferAccept();
  const handleOk = () => handlers.confirmTerms();

  if (!offer) return null;

  return (
    <div className={commonStyles.modalBody}>
      <div className={commonStyles.modalContent}>
        <div className={styles.termsList}>
          <ReadOnlyField label="Lender" value={offer.lender.address} />
          <ReadOnlyField label="For" value={`${offer.amount} ${offer.coin?.name}`} />
          <ReadOnlyField label="Duration" value={offer.duration} />
          <ReadOnlyField label="APR" value={`${offer.apr}%`} />
          <ReadOnlyField label="Repayment" value={`${offer.amountWithAPR} ${offer.coin?.name}`} />
        </div>

        <Typography kind="body2" weight="medium" className={styles.notion}>
          Please note that once an offer is accepted, it cannot be cancelled.
        </Typography>
      </div>

      <div className={commonStyles.modalFooter}>
        <Button kind="ghost" onClick={handleBack}>
          No
        </Button>
        <Button loading={isAccessesChecking} onClick={handleOk}>
          Yes
        </Button>
      </div>
    </div>
  );
};
