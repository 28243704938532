import { httpClient } from "@api/clients/http-client";
import { GetAllParams, MarkAsReadParams, Notification } from "./types";

const getAll = (params: GetAllParams): Promise<Notification[]> =>
  httpClient.get("/notification", { params });

const markAsRead = (params: MarkAsReadParams) =>
  httpClient.put("/notification/read", null, { params });

export const NotificationsService = {
  getAll,
  markAsRead,
};
