import { interval } from "patronum/interval";

import { cancelListingFx } from "@entities/listings";

import {
  attach,
  combine,
  createDomain,
  createEffect,
  createEvent,
  createStore,
  merge,
  sample,
} from "effector";
import { createGate } from "effector-react";

import {
  $assetContractAddress,
  $assetTokenId,
  $isAssetInLoan,
  assetOffers,
  checkIsAssetInLoanFx,
  resetIsAssetOwner,
  resetLenderOffersByAsset,
} from "../../../entities/assets/model";
import { AssetsProviderService } from "../../../shared/api/assetsProviderService";
import { ListingService } from "../../../shared/api/listingService";
import { GetListingParams, ListedAsset } from "../../../shared/api/listingService/types";
import {
  $connectionStatus,
  $currentChainId,
  $defaultAccount,
} from "../../../shared/libs/effector-metamask";

import { CreateOfferStage, createOfferStages, listingStages, ListingTerms } from "./types";
import { $assetLoan, $assetLoanExpired, resetAssetLoan } from "@entities/loans/model";
import { toast } from "shared/libs/toast";
import { ToastOptions } from "shared/libs/toast/types";
import { ListingForm } from "../ui/Listing/InitialView/types";
import { weiToEth } from "shared/libs/wei-to-eth";
import { daysToSeconds, secondsToDays } from "shared/libs/format-days";
import { $coins } from "@entities/coins";
import { pluralize } from "shared/libs/pluralize";
import { calculateAPR } from "shared/libs/calculate-apr";
import { calculateLeftTime } from "shared/libs/calculate-left-time";
import { ethToWei } from "shared/libs/eth-to-wei";
import { $loanInProcessing } from "@features/loans/model/asset-loans";
import { Asset, AssetMetadata } from "@api/assetsProviderService/types";
import { checkIsCollectionPermittedFx } from "@entities/smart-contracts/model/permittedNFTsAndTypeRegistry";
import { acceptOfferFx } from "@entities/offers";
import { precisions, primaryLazyLoadLimit } from "@configs/constants";
import { createDataList } from "shared/libs/create-data-list";
import { FetchDataListParams } from "shared/libs/create-data-list/types";
import { connectionStatuses } from "shared/libs/effector-metamask/constants";

//#region Assets List
const ownAssetsDomain = createDomain();
const resetOwnAssetDomain = createEvent();
const ownAssetsListGate = createGate();

const $ownAssets = ownAssetsDomain.createStore<Array<Asset>>([]);
const $ownAssetsPageKey = ownAssetsDomain.createStore<string>("");
const $ownAssetsCount = ownAssetsDomain.createStore(0);

const $ownAssetsLoaded = combine(
  $ownAssetsCount,
  $ownAssets,
  (ownAssetsCount, ownAssets) => ownAssetsCount <= ownAssets.length,
);

const fetchOwnAssetsFx = attach({
  source: $defaultAccount,
  effect: (ownerAddress) =>
    AssetsProviderService.fetchAssets({
      ownerAddress,
      pageSize: primaryLazyLoadLimit,
    }),
});

const fetchMoreOwnAssetsFx = attach({
  source: {
    defaultAccount: $defaultAccount,
    pageKey: $ownAssetsPageKey,
  },
  effect: ({ defaultAccount, pageKey }) =>
    AssetsProviderService.fetchAssets({
      ownerAddress: defaultAccount,
      pageKey: pageKey || undefined,
    }),
});

const $ownAssetsLoading = combine(
  fetchOwnAssetsFx.pending,
  fetchMoreOwnAssetsFx.pending,
  (...pendings) => pendings.some(Boolean),
);

$ownAssetsPageKey.on(
  [fetchMoreOwnAssetsFx.done, fetchOwnAssetsFx.done],
  (_, { result }) => result.pageKey || "",
);

sample({
  clock: [fetchMoreOwnAssetsFx.doneData],
  source: $ownAssets,
  fn: (ownAssets, { collaterals }) => {
    return [...ownAssets, ...collaterals];
  },
  target: $ownAssets,
});

sample({
  clock: fetchOwnAssetsFx.doneData,
  fn: ({ collaterals }) => collaterals,
  target: $ownAssets,
});

sample({
  clock: $defaultAccount,
  target: resetOwnAssetDomain,
});

sample({
  clock: fetchOwnAssetsFx.doneData,
  fn: ({ totalCount }) => totalCount,
  target: $ownAssetsCount,
});

sample({
  clock: [ownAssetsListGate.open, $defaultAccount, $currentChainId],
  source: combine([ownAssetsListGate.status, $defaultAccount]),
  filter: ([isOwnNftsMounted, defaultAccount]) => isOwnNftsMounted && Boolean(defaultAccount),
  target: fetchOwnAssetsFx,
});

ownAssetsDomain.onCreateStore((store) => store.reset(resetOwnAssetDomain));
//#endregion

//#region Asset Details
const resetAssetDetailsDomain = createEvent();
const assetDetailsDomain = createDomain();

const fetchAssetsMetadataFx = createEffect(AssetsProviderService.fetchAssetMetadata);

const fetchAssetDetailsFx = createEffect(async ({ tokenID, contractAddress }: GetListingParams) => {
  if (!tokenID || !contractAddress) return Promise.reject();

  return ListingService.get({
    tokenID,
    contractAddress,
    state: "Opened",
  });
});

const updateAssetDetailsFx = attach({
  source: {
    tokenID: $assetTokenId,
    contractAddress: $assetContractAddress,
  },
  effect: (params) => ListingService.get({ ...params, state: "Opened" }),
});

const $assetMetadata = assetDetailsDomain.createStore<AssetMetadata | null>(null);

const resetAssetDetails = createEvent();
const $assetDetails = assetDetailsDomain.createStore<ListedAsset | null>(null);
const $isCollectionPermitted = assetDetailsDomain.createStore(false);

$assetDetails.reset(resetAssetDetails);

const $assetDetailsLoading = combine(
  $assetMetadata,
  fetchAssetsMetadataFx.pending,
  fetchAssetDetailsFx.pending,
  (assetMetadata, ...pending) => !assetMetadata && pending.some(Boolean),
);

$isCollectionPermitted.on(checkIsCollectionPermittedFx.doneData, (_, result) => Boolean(result));

const $assetState = combine(
  $assetDetails,
  $assetLoan,
  $isAssetInLoan,
  $loanInProcessing,
  $assetLoanExpired,
  (details, loan, isAssetInLoan, loanInProcessing, loanExpired) => {
    if (loan && loanExpired && (loan?.state === "Expired" || loan?.state === "Active"))
      return "Defaulted";

    if (
      (isAssetInLoan && loan?.state === "Active") ||
      (isAssetInLoan && loan === null) ||
      loanInProcessing
    )
      return "Escrow";

    if (details?.state === "Opened") return "Listed";

    if (details === null || details?.state === "Closed") return "Unlisted";

    return "loading";
  },
);
const $listingExpiration = createStore<null | false | string>(null);

const startListingCountdown = createEvent();
const stopListingCountdown = createEvent();

const listingExpirationInterval = interval({
  timeout: 1000,
  start: startListingCountdown,
  stop: stopListingCountdown,
});

sample({
  source: $assetDetails,
  clock: listingExpirationInterval.tick,
  fn: (assetDetails) => assetDetails && calculateLeftTime(assetDetails.expiresAt),
  target: $listingExpiration,
});

sample({
  clock: $assetDetails,
  fn: (assetDetails) => assetDetails && calculateLeftTime(assetDetails.expiresAt),
  target: [$listingExpiration, startListingCountdown],
});

const cancelListing = createEvent<unknown>();
const submitListingCancelation = createEvent<unknown>();
const revokeListingCancelation = createEvent();

const onAssetCancel = sample({
  clock: cancelListingFx.done,
  source: $assetDetails,
  filter: (details, { params }) => details?.id === params,
});

const $cancelAssetListingModalIsOpen = assetDetailsDomain.createStore(false);

$cancelAssetListingModalIsOpen.on(cancelListing, () => true).reset(revokeListingCancelation);

sample({
  clock: submitListingCancelation,
  source: $assetDetails,
  fn: (details) => details!.id,
  target: cancelListingFx,
});

sample({
  clock: onAssetCancel,
  fn: () => false,
  target: $cancelAssetListingModalIsOpen,
});

$assetDetails.reset(onAssetCancel);

$assetDetails.on(
  merge([fetchAssetDetailsFx.done, updateAssetDetailsFx.done]),
  (_, { result }) => result,
);
$assetMetadata.on(fetchAssetsMetadataFx.done, (_, { result }) => result);

//#endregion

//#region Create Listing
const listingGate = createGate<string>();

const listingDomain = assetDetailsDomain.createDomain();

const listAssetFx = createEffect(ListingService.create);

const openListingModal = createEvent();
const closeListingModal = createEvent();
const confirmListing = createEvent<unknown>();
const setListingTerms = createEvent<ListingForm>();

const $listingModalIsOpen = listingDomain.createStore(false);

const setListingStage = createEvent<number>();
const $listingStage = listingDomain.createStore(listingStages.initial);

$listingStage.on(setListingStage, (_, stage) => stage);

const $listingTerms = assetDetailsDomain.createStore<ListingTerms | null>(null);

const $normalizedListingTerms = combine(
  $listingTerms,
  $coins,
  (terms, coins) =>
    terms && {
      amount: weiToEth(terms.desiredOffer.amount),
      amountWithAPR: weiToEth(terms.desiredOffer.amountWithAPR, {
        maxDigitsAfterComma: precisions.repayment,
      }),
      aprPercent: calculateAPR({
        loan: weiToEth(terms.desiredOffer.amount),
        repayment: weiToEth(terms.desiredOffer.amountWithAPR, {
          maxDigitsAfterComma: precisions.repayment,
        }),
        days: secondsToDays(terms.desiredOffer.lendSeconds),
      }),
      coinAddress: terms.desiredOffer.coinAddress,
      lendTerms: `${pluralize(secondsToDays(terms.desiredOffer.lendSeconds), "day", "s")}`,
      coinSymbol: coins.find((coin) => coin.contractAddress === terms.desiredOffer.coinAddress)
        ?.currencySymbol,
    },
);

const $isListingConfirming = listAssetFx.pending;

$listingModalIsOpen.on(openListingModal, () => true);

sample({
  clock: fetchAssetDetailsFx.doneData,
  filter: Boolean,
  fn: (result) => ({
    desiredOffer: result.desiredOffer,
  }),
  target: $listingTerms,
});
sample({
  clock: setListingTerms,
  fn: () => listingStages.confirmation,
  target: $listingStage,
});

sample({
  clock: setListingTerms,
  fn: (values) => ({
    desiredOffer: {
      ...values,
      lendSeconds: daysToSeconds(values.lendDays),
      amount: ethToWei(String(values.amount)),
      amountWithAPR: ethToWei(String(values.amountWithAPR)),
    },
  }),
  target: $listingTerms,
});

sample({
  clock: confirmListing,
  source: {
    listingTerms: $listingTerms,
    chainID: $currentChainId,
    contractAddress: $assetContractAddress,
    assetTokenId: $assetTokenId,
  },
  fn: ({ listingTerms, chainID, assetTokenId, contractAddress }) => ({
    collateral: {
      tokenID: String(assetTokenId),
      chainID: Number(chainID),
      contractAddress: String(contractAddress),
    },
    desiredOffer: {
      ...listingTerms!.desiredOffer,
    },
  }),
  target: listAssetFx,
});

sample({
  clock: listAssetFx.done,
  fn: ({ params }) => ({
    tokenID: params.collateral.tokenID,
    contractAddress: params.collateral.contractAddress,
  }),
  target: updateAssetDetailsFx,
});

sample({
  clock: listAssetFx.done,
  fn: (): ToastOptions => ({
    kind: "success",
    duration: 3000,
    title: "Asset has been listed",
  }),
  target: toast.show,
});

sample({
  clock: updateAssetDetailsFx.doneData,
  filter: $listingModalIsOpen,
  target: closeListingModal,
});

sample({
  clock: updateAssetDetailsFx.done,
  filter: $listingModalIsOpen,
  fn: (): ToastOptions => ({
    title: "Asset has been listed",
    kind: "success",
    duration: 3000,
  }),
  target: toast.show,
});

listingDomain.onCreateStore((store) => store.reset(closeListingModal));
//#endregion

//#endregion

//#region
const fetchListedAssetsFx = createEffect((params: FetchDataListParams) =>
  ListingService.getAll({
    state: "Opened",
    ...params,
  }),
);

const resetListedAssets = createEvent();

const listedAssets = createDataList({
  effect: fetchListedAssetsFx,
  limit: primaryLazyLoadLimit,
});

//#endregion

sample({
  clock: resetAssetDetailsDomain,
  target: [
    resetIsAssetOwner,
    assetOffers.reset,
    resetAssetLoan,
    resetLenderOffersByAsset,
    stopListingCountdown,
  ],
});

sample({
  clock: acceptOfferFx.done,
  fn: (details) => details.params.collateral,
  target: [
    resetAssetDetails,
    checkIsAssetInLoanFx,
    resetAssetLoan,
    assetOffers.reset,
    resetLenderOffersByAsset,
  ],
});

assetDetailsDomain.onCreateStore((store) => store.reset(resetAssetDetailsDomain));

sample({
  clock: $connectionStatus,
  filter: (status) => status !== "connected",
  fn: () => null,
  target: [$assetDetails],
});

export {
  fetchOwnAssetsFx,
  listingGate,
  ownAssetsListGate,
  $ownAssets,
  $assetMetadata,
  fetchAssetsMetadataFx,
  fetchAssetDetailsFx,
  setListingTerms,
  confirmListing,
  $isListingConfirming,
  listedAssets,
  $assetDetails,
  resetListedAssets,
  resetAssetDetailsDomain,
  resetAssetDetails,
  $assetDetailsLoading,
  $listingModalIsOpen,
  openListingModal,
  closeListingModal,
  $listingStage,
  $normalizedListingTerms,
  setListingStage,
  $listingExpiration,
  $cancelAssetListingModalIsOpen,
  cancelListing,
  revokeListingCancelation,
  submitListingCancelation,
  $assetState,
  assetDetailsDomain,
  $isCollectionPermitted,
  $ownAssetsLoading,
  fetchMoreOwnAssetsFx,
  $ownAssetsLoaded,
};
