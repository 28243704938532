import { RefObject, useEffect, useRef } from "react";

type Handler = (event: MouseEvent) => void;

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler,
  mouseEvent: "mousedown" | "mouseup" = "mousedown",
): void {
  const callback = useRef(handler);

  useEffect(() => {
    callback.current = (event: MouseEvent) => {
      const el = ref?.current;

      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };
  }, [handler]);

  useEffect(() => {
    document.addEventListener(mouseEvent, callback.current);

    return () => document.removeEventListener(mouseEvent, callback.current);
  }, [mouseEvent]);
}

export { useOnClickOutside };
