import { useStore } from "effector-react";
import { useEvent } from "effector-react/scope";
import { useMemo } from "react";

import { assetLoans } from "@entities/loans/model";
import { openAccountInfo } from "@entities/account";
import { $normalizedAssetLoans } from "@features/loans/model/asset-loans";
import { NormalizedAssetLoan } from "@features/loans/model/types";
import { Columns, Link, Table, Typography } from "@ui";

import styles from "./styles.module.scss";

export const AssetLoans = () => {
  const data = useStore($normalizedAssetLoans);
  const isPending = useStore(assetLoans.$pending);

  const handlers = useEvent({
    openAccountInfo,
    getMore: assetLoans.getMore,
  });

  const columns = useMemo(() => {
    const data: Columns<NormalizedAssetLoan> = [
      {
        title: "Lender",
        dataIndex: "lender.nickname",
        render: (row) => (
          <Link onClick={() => handlers.openAccountInfo(row.lender.address)}>
            {row.lender.nickname}
          </Link>
        ),
      },
      {
        title: "Borrower",
        dataIndex: "borrower.nickname",
        render: (row) => (
          <Link onClick={() => handlers.openAccountInfo(row.borrower.address)}>
            {row.borrower.nickname}
          </Link>
        ),
      },
      {
        title: "Start",
        dataIndex: "startDate",
      },
      {
        title: "Duration",
        dataIndex: "duration",
      },
      {
        title: "Loan value",
        dataIndex: "loanValue",
      },
      {
        title: "Repayment",
        dataIndex: "repayment",
      },
      {
        title: "APR",
        dataIndex: "apr",
      },
    ];

    return data;
  }, []);
  return (
    <div className={styles.box}>
      <Typography kind="body1" weight="bold">
        Previous loans
      </Typography>
      <Table
        onLoanMore={handlers.getMore}
        loading={isPending}
        defaultRowsCount={3}
        indexKey="id"
        columns={columns}
        data={data}
      />
    </div>
  );
};
