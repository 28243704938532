import cn from "classnames/bind";
import { DetailedHTMLProps, FunctionComponent, HTMLAttributes, PropsWithChildren } from "react";

import styles from "./styles.module.scss";

const cx = cn.bind(styles);

type Props = PropsWithChildren<{}> &
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Default: FunctionComponent<Props> = ({ children, className, ...props }) => (
  <div className={cx("box", className)} {...props}>
    {children}
  </div>
);
