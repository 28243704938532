import { httpClient } from "@api/clients/http-client";
import { weiToEth } from "shared/libs/wei-to-eth";
import { GetLoansParams, Loan } from "./types";

const getAll = (params: GetLoansParams): Promise<Array<Loan>> =>
  httpClient.get("/loan", { params });

const get = async (params: Omit<GetLoansParams, "limit" | "offset">): Promise<Loan | null> => {
  const [loan] = await httpClient.get<never, Array<Loan>>("/loan", {
    params: { ...params, limit: 1, offset: 0 },
  });

  return loan || null;
};

export const LoansService = {
  getAll,
  get,
};
