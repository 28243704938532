import { FunctionComponent, PropsWithChildren } from "react";
import cn from "classnames/bind";

import styles from "./styles.module.scss";

const cx = cn.bind(styles);

type Props = PropsWithChildren<{
  title: JSX.Element;
}>;

export const Card: FunctionComponent<Props> = ({ children, title }) => (
  <section className={cx("card")}>
    <div className={cx("headline")}>{title}</div>
    <div className={cx("body")}>{children}</div>
  </section>
);
