import { AccountInfoModal } from "@entities/account";
import { OwnActiveDebts, OwnActiveLoans, OwnDebtsHistory, OwnLoansHistory } from "@features/loans";
import { MetamaskGuard } from "@features/metamask";
import { PrimaryLayout, SecondaryLayout, Tabs, Typography } from "@ui";
import { useEvent, useStore } from "effector-react";
import { Helmet } from "react-helmet";
import { PrimaryHeader } from "widgets";
import { $activeTab, LoanTabs, setActiveTab } from "./model";

import styles from "./styles.module.scss";

export const Loans = () => {
  const activeTab = useStore($activeTab);

  const handlers = useEvent({ setActiveTab });

  return (
    <PrimaryLayout header={<PrimaryHeader />}>
      <Helmet title={`Dip Ocean - Loans`} />

      <MetamaskGuard>
        <SecondaryLayout title="Loans">
          <AccountInfoModal />
          <Tabs value={activeTab} onChange={handlers.setActiveTab}>
            <Tabs.Item title="Borrowed" value={LoanTabs.borrowed}>
              <div className={styles.tablesLayout}>
                <section className={styles.tableBox}>
                  <Typography className={styles.title} weight="bold" kind="body1">
                    Current loans
                  </Typography>
                  <OwnActiveLoans />
                </section>
                <section className={styles.tableBox}>
                  <Typography className={styles.title} weight="bold" kind="body1">
                    Previous loans
                  </Typography>
                  <OwnLoansHistory />
                </section>
              </div>
            </Tabs.Item>

            <Tabs.Item title="Lend" value={LoanTabs.lend}>
              <div className={styles.tablesLayout}>
                <section className={styles.tableBox}>
                  <Typography className={styles.title} weight="bold" kind="body1">
                    Current loans
                  </Typography>
                  <OwnActiveDebts />
                </section>
                <section className={styles.tableBox}>
                  <Typography className={styles.title} weight="bold" kind="body1">
                    Previous loans
                  </Typography>
                  <OwnDebtsHistory />
                </section>
              </div>
            </Tabs.Item>
          </Tabs>
        </SecondaryLayout>
      </MetamaskGuard>
    </PrimaryLayout>
  );
};
