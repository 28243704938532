import { PrimaryLayout, SecondaryLayout } from "@ui";
import { Helmet } from "react-helmet";
import { PrimaryHeader } from "widgets";
import { OwnAssets } from "../../../features/assets";
import { MetamaskGuard } from "../../../features/metamask";

export const BorrowAssets = () => {
  return (
    <PrimaryLayout header={<PrimaryHeader />}>
      <Helmet title={`Dip Ocean - Assets`} />

      <MetamaskGuard>
        <SecondaryLayout title="Assets">
          <OwnAssets />
        </SecondaryLayout>
      </MetamaskGuard>
    </PrimaryLayout>
  );
};
