import { httpClient } from "../clients/http-client";

import { CreateListingPayload, GetListingParams, ListedAsset } from "./types";

const create = (payload: CreateListingPayload) => httpClient.post("/listing", payload);

const cancel = (id: ListedAsset["id"]) => httpClient.delete(`/listing/${id}`);

const get = async (params?: Omit<GetListingParams, "limit" | "offset">) =>
  httpClient
    .get<never, Array<ListedAsset | undefined>>("/listing", {
      params: { ...params, limit: 1, offset: 0 },
    })
    .then(([data]) => data || null);

const getAll = (params?: GetListingParams): Promise<Array<ListedAsset>> =>
  httpClient.get("/listing", {
    params,
    headers: {
      "X-Chain-ID": "all",
    },
  });

const getOffers = () => {};

export const ListingService = {
  create,
  cancel,
  getAll,
  getOffers,
  get,
};
