import { Icon, Typography } from "@ui";
import { useEvent } from "effector-react/scope";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { hide } from "../../events";

import { Toast } from "../../types";

import styles from "./styles.module.scss";

type Props = Toast;

export const Toaster: FunctionComponent<Props> = ({ id, kind, title, description, duration }) => {
  const [show, setShow] = useState(false);
  const timeoutId = useRef<unknown>(null);

  const handlers = useEvent({
    hide,
  });

  useEffect(() => {
    setShow(true);

    if (duration) {
      timeoutId.current = setTimeout(() => {
        handlers.hide(id);
      }, duration);
    }

    return () => {
      if (typeof timeoutId.current === "number") {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  const handleClose = () => handlers.hide(id);

  return (
    <CSSTransition
      in={show}
      timeout={300}
      classNames={{ enter: "xuy", enterActive: styles.enterActive, enterDone: styles.enterDone }}
    >
      <div className={styles.toast} data-kind={kind}>
        <div className={styles.header}>
          <Typography className={styles.title} kind="body1" weight="bold">
            {title}
          </Typography>
          {duration && (
            <span className={styles.closeIcon} onClick={handleClose}>
              <Icon.Cancel />
            </span>
          )}
        </div>
        {description && <div className={styles.description}>{description}</div>}
      </div>
    </CSSTransition>
  );
};
