import { useStore } from "effector-react";
import { useEvent } from "effector-react/scope";
import {
  $cancelOfferModalIsOpen,
  cancelOffer,
  openCancelOfferModal,
  closeCancelOfferModal,
  $deletingOffersMap,
  cancelOfferFx,
  deleteOfferFxState,
} from "@entities/offers/model/cancelOffer";
import { Button, Message, Modal, ModalFooter, Typography } from "@ui";

import styles from "./styles.module.scss";
import { FunctionComponent } from "react";
import { TxState } from "shared/libs/create-tx-effect";

type Props = {
  offerId: string;
  nonce: string;
};

export const RevokeOffer: FunctionComponent<Props> = ({ offerId, nonce }) => {
  const deletingOffersMap = useStore($deletingOffersMap);
  const isOpen = useStore($cancelOfferModalIsOpen);
  const cancelOfferFxState = useStore(cancelOfferFx.$state);
  const error = useStore(deleteOfferFxState.$error);

  const isDeleting = deletingOffersMap[offerId];

  const handlers = useEvent({
    cancelOffer,
    openCancelOfferModal,
    closeCancelOfferModal,
  });

  return (
    <>
      <Modal
        className={styles.modal}
        title="Revoke offer"
        description="Are you sure you want to delete the offer?"
        isOpen={isOpen}
        renderFooter={
          <ModalFooter>
            <Button
              loading={isDeleting}
              onClick={() => handlers.cancelOffer({ id: offerId, nonce })}
            >
              Revoke
            </Button>
            <Button
              disabled={isDeleting}
              onClick={() => handlers.closeCancelOfferModal()}
              kind="ghost"
            >
              No
            </Button>
          </ModalFooter>
        }
        onClose={handlers.closeCancelOfferModal}
      >
        <div className={styles.layout}>
          <div>
            <Typography className={styles.termItem} kind="body1" weight="light">
              Your nft will be hidden from the issuance of the platform.
            </Typography>
            <Typography className={styles.termItem} kind="body1" weight="light">
              Offers can{"'"}t be submitted to your nft.
            </Typography>
            <Typography className={styles.termItem} kind="body1" weight="light">
              All existing offers will remain active until expiration or cancellation by the lender.
            </Typography>
          </div>

          {cancelOfferFxState === TxState.pending && (
            <Message.Pending
              title="Waiting for signature"
              description="Please approve the transaction in your wallet"
            />
          )}

          {cancelOfferFxState === TxState.confirmation && (
            <Message.Pending title="Executing: Revoke offer" />
          )}

          {error && <Message.Error title="Error executing transaction" description={error} />}
        </div>
      </Modal>
      <Button onClick={() => handlers.openCancelOfferModal()}>Revoke offer</Button>
    </>
  );
};
