import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FunctionComponent,
  PropsWithChildren,
} from "react";
import cn from "classnames/bind";

import styles from "./styles.module.scss";

const cx = cn.bind(styles);

export type ButtonProps = PropsWithChildren<{
  size?: "extra-small" | "small" | "medium" | "large";
  kind?: "primary" | "secondary" | "danger" | "ghost" | "simple";
  shape?: "rounded" | "square";
  appendBefore?: JSX.Element;
  appendAfter?: JSX.Element;
  loading?: boolean;
  wide?: boolean;
}> &
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const Button: FunctionComponent<ButtonProps> = ({
  children,
  size = "medium",
  kind = "primary",
  shape = "square",
  loading = false,
  appendBefore,
  appendAfter,
  className,
  wide,
  ...rest
}) => (
  <button
    className={cx("button", className)}
    data-size={size}
    data-kind={kind}
    data-shape={shape}
    data-loading={loading}
    data-wide={wide}
    {...rest}
  >
    <span className={styles.content}>
      {appendBefore && <span className={styles.appendBefore}>{appendBefore}</span>}
      {children}
      {appendAfter && <span className={styles.appendAfter}>{appendAfter}</span>}
    </span>
  </button>
);
