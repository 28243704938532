import { Button, ButtonProps, Icon } from "@ui";
import { useStore } from "effector-react";
import { useEvent } from "effector-react/scope";
import { FunctionComponent, PropsWithChildren } from "react";
import { $connectionStatus, connectFx } from "shared/libs/effector-metamask";
import { connectionStatuses } from "shared/libs/effector-metamask/constants";

type Props = PropsWithChildren<{}> & Pick<ButtonProps, "size" | "appendBefore">;

export const ProtectedAction: FunctionComponent<Props> = ({
  children,
  size = "extra-small",
  ...props
}) => {
  const status = useStore($connectionStatus);

  const handlers = useEvent({
    connect: connectFx,
  });

  if (status === connectionStatuses.connected) return <>{children}</>;
  if (status === connectionStatuses.idle) return null;

  return (
    <Button
      loading={status === connectionStatuses.pending}
      size={size}
      onClick={() => handlers.connect()}
      {...props}
    >
      Connect wallet
    </Button>
  );
};
