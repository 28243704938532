import classnames from "classnames/bind";
import React, {
  ChangeEvent,
  cloneElement,
  isValidElement,
  PropsWithChildren,
  ReactElement,
} from "react";

import { Typography } from "../../../../elements";
import { RadioOptionValue } from "../../types";
import { RadioItem, RadioItemProps } from "../Item";

import styles from "./styles.module.scss";

const cx = classnames.bind(styles);

type RadioGroupProps = PropsWithChildren<{
  label?: string;
  className?: string;
  name?: string;
  onChange?: (value: any) => void;
  value?: RadioOptionValue;
  direction?: "row" | "column";
  disabled?: boolean;
}>;

const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  value = null,
  label,
  className,
  children,
  direction = "row",
  disabled,
  onChange = () => {},
}) => {
  return (
    <div className={cx("group", className)}>
      {label && <span className={cx("labelText")}>{label}</span>}
      <ul className={cx("items")} data-direction={direction}>
        {React.Children.map(children, (child) => {
          if (!isValidElement(child) || child.type !== RadioItem) return child;

          const radioChild = child as ReactElement<PropsWithChildren<RadioItemProps>>;
          const childProps = radioChild.props as RadioItemProps;

          return (
            <li>
              {cloneElement(radioChild, {
                disabled: childProps.disabled ?? disabled,
                _name: name,
                _onChange: () => onChange(childProps.value),
                _checked: childProps.value === value,
              })}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export { RadioGroup };
export type { RadioGroupProps };
