import { Loan } from "@api/loansService/types";
import { Button, Message, Modal, ModalBody, ModalFooter, Typography } from "@ui";
import { useEvent, useStore } from "effector-react/scope";
import {
  $liquidateLoanModalIsOpen,
  liquidateLoan,
  cancelLoanLiquidation,
  confirmLoanLiquidation,
  liquidateLoanState,
} from "@features/loans/model";
import { FunctionComponent } from "react";
import { $assetLoanExpired, liquidateLoanFx } from "@entities/loans/model";
import { TxState } from "shared/libs/create-tx-effect";

import styles from "./styles.module.scss";

type Props = {
  loanId: Loan["loanID"];
};

export const LiquidateLoan: FunctionComponent<Props> = ({ loanId }) => {
  const txState = useStore(liquidateLoanFx.$state);
  const liquidateLoanFxError = useStore(liquidateLoanState.$error);
  const isAssetLoanExpired = useStore($assetLoanExpired);
  const modalIsOpen = useStore($liquidateLoanModalIsOpen);

  const handlers = useEvent({
    liquidateLoan,
    cancelLoanLiquidation,
    confirmLoanLiquidation,
  });

  return (
    <>
      <Modal
        onClose={handlers.cancelLoanLiquidation}
        className={styles.modal}
        title="Foreclose"
        description="Please sign the transaction"
        renderFooter={null}
        isOpen={modalIsOpen}
        customBody
      >
        <ModalBody>
          <div className={styles.layout}>
            <Message>
              <Typography kind="body1">
                Please click sign to show the metamask popup and confirm the transaction and begin
                the loan
              </Typography>
            </Message>

            {txState === TxState.pending && (
              <Message.Pending
                title="Waiting for signature..."
                description="Please approve the transaction in your wallet"
              />
            )}

            {txState === TxState.confirmation && (
              <Message.Pending
                title="Waiting for tx confirmation..."
                description="Please wait for the transaction confirmation"
              />
            )}

            {liquidateLoanFxError && (
              <Message.Error
                title="Error executing transaction"
                description={liquidateLoanFxError}
              />
            )}
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            disabled={txState !== TxState.idle}
            kind="ghost"
            onClick={() => handlers.cancelLoanLiquidation()}
          >
            Back
          </Button>
          <Button
            disabled={txState !== TxState.idle}
            onClick={() => handlers.confirmLoanLiquidation()}
          >
            Sign
          </Button>
        </ModalFooter>
      </Modal>
      {isAssetLoanExpired && (
        <Button onClick={() => handlers.liquidateLoan(loanId)}>Foreclose loan</Button>
      )}
    </>
  );
};
