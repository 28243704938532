import { runtimeEnv } from "@configs/runTimeEnv";
import { Icon, Modal, Skeleton, Typography } from "@ui";
import { useEvent, useStore } from "effector-react";
import { FunctionComponent } from "react";
import {
  closeAccountInfo,
  $accountInfoModalIsOpen,
  $normalizedAccountInfo,
  $selectedAccountAddress,
  fetchAccountInfoState,
} from "../../model";
import { AccountStatCard } from "../AccountStatCard";

import styles from "./styles.module.scss";

type Props = {
  title?: string;
};

export const AccountInfoModal: FunctionComponent<Props> = ({ title = "Account information" }) => {
  const isOpen = useStore($accountInfoModalIsOpen);
  const accountInfo = useStore($normalizedAccountInfo);
  const accountAddress = useStore($selectedAccountAddress);
  const accountFetchingStatus = useStore(fetchAccountInfoState.$status);

  const loading = accountFetchingStatus === "pending";

  const handlers = useEvent({
    closeAccountInfo,
  });

  return (
    <Modal renderFooter={null} isOpen={isOpen} title={title} onClose={handlers.closeAccountInfo}>
      <div className={styles.layout}>
        <article className={styles.card}>
          <div className={styles.cardHeadline}>
            <span className={styles.iconBox}>
              <Icon.Etherscan />
            </span>
            <Typography kind="body1" weight="medium">
              Etherscan:
            </Typography>
          </div>

          <a
            className={styles.link}
            target="_blank"
            rel="noreferrer nofollow"
            href={`${runtimeEnv.etherscanBaseUrl}/address/${accountAddress}`}
          >
            {accountAddress}
          </a>
        </article>

        <article className={styles.card}>
          <div className={styles.cardHeadline}>
            <span className={styles.iconBox}>
              <Icon.Opensea />
            </span>
            <Typography kind="body1" weight="medium">
              OpenSea:
            </Typography>
          </div>

          <a
            className={styles.link}
            target="_blank"
            rel="noreferrer nofollow"
            href={`https://opensea.io/assets?search[query]=${accountAddress}`}
          >
            {accountAddress}
          </a>
        </article>

        <AccountStatCard
          loading={loading}
          title="Total borrowed"
          values={accountInfo?.totalBorrowed}
        />

        <AccountStatCard loading={loading} title="Total lent" values={accountInfo?.totalLent} />

        <AccountStatCard loading={loading} title="Avg borrowed" values={accountInfo?.avgBorrowed} />
        <AccountStatCard loading={loading} title="Avg loan size" values={accountInfo?.avgLent} />

        <AccountStatCard
          title="Loans given"
          loading={loading}
          values={[
            {
              title: "Loans given",
              value: accountInfo?.timesLoansGiven,
            },
            {
              title: "Borrowed",
              value: accountInfo?.timesBorrowed,
            },
          ]}
        />

        <AccountStatCard
          loading={loading}
          title="Default rate"
          values={[
            {
              title: "Repaid",
              value: accountInfo?.timesRepaid,
            },
            {
              title: "Defaulted",
              value: accountInfo?.timesDefaulted,
            },
          ]}
        />
      </div>
    </Modal>
  );
};
