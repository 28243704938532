export const Dai = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.16667 6.63333C3.16667 5.42239 3.16667 3.60614 3.16667 2.02C3.16667 1.55887 3.48159 1.16021 3.93865 1.09908C6.06205 0.815082 11.8333 0.676968 11.8333 7.56719C11.8333 13.813 6.18995 14.1024 4.01098 13.9813C3.52132 13.9541 3.16667 13.5396 3.16667 13.0492V9.23333M3.16667 6.63333C3.16667 7.23485 3.16667 6.83025 3.16667 7.56723V9.23333M3.16667 6.63333H1M3.16667 6.63333H14M3.16667 9.23333H1M3.16667 9.23333H14"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
