import { runtimeEnv } from "@configs/runTimeEnv";
import { createStore, sample, scopeBind } from "effector";
import { Provider, useStore } from "effector-react/scope";
import { createRoot } from "react-dom/client";
import { v4 as uuidv4 } from "uuid";

import { getClientScope } from "../get-client-scope";

import { Wrapper, Toaster } from "./views";
import { show, hide } from "./events";
import { Toast } from "./types";

const $activeToasts = createStore<Toast[]>([]);

if (runtimeEnv.isClient) {
  const rootNode = document.createElement("div");

  rootNode.setAttribute("data-name", "toasts");
  document.body.appendChild(rootNode);

  const View = () => {
    const activeToasts = useStore($activeToasts);

    return (
      <Wrapper>
        {activeToasts.map((toast) => (
          <Toaster key={toast.id} {...toast} />
        ))}
      </Wrapper>
    );
  };

  const root = createRoot(rootNode);
  const scope = getClientScope();

  root.render(
    <Provider value={scope}>
      <View />
    </Provider>,
  );

  sample({
    clock: show,
    source: $activeToasts,
    filter: (activeToasts, payload) => {
      if (payload.id === undefined) return true;

      return activeToasts.every((toast) => payload.id !== toast.id);
    },
    fn: (activeToasts, newToast) => [{ ...newToast, id: newToast.id || uuidv4() }, ...activeToasts],
    target: $activeToasts,
  });

  sample({
    clock: hide,
    source: $activeToasts,
    fn: (activeToasts, toastId) => activeToasts.filter((toast) => toast.id !== toastId),
    target: $activeToasts,
  });

  sample({
    clock: hide,
    source: $activeToasts,
    fn: (activeToasts, hiddenToastId) => activeToasts.find((toast) => toast.id === hiddenToastId),
  }).watch((toast) => {
    if (toast?.onClose) {
      scopeBind(toast?.onClose, { scope });
    }
  });
}

export const toast = {
  show,
  hide,
};
