import { createEvent, createStore } from "effector";

enum LoanTabs {
  borrowed,
  lend,
}

const setActiveTab = createEvent<LoanTabs>();
const $activeTab = createStore(LoanTabs.borrowed);

$activeTab.on(setActiveTab, (_, tab) => tab);

export { $activeTab, setActiveTab, LoanTabs };
