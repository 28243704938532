import { runtimeEnv } from "@configs/runTimeEnv";
import { attach, createStore, sample } from "effector";
import { $signer } from "shared/libs/effector-ethers";
import { DirectLoanFixedOffer__factory } from "shared/smart-contracts/factories/DirectLoanFixedOffer__factory";

const $directLoanFixedOfferContract = $signer.map(
  (signer) =>
    signer && DirectLoanFixedOffer__factory.connect(runtimeEnv.directLoanFixedOfferAddress, signer),
);

const getServiceFeeFx = attach({
  source: $directLoanFixedOfferContract,
  effect: (contract) => contract?.adminFeeInBasisPoints(),
});

sample({
  clock: $directLoanFixedOfferContract,
  filter: Boolean,
  target: getServiceFeeFx,
});

const $serviceFee = createStore<number | null>(null);

$serviceFee.on(getServiceFeeFx.doneData, (_, data) => data);

export { getServiceFeeFx, $serviceFee, $directLoanFixedOfferContract };
