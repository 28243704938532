import { EntitiesService } from "@api/entitiesService";
import { createEffect, createStore, sample } from "effector";
import { setRequiredChains } from "shared/libs/effector-metamask";
import { Chain } from "shared/libs/effector-metamask/types";

const fetchNetworksFx = createEffect(EntitiesService.fetchNetworks);

sample({
  clock: fetchNetworksFx.doneData,
  fn: (data) =>
    data.map(
      (chain): Chain => ({
        id: chain.networkID,
        chainName: chain.name,
        rpcUrls: [chain.url],
      }),
    ),
  target: setRequiredChains,
});

export { fetchNetworksFx };
