import { $normalizedNotifications, notifications } from "@entities/notifications/model";
import { NotificationCard } from "@entities/notifications/ui/NotificationCard";
import { PrimaryLayout, SecondaryLayout } from "@ui";
import { useStore } from "effector-react";
import { useEvent } from "effector-react/scope";
import { Helmet } from "react-helmet";
import { IntersectionObserver } from "shared/libs/intersection-observer";
import { PrimaryHeader } from "widgets";

import styles from "./styles.module.scss";

const mock = Array.from(
  {
    length: 5,
  },
  (_, i) => i,
);

export const Notifications = () => {
  const data = useStore($normalizedNotifications);
  const loading = useStore(notifications.$pending);

  const handlers = useEvent({
    getMore: notifications.getMore,
  });

  const loadingView =
    loading &&
    mock.map((i) => (
      <NotificationCard
        image={null}
        loading
        key={i}
        description={""}
        title=""
        creationTime=""
        isViewed
      />
    ));

  return (
    <PrimaryLayout header={<PrimaryHeader />}>
      <Helmet title={`Dip Ocean - Notifications`} />

      <SecondaryLayout title="Notifications">
        <IntersectionObserver disabled={loading} onIntersect={handlers.getMore}>
          {(ref) => (
            <section className={styles.listLayout}>
              {data.map((notification) => (
                <NotificationCard
                  image={notification.image}
                  key={notification.id}
                  description={notification.description}
                  title={notification.title}
                  creationTime={notification.date}
                  isViewed={notification.seen}
                />
              ))}

              {loadingView}

              <span ref={ref} />
            </section>
          )}
        </IntersectionObserver>
      </SecondaryLayout>
    </PrimaryLayout>
  );
};
