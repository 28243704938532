import { runtimeEnv } from "@configs/runTimeEnv";
import { $assetDetailsLoading, $assetMetadata } from "@features/assets/model";
import { Button, Icon, Skeleton, Tag, Typography } from "@ui";
import { useStore } from "effector-react";

import styles from "./styles.module.scss";

export const AssetInfo = () => {
  const assetMetadata = useStore($assetMetadata);
  const pending = useStore($assetDetailsLoading);

  return (
    <section className={styles.layout}>
      <div className={styles.headline}>
        <Skeleton active={pending}>
          <Typography className={styles.title} kind="title3">
            {assetMetadata?.name || "Asset name"}
          </Typography>
        </Skeleton>

        <div className={styles.headerButtons}>
          <a
            target="_blank"
            href={`${runtimeEnv.etherscanBaseUrl}/address/${assetMetadata?.tokenID}`}
            rel="noreferrer nofollow"
          >
            <Button
              size="extra-small"
              kind="simple"
              className={styles.linkTag}
              appendBefore={<Icon.Etherscan />}
            >
              Etherscan
            </Button>
          </a>
          <a
            target="_blank"
            rel="noreferrer nofollow"
            href={`https://opensea.io/assets?search[query]=${assetMetadata?.contractAddress}`}
          >
            <Button
              size="extra-small"
              kind="simple"
              className={styles.linkTag}
              appendBefore={<Icon.Opensea />}
            >
              Opensea
            </Button>
          </a>
        </div>
      </div>
      <div>
        <Skeleton active={pending}>
          <Typography as="span" className={styles.collectionName} kind="body2">
            {assetMetadata?.collectionName}
          </Typography>
        </Skeleton>
      </div>
    </section>
  );
};
