import { httpClient } from "@api/clients/http-client";
import { Coin, Network } from "./types";

const fetchCoins = (): Promise<Coin[]> => httpClient.get("/network/coins/all");

const fetchNetworks = (): Promise<Network[]> => httpClient.get("/network/all");

export const EntitiesService = {
  fetchCoins,
  fetchNetworks,
};
