import { routesMap } from "@configs/routing";
import { fetchAccountInfoFx, profileListings } from "@entities/account";
import { sample } from "effector";
import { $defaultAccount } from "shared/libs/effector-metamask";

sample({
  source: {
    account: $defaultAccount,
    isOpened: routesMap.profile.$isOpened,
  },
  clock: [routesMap.profile.opened, $defaultAccount],
  filter: ({ isOpened, account }) => Boolean(isOpened && account),
  fn: ({ account }) => account,
  target: [fetchAccountInfoFx, profileListings.get],
});
