import { AxiosInstance, AxiosResponse } from "axios";
import { runtimeEnv } from "@configs/runTimeEnv";
import { scopeBind } from "effector";
import { signMessageFx } from "../effector-ethers";
import { getCurrentChain, getDefaultAccount } from "../effector-metamask";
import { getClientScope } from "../get-client-scope";

type Params = {
  nonceUrl: string;
  authUrl: string;
  httpClient: AxiosInstance;
};

type State = {
  currentPromise: null | Promise<AxiosResponse>;
};

const state: State = {
  currentPromise: null,
};

export const authWithMetamask = async ({ nonceUrl, authUrl, httpClient }: Params) => {
  if (!runtimeEnv.isClient) return Promise.reject();
  // If another request has called authWithMetamask, we don't need to repeat the request
  if (state.currentPromise) return state.currentPromise;

  const accountAddress = getDefaultAccount();
  const chainID = getCurrentChain();

  const getNonce = (): Promise<{ nonce: string }> =>
    httpClient.get(nonceUrl, {
      params: {
        accountAddress,
        chainID,
      },
    });

  try {
    const clientScope = getClientScope();

    if (typeof window === "undefined" || !accountAddress || !chainID) return;

    const { nonce } = await getNonce();

    const signMessage = `Welcome to the club, buddy\n\n${accountAddress}\n${chainID}\n${nonce}\n\nThis message proves that you are owner of this address ${accountAddress}.`;

    const signature = await scopeBind(signMessageFx, { scope: clientScope })(signMessage);

    state.currentPromise = httpClient.post(authUrl, {
      message: signMessage,
      signature,
      nonce,
      chainID,
      accountAddress,
    });

    await state.currentPromise;
  } finally {
    state.currentPromise = null;
  }
};
