import { Loan } from "@api/loansService/types";
import { $assetTokenId, checkIsAssetInLoanFx, checkIsAssetOwnerFx } from "@entities/assets";
import { liquidateLoanFx, resetAssetLoan } from "@entities/loans/model";
import { resetAssetDetails } from "@features/assets";
import { createDomain, createEvent, sample } from "effector";
import { createEffectState } from "shared/libs/create-effect-state";
import { TxState } from "shared/libs/create-tx-effect";
import { toast } from "shared/libs/toast";
import { ToastOptions } from "shared/libs/toast/types";

//#region Liquidate Loan
const liquidateLoanDomain = createDomain();
const resetLoanLiquidationDomain = createEvent();

const liquidateLoan = createEvent<Loan["loanID"]>();
const confirmLoanLiquidation = createEvent();
const cancelLoanLiquidation = createEvent();

const $liquidatingLoanId = liquidateLoanDomain.createStore<null | Loan["loanID"]>(null);
const $liquidateLoanModalIsOpen = $liquidatingLoanId.map((v) => v !== null);

sample({
  clock: liquidateLoan,
  target: $liquidatingLoanId,
});

sample({
  clock: confirmLoanLiquidation,
  source: $liquidatingLoanId,
  filter: Boolean,
  target: liquidateLoanFx,
});

sample({
  clock: liquidateLoanFx.done,
  source: $assetTokenId,
  fn: (tokenID) => ({
    tokenID,
  }),
  target: [
    checkIsAssetOwnerFx,
    checkIsAssetInLoanFx,
    resetAssetLoan,
    resetAssetDetails,
    resetLoanLiquidationDomain,
  ],
});

sample({
  clock: liquidateLoanFx.done,
  source: $assetTokenId,
  fn: (): ToastOptions => ({
    title: "Loan has been liquidated",
    duration: 3000,
    kind: "success",
  }),
  target: toast.show,
});

sample({
  clock: liquidateLoanFx.failData,
  source: $assetTokenId,
  fn: (_, error): ToastOptions => ({
    title: "Loan liquidation failed",
    description: error.message,
    duration: 3000,
    kind: "error",
  }),
  target: toast.show,
});

sample({
  clock: cancelLoanLiquidation,
  source: liquidateLoanFx.$state,
  filter: (state) => state === TxState.idle,
  target: resetLoanLiquidationDomain,
});

liquidateLoanDomain.onCreateStore((store) => store.reset(resetLoanLiquidationDomain));

const liquidateLoanState = createEffectState(liquidateLoanFx, { domain: liquidateLoanDomain });

//#endregion

export {
  liquidateLoan,
  $liquidateLoanModalIsOpen,
  cancelLoanLiquidation,
  confirmLoanLiquidation,
  liquidateLoanState,
};
