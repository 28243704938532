import { $isAssetInLoan, $isAssetOwner } from "@entities/assets";
import {
  $assetLoan,
  $isAssetLoanLender,
  $assetLoanExpiration,
  $normalizedAssetLoan,
  $assetLoanExpired,
} from "@entities/loans/model";
import { $assetDetails } from "@features/assets";
import { RepayLoan } from "@features/loans";
import { $loanInProcessing } from "@features/loans/model/asset-loans";
import { Card, Icon, Info, Message, Spinner, Tag, Typography } from "@ui";
import { useStore } from "effector-react";
import { useMemo } from "react";
import { secondsToDays } from "shared/libs/format-days";
import { pluralize } from "shared/libs/pluralize";
import { LiquidateLoan } from "../LiquidateLoan";

import styles from "./styles.module.scss";

export const AssetLoan = () => {
  const loan = useStore($normalizedAssetLoan);
  const isAssetLoanLender = useStore($isAssetLoanLender);
  const isAssetInLoan = useStore($isAssetInLoan);

  const loanExpiration = useStore($assetLoanExpiration);
  const loanExpired = useStore($assetLoanExpired);
  const loanInProcessing = useStore($loanInProcessing);

  const expirationStatus = useMemo(() => {
    if (loanExpired) return "Expired";
    if (loanExpiration) return `Expires in ${loanExpiration}`;

    return null;
  }, [loanExpiration, loanExpired]);

  const processingView = loanInProcessing && (
    <div className={styles.processingLayout}>
      <div className={styles.spinnerBox}>
        <Spinner className={styles.spinner} />
      </div>
      <div className={styles.processingContent}>
        <Typography kind="body1">Processing loan</Typography>
        <Typography className={styles.processingDescription} kind="body2">
          It may take 2 minutes
        </Typography>
      </div>
    </div>
  );

  const defaultView = !loanInProcessing &&
    (loan?.state === "Active" || loan?.state === "Expired") && (
      <>
        <div className={styles.items}>
          <div className={styles.item}>
            <Typography className={styles.itemTitle} kind="body2">
              Repayment
            </Typography>
            <Typography weight="bold" kind="body1">
              {loan.amountWithAPR} {loan.coin.currencySymbol}
            </Typography>
          </div>

          <div className={styles.item}>
            <Typography className={styles.itemTitle} kind="body2">
              APR
            </Typography>
            <Typography weight="bold" kind="body1">
              {loan.apr}%
            </Typography>
          </div>

          <div className={styles.item}>
            <Typography className={styles.itemTitle} kind="body2">
              Payment term
            </Typography>
            <Typography weight="bold" kind="body1">
              {loan.creationDate} - {loan.expirationDate}
            </Typography>
          </div>

          <div className={styles.item}>
            <Typography className={styles.itemTitle} kind="body2">
              Principal amount
            </Typography>
            <Typography weight="bold" kind="body1">
              {loan.amount} {loan.coin.currencySymbol}
            </Typography>
          </div>
        </div>

        {!isAssetLoanLender && loanExpired && (
          <Tag kind="danger" appendBefore={<Icon.ExclamationTriangle />}>
            Payment has not been made in time and collateral will be transferred to the lender
          </Tag>
        )}
        {!isAssetLoanLender && loan.state === "Active" && <RepayLoan loanId={loan.id} />}
        {isAssetLoanLender && loanExpired && <LiquidateLoan loanId={loan.id} />}
      </>
    );

  if (!isAssetInLoan && !loanInProcessing) return null;
  if (!defaultView && !processingView) return null;

  return (
    <Card
      title={
        <>
          <Typography kind="body1">Active loan</Typography>
          {!loanInProcessing && expirationStatus && loan && (
            <div className={styles.headerRight}>
              <Tag kind="danger">{expirationStatus}</Tag>
              <Info>
                {!loanExpired && (
                  <>
                    {loan?.collateral.name} is currently held in escrow in a Dip Ocean contract and
                    will be released back to its borrowed if a repayment amount of
                    {loan.amountWithAPR} {loan?.coin.currencySymbol} is made before{" "}
                    {loan?.expirationDate}
                    <span className={styles.helperBottom}>
                      If repayment is not made by{" "}
                      {pluralize(secondsToDays(loan.secondsLeft), "day", "s")}, you will be able to
                      claim this asset
                    </span>
                  </>
                )}

                {loanExpired && (
                  <>Payment has not been made in time and will be transferred to the lender.</>
                )}
              </Info>
            </div>
          )}
        </>
      }
    >
      {processingView}
      {defaultView}
    </Card>
  );
};
