import { PropsWithChildren, FunctionComponent, useMemo } from "react";
import { useEvent, useStore } from "effector-react/scope";

import { Button, Icon, Typography } from "@ui";

import styles from "./styles.module.scss";
import "../../model";

import {
  $connectionStatus,
  connectFx,
  initMetamask,
} from "../../../../shared/libs/effector-metamask";
import { connectionStatuses } from "../../../../shared/libs/effector-metamask/constants";

type Props = PropsWithChildren<{}>;

export const MetamaskGuard: FunctionComponent<Props> = ({ children }) => {
  const connectionStatus = useStore($connectionStatus);

  const handlers = useEvent({
    connect: connectFx,
    init: initMetamask,
  });

  const handleConnect = () => {
    handlers.connect();
  };

  const noMetamaskView = connectionStatus === connectionStatuses.notDetected && (
    <div className={styles.layout}>
      <Typography className={styles.title} kind="title3">
        Not metamask detected
      </Typography>
      <Typography className={styles.description} kind="body1">
        This is really embarrassing.
      </Typography>
      <Typography className={styles.description} kind="body1">
        MetaMask Service is not responding.
      </Typography>
    </div>
  );

  const detectedView = useMemo(() => {
    if (
      connectionStatus !== connectionStatuses.detected &&
      connectionStatus !== connectionStatuses.pending
    )
      return null;

    return (
      <div className={styles.layout}>
        <svg
          width="242"
          height="240"
          viewBox="0 0 242 240"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="121.5" cy="120" r="120" fill="#EBF1FF" />
          <path
            d="M114.8 148.074L107.2 63.6475C106.8 59.276 107.8 55.1093 110.2 51.1475C112.733 47.0492 116.533 45 121.6 45C127.067 45 130.867 47.0492 133 51.1475C135.267 55.1093 136.2 59.276 135.8 63.6475L128.4 148.074H114.8ZM121.4 195C117.533 195 114.133 193.429 111.2 190.287C108.4 187.145 107 183.388 107 179.016C107 174.645 108.467 170.888 111.4 167.746C114.333 164.604 117.733 163.033 121.6 163.033C125.733 163.033 129.133 164.604 131.8 167.746C134.6 170.888 136 174.645 136 179.016C136 183.388 134.533 187.145 131.6 190.287C128.667 193.429 125.267 195 121.4 195Z"
            fill="#9ABBFF"
          />
        </svg>

        <Typography className={styles.title} kind="title3">
          Not detected
        </Typography>
        <Typography className={styles.description} kind="body1">
          This is really embarrassing. MetaMask Service is not responding.
        </Typography>
        <Button loading={connectionStatus === connectionStatuses.pending} onClick={handleConnect}>
          Connect wallet
        </Button>
      </div>
    );
  }, [connectionStatus]);

  const defaultView =
    (connectionStatus === connectionStatuses.connected ||
      connectionStatus === connectionStatuses.wrongChain) &&
    children;

  return (
    <>
      {noMetamaskView}
      {detectedView}
      {defaultView}
    </>
  );
};
