import { createEffect, createEvent, createStore, Effect, sample } from "effector";
import { ContractTransaction } from "ethers";
import { Logger } from "ethers/lib/utils";
import { isEthersError } from "../is-ethers-error";

type Options = {
  confirmationsCount?: number;
};

export enum TxState {
  idle = "idle",
  pending = "pending",
  confirmation = "confirmation",
}

type AsyncHandler<P, R> = (params: P) => Promise<R>;

export const onAnyTxDone = createEvent();

export const createTxEffect = <P>(
  fx: Effect<P, ContractTransaction> | AsyncHandler<P, ContractTransaction>,
  params?: Options,
) => {
  const $state = createStore<TxState>(TxState.idle);
  const originFx = createEffect(fx);

  const wrappedFx = createEffect(async (payload: P) => {
    const tx = await originFx(payload);

    if (params?.confirmationsCount) {
      try {
        await tx.wait(params.confirmationsCount);
      } catch (e) {
        if (!isEthersError(e)) throw e;
        if (e.code === Logger.errors.TRANSACTION_REPLACED) {
          // If transaction wasn't speeded up and just cancelled, throw error and reject the Promise
          if (e.reason === "cancelled") throw Error("Transaction was canceled");
        }
      }
    }
  });

  sample({
    clock: wrappedFx,
    fn: () => TxState.pending,
    target: $state,
  });

  sample({
    clock: originFx.done,
    fn: () => TxState.confirmation,
    target: $state,
  });

  sample({
    clock: [wrappedFx.finally],
    fn: () => TxState.idle,
    target: $state,
  });

  sample({
    clock: [wrappedFx.done],
    target: onAnyTxDone,
  });

  return Object.assign(wrappedFx, {
    $state,
  });
};
