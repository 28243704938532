import cn from "classnames/bind";
import { FunctionComponent } from "react";
import { Icon, Spinner, Typography } from "shared/ui/elements";

import styles from "./styles.module.scss";

const cx = cn.bind(styles);

type Props = {
  icon?: JSX.Element;
  title: JSX.Element | string;
  description?: string;
  inline?: boolean;
};

export const Info: FunctionComponent<Props> = ({ icon, title, inline, description }) => (
  <div className={cx("box")} data-inline={inline}>
    <div className={cx("header")}>
      {icon}
      <Typography kind="body1" className={cx("title")}>
        {title}
      </Typography>
    </div>
    {description && (
      <Typography kind="body2" className={cx("description")}>
        {description}
      </Typography>
    )}
  </div>
);
