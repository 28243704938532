import {
  $hasUnreadLastNotification,
  openNotificationsPopup,
  closeNotificationsPopup,
  $notificationsPopupIsOpen,
  notifications,
  $notificationsPreview,
  markAllAsRead,
  readNotification,
} from "@entities/notifications/model";
import { Button, Icon, Link, Typography } from "@ui";
import { useStore } from "effector-react";
import styles from "./styles.module.scss";
import { useFloating } from "@floating-ui/react-dom";
import { useOnClickOutside } from "shared/libs/use-click-outside";
import { useEvent } from "effector-react/scope";
import { NotificationCard } from "../NotificationCard";
import { routesMap } from "@configs/routing";

export const NotificationsPopup = () => {
  const { x, y, reference, refs, floating, strategy } = useFloating({
    placement: "bottom",
    strategy: "absolute",
  });

  const isPopupOpen = useStore($notificationsPopupIsOpen);
  const hasUnreadLastNotification = useStore($hasUnreadLastNotification);
  const data = useStore($notificationsPreview);
  const isDataPending = useStore(notifications.$pending);

  const hasNoNotifications = !isDataPending && data.length === 0;

  const handlers = useEvent({
    openNotificationsPopup,
    closeNotificationsPopup,
    markAllAsRead,
    readNotification,
  });

  useOnClickOutside(refs.floating, handlers.closeNotificationsPopup);

  const defaultBody = !hasNoNotifications && (
    <>
      {data.map(({ link: Link, ...notification }) => (
        <Link key={notification.id}>
          <NotificationCard
            onClick={() => handlers.readNotification(notification.id)}
            image={notification.image}
            key={notification.id}
            title={notification.title}
            creationTime={notification.date}
            isViewed={notification.seen}
          />
        </Link>
      ))}

      <Link to={routesMap.notifications}>
        <Button onClick={handlers.closeNotificationsPopup} wide kind="ghost" size="extra-small">
          See all notifications
        </Button>
      </Link>
    </>
  );

  const emptyBody = hasNoNotifications && (
    <Typography kind="body2">You don{"'"}t have any notifications yet</Typography>
  );

  return (
    <span className={styles.box} ref={reference}>
      <span
        className={styles.bell}
        data-has-updates={hasUnreadLastNotification}
        onClick={handlers.openNotificationsPopup}
      >
        <Icon.Bell />
      </span>

      <div
        data-is-open={isPopupOpen}
        className={styles.popup}
        ref={floating}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
        }}
      >
        <div className={styles.header}>
          <Typography weight="bold" kind="body1">
            Notifications
          </Typography>

          {hasUnreadLastNotification && (
            <Typography
              onClick={() => handlers.markAllAsRead()}
              className={styles.markAsRead}
              kind="body2"
            >
              Mark all as read
            </Typography>
          )}
        </div>

        <div className={styles.body}>
          {emptyBody}
          {defaultBody}
        </div>
      </div>
    </span>
  );
};
