import { $coins } from "@entities/coins";
import { fetchNewNotificationsFx, notifications } from "@entities/notifications/model";
import { getERC20BalanceFx } from "@entities/smart-contracts";
import { attach, createEvent, createStore, sample } from "effector";
import { onAnyTxDone } from "shared/libs/create-tx-effect";
import { $defaultAccount } from "shared/libs/effector-metamask";
import { maskAddress } from "shared/libs/mask-address";
import { weiToEth } from "shared/libs/wei-to-eth";
import { CoinBalance } from "./types";

const $maskedViewerAddress = $defaultAccount.map(
  (account) => account && maskAddress(account, { from: 4, to: account.length - 5 }),
);

const fetchCoinsBalanceFx = attach({
  source: {
    defaultAccount: $defaultAccount,
    coins: $coins,
  },
  effect: async ({ defaultAccount, coins }) => {
    const balances = await Promise.all(
      coins.map((coin) =>
        getERC20BalanceFx({ contractAddress: coin.contractAddress, defaultAccount }),
      ),
    );

    return coins.map((coin, idx) => ({
      id: coin.id,
      contractAddress: coin.contractAddress,
      balance: {
        wei: balances[idx],
        eth: weiToEth(balances[idx]),
      },
    }));
  },
});

const $coinsBalance = createStore<CoinBalance[]>([]);
const $balanceIsPending = fetchCoinsBalanceFx.pending;

const updateBalance = createEvent();
const onBalanceUpdatingNotifications = sample({
  clock: fetchNewNotificationsFx.doneData,
  filter: (notifications) =>
    notifications.some(
      (notification) =>
        notification.type === "YourOfferAccepted" || notification.type === "YouGotRepayment",
    ),
});

sample({
  source: {
    defaultAccount: $defaultAccount,
    coins: $coins,
  },
  clock: [updateBalance, $defaultAccount, onAnyTxDone, $coins, onBalanceUpdatingNotifications],
  filter: ({ defaultAccount, coins }) => Boolean(defaultAccount) && coins.length > 0,
  target: fetchCoinsBalanceFx,
});

sample({
  clock: fetchCoinsBalanceFx.doneData,
  target: $coinsBalance,
});

export { $maskedViewerAddress, $coinsBalance, $balanceIsPending, updateBalance };
