import {
  $isAssetInLoan,
  $isAssetOwner,
  $lenderOffersByAsset,
  checkIsAssetOwnerFx,
  fetchLenderOffersByAssetFx,
} from "@entities/assets";
import { useEvent, useStore } from "effector-react";
import { openAccountInfo } from "@entities/account";
import { $assetDetails } from "@features/assets/model";
import { Button } from "@ui";

export const OwnerInfo = () => {
  const isAssetOwner = useStore($isAssetOwner);
  const isAssetInLoan = useStore($isAssetInLoan);
  const assetOwnerChecking = useStore(checkIsAssetOwnerFx.pending);
  const ownOfferIsPending = useStore(fetchLenderOffersByAssetFx.pending);
  const assetDetails = useStore($assetDetails);

  const handlers = useEvent({
    openAccountInfo,
  });

  if (!assetDetails || assetOwnerChecking || isAssetOwner || isAssetInLoan || ownOfferIsPending)
    return null;

  return (
    <>
      <Button kind="ghost" onClick={() => handlers.openAccountInfo(assetDetails.borrower.address)}>
        Owner information
      </Button>
    </>
  );
};
