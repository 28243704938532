import { PrimaryLayout } from "@ui";
import { Helmet } from "react-helmet";
import { PrimaryHeader } from "widgets";

export const NotFound = () => (
  <PrimaryLayout header={<PrimaryHeader />}>
    <div>
      <Helmet title={`Dip Ocean - Not found`} />

      <h1>Page not found</h1>
    </div>
  </PrimaryLayout>
);
