import {
  $acceptOfferModalIsOpen,
  $acceptOfferStage,
  cancelOfferAccept,
} from "@entities/offers/model";
import { AcceptOfferStages, AcceptOfferStagesMap } from "@entities/offers/model/types";
import { Modal } from "@ui";
import { useStore } from "effector-react";
import styles from "./styles.module.scss";
import { AccessGrantView } from "./AccessGrantView";
import { TermsView } from "./TermsView";
import { ConfirmationView } from "./ConfirmationView";
import { useEvent } from "effector-react/scope";

const viewsMap: Record<number, () => JSX.Element | null> = {
  [AcceptOfferStagesMap.accessGrant]: AccessGrantView,
  [AcceptOfferStagesMap.terms]: TermsView,
  [AcceptOfferStagesMap.confirmation]: ConfirmationView,
};

const descriptionByStage: Record<AcceptOfferStages, string> = {
  terms: "Are you sure you want to accept this offer and start the loan?",
  confirmation: "Please sign the transaction",
  accessGrant: "Grant permission to manage asset",
};

export const AcceptOfferModal = () => {
  const stage = useStore($acceptOfferStage);
  const isOpen = useStore($acceptOfferModalIsOpen);
  const handlers = useEvent({
    cancelOfferAccept,
  });

  const CurrentView = viewsMap[stage];

  return (
    <Modal
      className={styles.modal}
      title="Accept offer"
      description={descriptionByStage[stage]}
      isOpen={isOpen}
      renderFooter={null}
      onClose={handlers.cancelOfferAccept}
    >
      {CurrentView && <CurrentView />}
    </Modal>
  );
};
