import { Offer } from "@api/offersService/types";
import { BigNumber, constants } from "ethers";
import { parseSigningError } from "shared/libs/parse-signing-error";
import { DirectLoanFixedOffer } from "shared/smart-contracts";

const acceptOffer = async (contract: DirectLoanFixedOffer, acceptedOffer: Offer) => {
  try {
    const offer = {
      loanPrincipalAmount: acceptedOffer.loanPrincipalAmount,
      maximumRepaymentAmount: acceptedOffer.maximumRepaymentAmount,
      nftCollateralId: acceptedOffer.collateral.tokenID,
      nftCollateralContract: acceptedOffer.collateral.contractAddress,
      loanDuration: acceptedOffer.loanDuration,
      loanAdminFeeInBasisPoints: acceptedOffer.fee,
      loanERC20Denomination: acceptedOffer.loanERC20Denomination,
      referrer: acceptedOffer.referrer,
    };
    const signature = {
      nonce: BigNumber.from(acceptedOffer.nonce),
      expiry: acceptedOffer.expiry,
      signer: acceptedOffer.lender.address,
      signature: acceptedOffer.signature,
    };

    const borrowerSettings = {
      revenueSharePartner: constants.AddressZero,
      referralFeeInBasisPoints: 0,
    };

    const tx = await contract?.acceptOffer(offer, signature, borrowerSettings);

    return tx;
  } catch (e) {
    throw parseSigningError(e);
  }
};

const payBackLoan = (contract: null | DirectLoanFixedOffer, loanId?: string | number) => {
  if (!contract || loanId === undefined) return Promise.reject();

  return contract.payBackLoan(loanId).catch(parseSigningError);
};

const liquidateLoan = (contract: null | DirectLoanFixedOffer, loanId?: string) => {
  if (!contract || loanId === undefined) return Promise.reject();

  return contract.liquidateOverdueLoan(loanId).catch(parseSigningError);
};

const cancelOffer = (contract: null | DirectLoanFixedOffer, nonce?: string) => {
  if (!contract || !nonce) return Promise.reject();

  return contract
    .cancelLoanCommitmentBeforeLoanHasBegun(BigNumber.from(nonce))
    .catch(parseSigningError);
};

export const directLoanFixedOfferService = {
  acceptOffer,
  payBackLoan,
  liquidateLoan,
  cancelOffer,
};
