import { routesMap } from "@configs/routing";
import { Image, Link, Skeleton, Typography } from "@ui";
import { FunctionComponent } from "react";

import styles from "./styles.module.scss";
import { AssetImage } from "../AssetImage";
import { AssetState } from "@api/listingService/types";
import { Asset } from "@api/assetsProviderService/types";

type Props = {
  title?: string;
  subtitle?: string;
  image?: string;
  loading?: boolean;
  tokenId?: string;
  contractAddress?: string;
  status?: Asset["status"];
};

const textByStatus: Record<Asset["status"], string> = {
  Listed: "Listed",
  NotListed: "Unlisted",
  NotAllowed: "Not Allowed",
};

export const AssetCard: FunctionComponent<Props> = ({
  title = "unknown",
  subtitle = "unknown",
  image,
  loading = false,
  contractAddress,
  tokenId,
  status,
}) => {
  const cardView = (
    <article className={styles.box} title={`Collection: ${title}\nToken name:${subtitle}`}>
      <div className={styles.image}>
        <AssetImage
          isLoading={loading}
          loading="lazy"
          src={image}
          state={status && textByStatus[status]}
          alt={subtitle}
        />
      </div>

      <div className={styles.details}>
        <Skeleton active={loading}>
          <Typography className={styles.title} kind="body2" weight="medium">
            {title}
          </Typography>
        </Skeleton>
        <Skeleton active={loading}>
          <Typography className={styles.subtitle} kind="body1" weight="bold">
            {subtitle}
          </Typography>
        </Skeleton>
      </div>
    </article>
  );

  return (
    <>
      {loading || !tokenId || !contractAddress ? (
        cardView
      ) : (
        <Link to={routesMap.assetDetails} params={{ contractAddress, tokenId }}>
          {cardView}
        </Link>
      )}
    </>
  );
};
