import { $isAssetInLoan, $lenderOffersByAsset } from "@entities/assets";
import { $assetDetails, $listingExpiration, $normalizedListingTerms } from "@features/assets/model";
import { Button, Card, FormatFloat, Info, Tag, Typography } from "@ui";
import { useStore } from "effector-react";

import styles from "./styles.module.scss";
import { RevokeListing } from "../RevokeListing";
import { MakeOffer } from "../MakeOffer";
import { RevokeOffer } from "../../../offers";
import { OwnerInfo } from "../OwnerInfo";

export const DesiredTerms = () => {
  const assetDetails = useStore($assetDetails);
  const listingTerms = useStore($normalizedListingTerms);
  const ownOffer = useStore($lenderOffersByAsset);
  const listingExpiration = useStore($listingExpiration);
  const isAssetInLoan = useStore($isAssetInLoan);

  if (!assetDetails || !listingTerms || isAssetInLoan) return null;

  return (
    <Card
      title={
        <>
          <Typography kind="body1">Borrower desired terms</Typography>
          <Tag>Listing expires in {listingExpiration}</Tag>
        </>
      }
    >
      <div className={styles.items}>
        <div className={styles.item}>
          <Typography className={styles.itemTitle} kind="body2">
            For
          </Typography>
          <Typography weight="bold" kind="body1">
            <FormatFloat value={listingTerms?.amount} postFix={listingTerms.coinSymbol} />
          </Typography>
        </div>

        <div className={styles.item}>
          <Typography className={styles.itemTitle} kind="body2">
            Over
          </Typography>
          <Typography weight="bold" kind="body1">
            {listingTerms.lendTerms}
          </Typography>
        </div>

        <div className={styles.item}>
          <Typography className={styles.itemTitle} kind="body2">
            Repayment
          </Typography>
          <Typography weight="bold" kind="body1">
            <FormatFloat value={listingTerms.amountWithAPR} postFix={listingTerms.coinSymbol} />
            {" ("}
            <FormatFloat value={listingTerms.aprPercent} postFix={"% APR"} />
            {")"}
          </Typography>
        </div>
      </div>

      <div className={styles.buttonsBox}>
        <OwnerInfo />
        <MakeOffer />
        <RevokeListing />
        {ownOffer && <RevokeOffer offerId={ownOffer.id} nonce={ownOffer.nonce} />}
      </div>
    </Card>
  );
};
