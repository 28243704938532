import { Button, Content, Icon, PrimaryLayout, Typography } from "@ui";
import mainImage from "./assets/main.png";
import { routesMap } from "@configs/routing";
import styles from "./styles.module.scss";
import { Link } from "atomic-router-react/scope";
import { Footer } from "widgets/Footer";
import { SecondaryHeader } from "widgets";
import { Helmet } from "react-helmet";

const cards = [
  {
    icon: <Icon.Undo />,
    title: "For NFT holders",
    description:
      "Use your NFTs as collateral to borrow ETH and DAI on preferred conditions. Withdraw your funds, manage them as you wish, repay your loan in time and get your NFT back!",
  },
  {
    icon: <Icon.Undo />,
    title: "For whales",
    description:
      "Tired of the low rates provided by liquidity pools and staking platforms? Lend your crypto and set any rate you prefer!",
  },
  {
    icon: <Icon.Undo />,
    title: "For collectors",
    description:
      "Dreaming about one more BAYC? Explore available assets, lend your crypto and try to get your BAYC below the market. At least, you would earn your interest.",
  },
];

export const Home = () => {
  return (
    <PrimaryLayout header={<SecondaryHeader />}>
      <Helmet title={`Dip Ocean - Borrow & lend safely`} />

      <Content>
        <div className={styles.mainLayout}>
          <div className={styles.contentBox}>
            <Typography className={styles.title} weight="bold" kind="title1">
              Discover, collect, and sell extraordinary
              <Typography className={styles.titleBadge} kind="title1" weight="bold">
                NFTs
              </Typography>
            </Typography>

            <Typography className={styles.description} kind="body1">
              DipOcean provides the community with a fantastic set of instruments for all users. Set
              your own conditions for the loans collateralized by your NFTs, earn interest for
              short-term lending, and get rare pieces below the market price.
            </Typography>

            <>
              <Link to={routesMap.explore}>
                <Button>Go to web application</Button>
              </Link>
            </>
          </div>
          <div className={styles.imageBox}>
            <img className={styles.image} src={mainImage} />
          </div>
        </div>

        <section className={styles.cards}>
          {cards.map((card, idx) => (
            <article key={idx} className={styles.card}>
              {card.icon}
              <Typography className={styles.cardTitle} kind="body1" weight="bold">
                {card.title}
              </Typography>
              <Typography kind="body2">{card.description}</Typography>
            </article>
          ))}
        </section>
      </Content>
      <Footer />
    </PrimaryLayout>
  );
};
