import {
  createElement,
  DetailedHTMLProps,
  FunctionComponent,
  HTMLAttributes,
  PropsWithChildren,
} from "react";
import cn from "classnames/bind";

import styles from "./styles.module.scss";

const cx = cn.bind(styles);

type Props = PropsWithChildren<{
  kind?: "danger" | "primary" | "secondary";
  appendBefore?: JSX.Element;
  as?: keyof JSX.IntrinsicElements;
}> &
  DetailedHTMLProps<HTMLAttributes<HTMLElement | HTMLAnchorElement>, HTMLSpanElement>;

export const Tag = ({
  children,
  appendBefore,
  kind = "primary",
  className,
  as = "span",
  ...props
}: Props) =>
  createElement(
    as,
    {
      className: cx("box", className),
      "data-kind": kind,
      ...props,
    },
    <>
      {appendBefore && <span className={styles.appendBefore}>{appendBefore}</span>}
      {children}
    </>,
  );
