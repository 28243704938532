import { runtimeEnv } from "@configs/runTimeEnv";
import { attach } from "effector";
import { BigNumber, constants } from "ethers";
import { $signer } from "shared/libs/effector-ethers";
import { $defaultAccount } from "shared/libs/effector-metamask";
import { ERC20__factory } from "shared/smart-contracts";
import { CheckAllocatedFundsParams, GetBalanceParams } from "./types";

const checkAllocatedFundsFx = attach({
  source: $signer,
  effect: async (signer, { contractAddress, amount, account }: CheckAllocatedFundsParams) => {
    if (!signer) return Promise.reject();

    const ERC20Contract = ERC20__factory.connect(contractAddress, signer);

    const allowance = await ERC20Contract.allowance(
      account,
      runtimeEnv.directLoanFixedOfferAddress,
    );

    // TODO: wait while backend will fix amount and send it as string
    const hasEnoughAllowance = allowance.gte(BigNumber.from(String(amount)));

    if (!hasEnoughAllowance) throw Error("Lender doesn't have enough allowed funds");

    return true;
  },
});

const getERC20BalanceFx = attach({
  source: $signer,
  effect: async (signer, { defaultAccount, contractAddress }: GetBalanceParams) => {
    if (!defaultAccount || !signer) return Promise.reject();

    const ERC20Contract = ERC20__factory.connect(contractAddress, signer);

    const balance = await ERC20Contract.balanceOf(defaultAccount);

    return balance.toString();
  },
});

const checkBalanceOfFx = attach({
  source: $signer,
  effect: async (signer, { account, amount, contractAddress }: CheckAllocatedFundsParams) => {
    if (!signer) return Promise.reject();

    const ERC20Contract = ERC20__factory.connect(contractAddress, signer);
    const balance = await ERC20Contract.balanceOf(account);

    // TODO: wait while backend will fix amount and send it as string
    const hasEnoughFunds = balance.gte(BigNumber.from(String(amount)));

    if (!hasEnoughFunds) throw Error("Lender doesn't have enough money");

    return true;
  },
});

const getERC20AllowanceFx = attach({
  source: {
    signer: $signer,
    defaultAccount: $defaultAccount,
  },
  effect: async ({ defaultAccount, signer }, contractAddress: string) => {
    if (!defaultAccount || !signer) return Promise.reject();

    const ERC20Contract = ERC20__factory.connect(contractAddress, signer);

    return ERC20Contract.approve(runtimeEnv.directLoanFixedOfferAddress, constants.MaxUint256);
  },
});

export { checkBalanceOfFx, getERC20BalanceFx, checkAllocatedFundsFx, getERC20AllowanceFx };
