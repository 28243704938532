import { getLoanTableColumns } from "@entities/loans/libs/loansTableColumns";
import {
  $normalizedOwnActiveLoans,
  ownActiveLoans,
  ownActiveLoansGate,
} from "@entities/loans/model";
import { Table } from "@ui";
import { useEvent, useGate, useStore } from "effector-react/scope";
import { useMemo } from "react";
import { openAccountInfo } from "@entities/account";
export const OwnActiveLoans = () => {
  const data = useStore($normalizedOwnActiveLoans);
  const isPending = useStore(ownActiveLoans.$pending);

  const handlers = useEvent({
    openAccountInfo,
    getMore: ownActiveLoans.getMore,
  });

  const columns = useMemo(
    () =>
      getLoanTableColumns({
        openAccountInfo: handlers.openAccountInfo,
      }),
    [handlers],
  );

  useGate(ownActiveLoansGate);

  return (
    <Table
      onLoanMore={handlers.getMore}
      loading={isPending}
      defaultRowsCount={3}
      columns={columns}
      data={data}
      indexKey="id"
    />
  );
};
