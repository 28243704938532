import { useMemo } from "react";

import { $isAssetInLoan, $isAssetOwner, assetOffers } from "@entities/assets/model";
import { useEvent, useStore } from "effector-react/scope";
import { $acceptedOfferId, acceptOffer } from "@entities/offers";
import { Button, Columns, Link, Table, Typography } from "@ui";
import { AssetOffersTableRow } from "@entities/offers/model/types";
import { $assetOffersTableData, $lenderIsChecking } from "@entities/offers/model";
import { openAccountInfo } from "@entities/account";

import styles from "./styles.module.scss";
import { AcceptOfferModal } from "../AcceptOfferModal";

export const AssetOffers = () => {
  const data = useStore($assetOffersTableData);
  const isPending = useStore(assetOffers.$pending);
  const isOwner = useStore($isAssetOwner);
  const isAssetInLoan = useStore($isAssetInLoan);
  const acceptedOfferId = useStore($acceptedOfferId);
  const lenderIsChecking = useStore($lenderIsChecking);

  const handlers = useEvent({
    acceptOffer,
    openAccountInfo,
    getMore: assetOffers.getMore,
  });

  const columns = useMemo(() => {
    const data: Columns<AssetOffersTableRow> = [
      {
        title: "Lender",
        dataIndex: "lender",
        render: (row) => (
          <Link onClick={() => handlers.openAccountInfo(row.lender.address)}>
            {row.lender.nickname}
          </Link>
        ),
      },
      {
        title: "Expiration",
        dataIndex: "expiration",
      },
      {
        title: "Received",
        dataIndex: "received",
      },
      {
        title: "Duration",
        dataIndex: "duration",
      },
      {
        title: "Loan value",
        dataIndex: "loanValue",
      },
      {
        title: "Repayment",
        dataIndex: "repayment",
      },
      {
        title: "APR",
        dataIndex: "apr",
      },
    ];

    return data;
  }, []);

  const tableColumns = useMemo(() => {
    if (!isOwner || data.length === 0) return columns;

    const columnsForOwner: Columns<AssetOffersTableRow> = [
      ...columns,
      {
        title: "",
        dataIndex: "id",
        render: (row) => (
          <Button
            loading={acceptedOfferId === row.id && lenderIsChecking}
            onClick={() => handlers.acceptOffer(row.id)}
            kind="secondary"
            size="small"
            disabled={row.expired}
            title={row.expired ? "Offer expired" : ""}
            wide
          >
            Accept
          </Button>
        ),
      },
    ];

    return columnsForOwner;
  }, [lenderIsChecking, data, acceptedOfferId, isOwner]);

  return (
    <section className={styles.box}>
      <Typography kind="body1" weight="bold">
        Offers received
      </Typography>
      <Table
        onLoanMore={handlers.getMore}
        loading={isPending}
        defaultRowsCount={5}
        indexKey="id"
        columns={tableColumns}
        noDataText={isAssetInLoan ? "Asset in loan" : "No offers yet"}
        data={isAssetInLoan ? [] : data}
      />

      <AcceptOfferModal />
    </section>
  );
};
