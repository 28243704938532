import { createRoute } from "atomic-router";

export const routesMap = {
  home: createRoute(),
  assetDetails: createRoute<{ contractAddress: string; tokenId: string }>(),
  userAssets: createRoute(),
  loans: createRoute(),
  offers: createRoute(),
  listings: createRoute(),
  explore: createRoute(),
  notifications: createRoute(),
  errors: {
    notFound: createRoute(),
  },
  profile: createRoute(),
  about: createRoute(),
};

export const routes = [
  { path: "/", route: routesMap.home },
  { path: "/asset/:contractAddress/:tokenId", route: routesMap.assetDetails },
  { path: "/loans", route: routesMap.loans },
  { path: "/offers", route: routesMap.offers },
  { path: "/user/assets", route: routesMap.userAssets },
  { path: "/explore", route: routesMap.explore },
  { path: "/notifications", route: routesMap.notifications },
  { path: "/about", route: routesMap.about },
  { path: "/profile", route: routesMap.profile },
];

export const notFoundRoute = routesMap.errors.notFound;
