import { precisions } from "@configs/constants";
import { utils } from "ethers";

type Params = {
  loan: number;
  repayment: number;
  days: number;
};

const daysInYear = 365;
const staticMultiplier = 10 ** precisions.float;

export const calculateAPR = ({ loan, repayment, days }: Params) => {
  if (loan <= 0 || repayment <= 0 || days <= 0) return 0;

  const loanInWei = utils.parseEther(String(loan));
  const repaymentInWei = utils.parseEther(String(repayment));

  const diffInPercent = repaymentInWei
    .sub(loanInWei)
    .mul(staticMultiplier)
    .mul(100)
    .div(loanInWei)
    .toNumber();

  const totalPercent = (diffInPercent / staticMultiplier / days) * daysInYear;

  return parseFloat(String(totalPercent.toFixed(precisions.percent)));
};
