import { Button, Message } from "@ui";
import { useEvent, useStore } from "effector-react/scope";
import {
  setAcceptOfferStage,
  confirmOfferAccept,
  acceptOfferState,
  acceptOfferFx,
} from "@entities/offers/model";

// import styles from "./styles.module.scss";
import commonStyles from "../styles.module.scss";
import { AcceptOfferStagesMap } from "@entities/offers/model/types";
import { Typography } from "shared/ui/elements";
import { TxState } from "shared/libs/create-tx-effect";

export const ConfirmationView = () => {
  const state = useStore(acceptOfferFx.$state);
  const error = useStore(acceptOfferState.$error);

  const loading = state === TxState.confirmation || state === TxState.pending;

  const handlers = useEvent({
    setAcceptOfferStage,
    confirmOfferAccept,
  });

  const handleBack = () => handlers.setAcceptOfferStage(AcceptOfferStagesMap.terms);
  const handleOk = () => handlers.confirmOfferAccept();

  return (
    <div className={commonStyles.modalBody}>
      <div className={commonStyles.modalContent}>
        <Message>
          <Typography kind="body1">
            Please click sign to show the metamask popup and confirm the transaction and begin the
            loan
          </Typography>

          <Typography kind="body1">
            By singing this transaction you agree to our <a href="#">Terms of service</a> and{" "}
            <a href="#">Terms of use</a>
          </Typography>
        </Message>
      </div>

      {state === TxState.pending && (
        <Message.Pending
          title="Waiting for signature..."
          description="Please approve the transaction in your wallet"
        />
      )}

      {state === TxState.confirmation && (
        <Message.Pending
          title="Executing: Accept offer"
          description="Please approve the transaction in your wallet"
        />
      )}

      {error && <Message.Error title="Error executing transaction" description={error} />}

      <div className={commonStyles.modalFooter}>
        <Button kind="ghost" disabled={loading} onClick={handleBack}>
          Back
        </Button>
        <Button disabled={loading} onClick={handleOk}>
          Sign
        </Button>
      </div>
    </div>
  );
};
