import { profileListings } from "@entities/account/model";
import { AssetCard } from "@entities/assets";
import { EmptyList } from "@ui";
import { useEvent, useStore } from "effector-react";
import { FunctionComponent } from "react";
import cn from "classnames/bind";
import { IntersectionObserver } from "shared/libs/intersection-observer";

import styles from "./styles.module.scss";

const cx = cn.bind(styles);

const mock = Array.from(
  {
    length: 12,
  },
  (_, i) => i,
);

type Props = {
  className?: string;
};

export const AccountAssets: FunctionComponent<Props> = ({ className }) => {
  const listings = useStore(profileListings.$data);
  const listingsLoading = useStore(profileListings.$pending);

  const handlers = useEvent({
    getMore: profileListings.getMore,
  });

  if (!listingsLoading && listings.length === 0)
    return <EmptyList firstLine="" secondLine="Your NFT will be here" small />;

  const loadingView = listingsLoading && mock.map((i) => <AssetCard key={i} loading />);

  const defaultView = listings.map((listing) => (
    <AssetCard
      key={listing.id}
      title={listing.collateral.collectionName}
      subtitle={listing.collateral.name}
      tokenId={listing.collateral.tokenID}
      contractAddress={listing.collateral.contractAddress}
      image={listing.collateral.imageURL}
    />
  ));

  return (
    <IntersectionObserver disabled={listingsLoading} onIntersect={handlers.getMore}>
      {(ref) => (
        <section className={cx("cardsLayout", className)}>
          {defaultView}
          {loadingView}
          <span ref={ref} />
        </section>
      )}
    </IntersectionObserver>
  );
};
