import { Skeleton, Typography } from "@ui";
import { FunctionComponent } from "react";
import styles from "./styles.module.scss";

type Props = {
  kind?: "primary" | "secondary";
  values?: Array<string | number | undefined> | { title: string; value?: string | number | null }[];
  title: string;
  loading?: boolean;
  weight?: "bold";
};

export const AccountStatCard: FunctionComponent<Props> = ({
  title,
  values,
  weight,
  loading,
  kind,
}) => (
  <article className={styles.card} data-kind={kind}>
    <div className={styles.cardHeadline}>
      <Typography className={styles.title} kind="body1" weight="medium">
        {title}:
      </Typography>
    </div>

    <ul className={styles.cardList}>
      {!values?.length && (
        <Skeleton active={loading}>
          <li> N/A</li>
        </Skeleton>
      )}

      {values?.map((row) => {
        if (typeof row === "object") {
          return (
            <li data-weight={weight} key={row.title}>
              <span className={styles.subtitle}>{title}:</span>
              <Skeleton active={loading}>
                <span className={styles.value}>{row.value || 0}</span>
              </Skeleton>
            </li>
          );
        }

        return (
          <Skeleton key={row} active={loading}>
            <li data-weight={weight}>{row}</li>
          </Skeleton>
        );
      })}
    </ul>
  </article>
);
