import { FocusEventHandler, forwardRef, InputHTMLAttributes, useMemo } from "react";
import { accurateToFixed } from "shared/libs/accurate-to-fixed";
import { Input } from "./Default";
import { InputProps } from "./types";

type CustomProps = {
  value?: number | string;
  onChange?: (value: number | string) => void;
  error?: string;
  maxDigitAfterComma?: number;
};

type Props = CustomProps & Omit<InputProps, keyof CustomProps | "type">;

const normalizeValue = (value: string | number, maxDigitAfterComma: number) => {
  const normalizedValue = value.toString().replace(",", ".");
  const num = parseFloat(accurateToFixed(parseFloat(normalizedValue), maxDigitAfterComma));

  return num;
};

export const InputNumber = forwardRef<HTMLInputElement, Props>(
  ({ value = "", onChange, onBlur, maxDigitAfterComma = 0, ...rest }, ref) => {
    const normalizedValue = useMemo(() => {
      if (String(value).endsWith(".") || String(value).endsWith("0")) return value;

      const parsedValue = parseFloat(String(value));

      if (isNaN(parsedValue)) return value;

      return accurateToFixed(parsedValue, maxDigitAfterComma);
    }, [value, maxDigitAfterComma]);

    const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
      if (typeof value !== "number") {
        const num = parseFloat(e.currentTarget.value);

        onChange?.(isNaN(num) ? 0 : num);
      }

      onBlur?.(e);
    };

    return (
      <Input
        ref={ref}
        {...rest}
        onChange={onChange}
        type="text"
        value={normalizedValue}
        onBlur={handleBlur}
      />
    );
  },
);

InputNumber.displayName = "InputNumber";
