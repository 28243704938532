import { precisions } from "@configs/constants";
import { utils } from "ethers";
import { accurateToFixed } from "../accurate-to-fixed";

type Params = {
  maxDigitsAfterComma?: number;
};
export const weiToEth = (value: null | number | string, params?: Params) => {
  if (!value) return 0;

  return parseFloat(
    accurateToFixed(
      parseFloat(utils.formatEther(String(value))),
      params?.maxDigitsAfterComma ?? precisions.float,
    ),
  );
};
