import { getAddress } from "ethers/lib/utils";

export const runtimeEnv =
  typeof window !== "undefined"
    ? {
        // client
        baseUrl: window.env.baseUrl,
        directLoanFixedOfferAddress: getAddress(window.env.directLoanFixedOfferAddress),
        permittedNftsAndTypeRegistryAddress: getAddress(
          window.env.permittedNftsAndTypeRegistryAddress,
        ),
        isClient: true,
        mode: process.env.RAZZLE_MODE,
        etherscanBaseUrl: window.env.etherscanBaseUrl,
        defaultChainId: window.env.defaultChainId,
      }
    : {
        // server
        baseUrl: process.env.RAZZLE_BASE_URL,
        permittedNftsAndTypeRegistryAddress: getAddress(
          process.env.RAZZLE_PERMITTED_NFTS_AND_TYPE_REGISTRY_ADDRESS,
        ),
        directLoanFixedOfferAddress: getAddress(process.env.RAZZLE_DIRECT_LOAN_FIXED_OFFER_ADDRESS),
        etherscanBaseUrl: process.env.RAZZLE_ETHERSCAN_BASE_URL,
        isClient: false,
        mode: process.env.RAZZLE_MODE,
        defaultChainId: process.env.RAZZLE_DEFAULT_CHAIN_ID,
      };
