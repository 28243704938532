import { Image, Skeleton, Typography } from "@ui";
import { FunctionComponent } from "react";

import styles from "./styles.module.scss";

type Props = {
  title: JSX.Element | string;
  description?: JSX.Element | string;
  creationTime: string;
  isViewed: boolean;
  image: string | null;
  onClick?: () => void;
  loading?: boolean;
};

export const NotificationCard: FunctionComponent<Props> = ({
  title,
  description,
  creationTime,
  isViewed,
  onClick,
  image,
  loading = false,
}) => (
  <article
    onClick={onClick}
    className={styles.box}
    data-is-viewed={isViewed}
    data-is-interactive={Boolean(onClick)}
  >
    <Skeleton active={loading}>
      <div className={styles.image}>{image && <Image src={image} loading="lazy" />}</div>
    </Skeleton>

    <div className={styles.content}>
      <div className={styles.headline}>
        <Skeleton active={loading}>
          <Typography className={styles.title} kind="body1" weight="bold">
            {title}
          </Typography>
        </Skeleton>

        <Skeleton active={loading}>
          <Typography className={styles.creationDate} kind="body2" weight="medium">
            {creationTime}
          </Typography>
        </Skeleton>
      </div>

      {loading ||
        (description && (
          <Skeleton active={loading}>
            <div className={styles.description}>{description}</div>
          </Skeleton>
        ))}
    </div>
  </article>
);
