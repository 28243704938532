import { cloneElement, ReactElement } from "react";
import { Controller, useFormContext, UseControllerProps } from "react-hook-form";
import cn from "classnames/bind";

import styles from "./styles.module.scss";

const cx = cn.bind(styles);

type Props<T> = Pick<UseControllerProps, "defaultValue" | "shouldUnregister"> & {
  children: ReactElement;
  name: T;
  onChange?: (v: unknown) => void;
  label?: string;
  className?: string;
};

export function Field<T extends string>({
  shouldUnregister,
  name,
  defaultValue,
  children,
  label,
  className,
  onChange,
}: Props<T>) {
  const form = useFormContext();

  const handleChange = (value: any) => {
    form.setValue(name, value, { shouldValidate: true });

    onChange?.(value);
  };

  return (
    <label className={className}>
      {label && <span className={cx("labelText")}>{label}</span>}
      <Controller
        control={form.control}
        shouldUnregister={shouldUnregister}
        defaultValue={defaultValue}
        name={name}
        render={({ field, fieldState }) => (
          <>
            {cloneElement(children, {
              value: field.value,
              onBlur: field.onBlur,
              onChange: handleChange,
              error: fieldState.error?.message,
              ref: field.ref,
            })}
            <span className={cx("error")} data-visible={Boolean(fieldState.error?.message)}>
              {fieldState.error?.message}
            </span>
          </>
        )}
      />
    </label>
  );
}
