import { routesMap } from "@configs/routing";
import { runtimeEnv } from "@configs/runTimeEnv";
import {
  $assetContractAddress,
  $assetTokenId,
  assetOffers,
  checkIsAssetInLoanFx,
  checkIsAssetOwnerFx,
  fetchLenderOffersByAssetFx,
  setAssetContractAddress,
  setAssetTokenId,
} from "@entities/assets";
import { assetLoans, fetchAssetLoanFx, resetAssetLoan } from "@entities/loans/model";
import { checkIsCollectionPermittedFx } from "@entities/smart-contracts/model/permittedNFTsAndTypeRegistry";
import {
  fetchAssetDetailsFx,
  fetchAssetsMetadataFx,
  resetAssetDetailsDomain,
} from "@features/assets";
import { combine, createDomain, createEvent, sample } from "effector";
import { getAddress } from "ethers/lib/utils";
import { $defaultAccount } from "shared/libs/effector-metamask";
import { BottomTabs } from "./types";

const pageDomain = createDomain();

// SSR data fetching
sample({
  source: routesMap.assetDetails.$params,
  clock: routesMap.assetDetails.$params,
  fn: (params) => ({
    tokenID: params.tokenId,
    contractAddress: getAddress(params.contractAddress),
  }),
  target: [fetchAssetsMetadataFx],
});

sample({
  clock: routesMap.assetDetails.$params,
  fn: (params) => params.tokenId,
  target: setAssetTokenId,
});

sample({
  clock: routesMap.assetDetails.$params,
  fn: (params) => params.contractAddress,
  target: setAssetContractAddress,
});

// CSR with $default account dependent data fetching
sample({
  source: routesMap.assetDetails.$params,
  clock: [routesMap.assetDetails.$params, $defaultAccount, $assetTokenId, $assetContractAddress],
  filter: combine(
    routesMap.assetDetails.$isOpened,
    $defaultAccount,
    $assetTokenId,
    $assetContractAddress,
    (isOpened, defaultAccount, tokenId, contractAddress) => {
      return Boolean(
        runtimeEnv.isClient && isOpened && defaultAccount && tokenId && contractAddress,
      );
    },
  ),
  fn: (params) => ({
    tokenID: params.tokenId,
    contractAddress: getAddress(params.contractAddress),
  }),
  target: [
    checkIsAssetOwnerFx,
    checkIsAssetInLoanFx,
    fetchAssetDetailsFx,
    fetchLenderOffersByAssetFx,
    assetOffers.get,
    assetLoans.get,
    fetchAssetLoanFx,
    checkIsCollectionPermittedFx,
  ],
});

sample({
  clock: routesMap.assetDetails.closed,
  target: [resetAssetDetailsDomain, resetAssetLoan],
});

const setActiveTab = createEvent<number>();

const $activeTab = pageDomain.createStore<number>(BottomTabs.offers);

$activeTab.on(setActiveTab, (_, value) => value);

pageDomain.onCreateStore((store) => store.reset(routesMap.assetDetails.closed));

export { $activeTab, setActiveTab };
