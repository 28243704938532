import { useEvent, useStore } from "effector-react/scope";

import {
  setMakeOfferStage,
  getAllowanceForFunds,
  allocateFundForOfferState,
  allocateFundForOfferFx,
  $offerTerms,
  $normalizedOfferTerms,
} from "@features/assets/model";
import { Button, Message, ModalBody, ModalFooter, Typography } from "@ui";
import { createOfferStages } from "@features/assets/model/types";

import styles from "./styles.module.scss";
import { TxState } from "shared/libs/create-tx-effect";

export const AccessGrantView = () => {
  const state = useStore(allocateFundForOfferFx.$state);
  const error = useStore(allocateFundForOfferState.$error);

  const pending = state === TxState.pending || state === TxState.confirmation;

  const handlers = useEvent({
    getAllowanceForFunds,
    setMakeOfferStage,
  });

  const handleBack = () => handlers.setMakeOfferStage(createOfferStages.initial);

  return (
    <>
      <ModalBody className={styles.layout}>
        <div className={styles.info}>
          <Message>
            <Typography kind="body1">
              Please click “Grant” below to access the MetaMask popup and grant permission
            </Typography>
          </Message>
        </div>
        {state === TxState.pending && (
          <Message.Pending
            title="Waiting for signature..."
            description="Please approve the transaction in your wallet"
          />
        )}
        {state === TxState.confirmation && <Message.Pending title="Executing: Grant permission" />}
        {error && <Message.Error title="Error executing transaction" description={error} />}
      </ModalBody>
      <ModalFooter>
        <Button kind="ghost" disabled={pending} onClick={handleBack}>
          Back
        </Button>
        <Button disabled={pending} onClick={() => handlers.getAllowanceForFunds()}>
          Grant
        </Button>
      </ModalFooter>
    </>
  );
};
