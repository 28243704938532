import { $assetContractAddress, $assetTokenId, $isAssetInLoan } from "@entities/assets";
import { $coinsMap } from "@entities/coins";
import {
  $assetLoan,
  assetLoans,
  fetchAssetLoanFx,
  fetchAssetLoanState,
  updateAssetLoanFx,
} from "@entities/loans/model";
import { combine, createEvent, sample } from "effector";
import { getAddress } from "ethers/lib/utils";
import { calculateAPR } from "shared/libs/calculate-apr";
import { $defaultAccount } from "shared/libs/effector-metamask";
import { secondsToDays } from "shared/libs/format-days";
import { maskAddress } from "shared/libs/mask-address";
import { pluralize } from "shared/libs/pluralize";
import { weiToEth } from "shared/libs/wei-to-eth";
import { NormalizedAssetLoan } from "./types";
import { interval } from "patronum/interval";
import { condition } from "patronum/condition";
import { precisions } from "@configs/constants";

const $normalizedAssetLoans = combine(
  assetLoans.$data,
  $coinsMap,
  $defaultAccount,
  (loans, coinsMap, defaultAccount) =>
    loans.map((loan): NormalizedAssetLoan => {
      const startDate = new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format(new Date(loan.createdAt * 1000));

      const duration = new Date(loan.expiresAt).getTime() - new Date(loan.createdAt).getTime();

      const coin = coinsMap[loan.terms.coinAddress] || null;

      const borrower = {
        address: loan.borrower.address,
        nickname:
          getAddress(loan.borrower.address) === getAddress(defaultAccount)
            ? "You"
            : loan.borrower.nickname ||
              maskAddress(loan.borrower.address, {
                from: 4,
                to: loan.borrower.address.length - 5,
              }),
      };

      const lender = {
        address: loan.lender.address,
        nickname:
          getAddress(loan.lender.address) === getAddress(defaultAccount)
            ? "You"
            : loan.lender.nickname ||
              maskAddress(loan.lender.address, {
                from: 4,
                to: loan.lender.address.length - 5,
              }),
      };

      return {
        id: loan.id,
        lender,
        borrower,
        startDate,
        duration: pluralize(secondsToDays(duration), "day", "s"),
        loanValue: `${weiToEth(loan.terms.amount)} ${coin.currencySymbol}`,
        repayment: `${weiToEth(loan.terms.amountWithAPR, {
          maxDigitsAfterComma: precisions.repayment,
        })} ${coin.currencySymbol}`,
        apr: `${calculateAPR({
          loan: weiToEth(loan.terms.amount),
          repayment: weiToEth(loan.terms.amountWithAPR, {
            maxDigitsAfterComma: precisions.repayment,
          }),
          days: secondsToDays(duration),
        })}%`,
      };
    }),
);

const $loanInProcessing = combine(
  $assetLoan,
  fetchAssetLoanState.$status,
  $isAssetInLoan,
  (loan, fetchLoanStatus, isAssetInLoan) => {
    return loan === null && fetchLoanStatus === "succeed" && isAssetInLoan;
  },
);

const startFetchingAssetLoan = createEvent();
const stopFetchingAssetLoan = createEvent();

const assetLoanFetchingTimeout = interval({
  timeout: 30 * 1000,
  start: startFetchingAssetLoan,
  stop: stopFetchingAssetLoan,
});

sample({
  clock: assetLoanFetchingTimeout.tick,
  source: {
    tokenID: $assetTokenId,
    contractAddress: $assetContractAddress,
  },
  fn: ({ tokenID, contractAddress }) => ({
    tokenID,
    contractAddress: contractAddress!,
  }),
  target: updateAssetLoanFx,
});

condition({
  source: $loanInProcessing,
  if: Boolean,
  then: startFetchingAssetLoan,
  else: stopFetchingAssetLoan,
});

export { $normalizedAssetLoans, $loanInProcessing };
