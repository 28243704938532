import { precisions } from "@configs/constants";
import { utils } from "ethers";
import { accurateToFixed } from "../accurate-to-fixed";

type Params = {
  amount: number;
  days: number;
  percent: number;
};

const daysInYear = 365;

export const calculateRepayment = ({ amount, days, percent }: Params) => {
  if (!percent || !days || !amount) return 0;

  const amountInWei = utils.parseEther(String(amount));
  const totalPercent = parseFloat(
    accurateToFixed((percent / daysInYear) * days, precisions.repayment),
  );

  const multiplierForPercent = 10 ** precisions.repayment;

  const totalRepayment = amountInWei
    .mul(String(totalPercent * multiplierForPercent))
    .div(String(100 * multiplierForPercent))
    .add(amountInWei);

  return parseFloat(utils.formatEther(totalRepayment));
};
