import { routesMap } from "@configs/routing";
import { Icon, Link, Logo, Tag } from "@ui";

import styles from "./styles.module.scss";
import { NotificationsPopup } from "@entities/notifications";

const nav = [
  {
    text: "About",
    route: routesMap.about,
  },
];

export const SecondaryHeader = () => {
  return (
    <header className={styles.header}>
      <Link to={routesMap.home}>
        <span className={styles.logo}>
          <Logo />
          <div className={styles.logoText}>Dip Ocean</div>
        </span>
      </Link>

      <div className={styles.rightSide}>
        <nav>
          <ul className={styles.navMenu}>
            {nav.map((navItem, idx) => (
              <li className={styles.navItem} key={idx}>
                <Link kind="secondary" className={styles.navLink} to={navItem.route}>
                  {navItem.text}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};
