import cn from "classnames/bind";
import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import { Spinner, Typography } from "shared/ui/elements";

import styles from "./styles.module.scss";

const cx = cn.bind(styles);

type Props = {
  title: string;
  description?: string;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Pending: FunctionComponent<Props> = ({ title, description, className, ...props }) => (
  <div className={cx("box", className)} {...props}>
    <div className={cx("header")}>
      <Spinner />
      <Typography kind="body1" className={cx("title")}>
        {title}
      </Typography>
    </div>
    {description && (
      <Typography kind="body2" className={cx("description")}>
        {description}
      </Typography>
    )}
  </div>
);
