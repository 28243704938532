import { pluralize } from "../pluralize";

export const calculateLeftTime = (expirationTimestamp: number) => {
  const expirationDate = new Date(expirationTimestamp * 1000);
  const currentDate = new Date();

  const secondsInMinute = 60;
  const secondsInHour = secondsInMinute * 60;
  const secondsInDay = secondsInHour * 24;

  const secondsLeft = Math.floor((expirationDate.getTime() - currentDate.getTime()) / 1000);
  const daysLeft = Math.floor(secondsLeft / secondsInDay);
  const hoursLeft = Math.floor(secondsLeft / secondsInHour);
  const minutesLeft = Math.floor(secondsLeft / secondsInMinute);

  const values = [
    [daysLeft, pluralize(daysLeft, "day", "s")],
    [hoursLeft, pluralize(hoursLeft, "hour", "s")],
    [minutesLeft, pluralize(minutesLeft, "minute", "s")],
    [secondsLeft, pluralize(secondsLeft, "second", "s")],
  ] as const;

  const exactDimension = values.find(([value]) => value > 0);

  return exactDimension ? exactDimension[1] : false;
};
