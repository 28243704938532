import {
  Button,
  Field,
  Form,
  Icon,
  Input,
  InputNumber,
  ModalBody,
  ModalFooter,
  Radio,
  Typography,
} from "@ui";
import { useEvent, useStore } from "effector-react/scope";
import { useForm } from "react-hook-form";
import {
  $assetDetails,
  $normalizedOfferTerms,
  submitOfferTerms,
  cancelOfferMaking,
} from "../../../model";
import { useEffect, useMemo } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { OfferForm } from "./types";
import { errorMessages } from "@configs/errorMessages";
import { $coins } from "@entities/coins";

import styles from "./styles.module.scss";
import { calculateRepayment } from "shared/libs/calculate-repayment";
import { calculateAPR } from "shared/libs/calculate-apr";
import { weiToEth } from "shared/libs/wei-to-eth";
import { secondsToDays } from "shared/libs/format-days";
import { $coinsBalance } from "@features/viewer";
import { minAmount, precisions } from "@configs/constants";

const schema = yup.object().shape({
  lenderBalance: yup.number().typeError(""),
  amount: yup
    .number()
    .required(errorMessages.default)
    .max(yup.ref("lenderBalance"), errorMessages.shouldBeLessThanBalance)
    .min(minAmount, errorMessages.min(minAmount))
    .typeError(""),
  amountWithAPR: yup
    .number()
    .required(errorMessages.default)
    .moreThan(yup.ref("amount"), errorMessages.moreThanField("amount"))
    .typeError(""),
  lendDays: yup
    .number()
    .required(errorMessages.default)
    .typeError("")
    .max(90, errorMessages.lessThanField("90 days"))
    .moreThan(0, errorMessages.moreThanField("0")),
  coinAddress: yup.string().required(errorMessages.default).typeError(""),
  apr: yup.number().max(300, errorMessages.APRLimit(300)).typeError(""),
});

export const InitialView = () => {
  const coins = useStore($coins);
  const assetDetails = useStore($assetDetails);
  const terms = useStore($normalizedOfferTerms);
  const coinsBalance = useStore($coinsBalance);

  const handlers = useEvent({
    submitOfferTerms,
    cancelOfferMaking,
  });

  const form = useForm<OfferForm>({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      coinAddress: coins[0]?.contractAddress,
    },
  });

  const coinAddress = form.watch("coinAddress");

  const selectedCoinName = useMemo(
    () => coins.find((coin) => coin.contractAddress === coinAddress),
    [coins, coinAddress],
  );

  const recalculateRepayment = () => {
    const formValues = form.getValues();

    const amount = parseFloat(String(formValues.amount));
    const percent = parseFloat(String(formValues.apr));
    const days = parseInt(String(formValues.lendDays));

    if (amount && percent && days) {
      const amountWithAPR = calculateRepayment({
        amount,
        percent,
        days,
      });

      form.setValue("amountWithAPR", amountWithAPR, { shouldValidate: true });
    }
  };

  const recalculateAPR = () => {
    const formValues = form.getValues();
    const loan = parseFloat(String(formValues.amount));
    const repayment = parseFloat(String(formValues.amountWithAPR));
    const days = parseInt(String(formValues.lendDays));

    if (loan && repayment && days && loan < repayment) {
      const apr = calculateAPR({
        loan: parseFloat(String(formValues.amount)),
        repayment: parseFloat(String(formValues.amountWithAPR)),
        days: parseInt(String(formValues.lendDays)),
      });

      form.setValue("apr", apr, { shouldValidate: true });
    }
  };

  const setLenderBalance = (coinAddress: string) => {
    const availableBalance = coinsBalance.find((coin) => coin.contractAddress === coinAddress);

    form.setValue("lenderBalance", availableBalance?.balance.eth || 0);
    form.trigger();
  };

  const handleDesiredTermsAccept = () => {
    if (!assetDetails) return;
    const amount = weiToEth(assetDetails.desiredOffer.amount);
    const amountWithAPR = weiToEth(assetDetails.desiredOffer.amountWithAPR, {
      maxDigitsAfterComma: precisions.repayment,
    });
    const lendDays = secondsToDays(assetDetails.desiredOffer.lendSeconds);

    form.setValue("amountWithAPR", amountWithAPR);
    form.setValue("amount", amount);
    form.setValue("lendDays", lendDays);
    form.setValue("coinAddress", assetDetails.desiredOffer.coinAddress);

    setLenderBalance(assetDetails.desiredOffer.coinAddress);

    recalculateAPR();

    form.trigger();
  };

  useEffect(() => {
    if (terms && terms.coin) {
      setLenderBalance(terms.coin.contractAddress);
      form.setValue("amount", terms.amount);
      form.setValue("amountWithAPR", terms.amountWithAPR);
      form.setValue("lendDays", terms.lendDays);
      form.setValue("coinAddress", terms.coin.contractAddress);

      form.trigger();
    }
  }, [terms]);

  useEffect(() => {
    if (coinAddress) {
      setLenderBalance(coinAddress);
    }
  }, [coinAddress]);

  useEffect(() => {
    if (!terms && assetDetails?.desiredOffer) {
      handleDesiredTermsAccept();
    }
  }, [terms, assetDetails?.desiredOffer]);

  return (
    <Form className={styles.form} form={form} onSubmit={handlers.submitOfferTerms}>
      <ModalBody>
        <Button
          onClick={handleDesiredTermsAccept}
          kind="ghost"
          appendBefore={<Icon.Process />}
          size="extra-small"
          type="button"
        >
          Set to borrower’s terms
        </Button>
        <Field className={styles.hiddenInput} name="lenderBalance">
          <Input type="hidden" />
        </Field>

        <Field
          className={styles.field}
          label="In which currency are you willing to accept loans?"
          name="coinAddress"
        >
          <Radio.Group>
            {coins.map((coin) => (
              <Radio key={coin.id} value={coin.contractAddress}>
                {coin.currencySymbol}
              </Radio>
            ))}
          </Radio.Group>
        </Field>

        <Field
          onChange={recalculateRepayment}
          className={styles.field}
          label="What loan amount are you willing to accept?"
          name="amount"
          defaultValue={0}
        >
          <InputNumber
            appendAfter={<Typography kind="body1">{selectedCoinName?.currencySymbol}</Typography>}
            placeholder="0.0"
            maxDigitAfterComma={precisions.float}
          />
        </Field>
        <Field
          onChange={recalculateRepayment}
          className={styles.field}
          label="Specify the desired loan term"
          name="lendDays"
          defaultValue={null}
        >
          <InputNumber placeholder="0" appendAfter={<Typography kind="body1">days</Typography>} />
        </Field>

        <div className={styles.field}>
          <Typography kind="body1">Enter the annual interest rate on the loan</Typography>
          <div className={styles.aprLayout}>
            <Field name="apr" onChange={recalculateRepayment}>
              <InputNumber
                maxDigitAfterComma={precisions.percent}
                placeholder="0.0"
                appendAfter={<Typography kind="body1">%</Typography>}
                className={styles.aprField}
              />
            </Field>
            <Field name="amountWithAPR" onChange={recalculateAPR}>
              <InputNumber
                maxDigitAfterComma={precisions.repayment}
                appendAfter={
                  <Typography kind="body1">{selectedCoinName?.currencySymbol}</Typography>
                }
                placeholder="0.0"
              />
            </Field>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => handlers.cancelOfferMaking()} kind="ghost">
          Back
        </Button>
        <Button disabled={!form.formState.isValid} type="submit">
          Make offer
        </Button>
      </ModalFooter>
    </Form>
  );
};
