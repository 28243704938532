import { routesMap } from "@configs/routing";
import { openAccountInfo } from "@entities/account";
import { receivedOffers, acceptOffer, $normalizedReceivedOffers } from "@entities/offers";
import { Button, Columns, Link, Table } from "@ui";
import { useStore } from "effector-react";
import { useEvent } from "effector-react/scope";
import { useEffect, useMemo } from "react";
import { NormalizedOffer } from "shared/libs/normalizeOffer";
import { AcceptOfferModal } from "../AcceptOfferModal";

export const ReceivedOffers = () => {
  const offers = useStore($normalizedReceivedOffers);
  const pending = useStore(receivedOffers.$pending);

  const handlers = useEvent({
    acceptOffer,
    get: receivedOffers.get,
    reset: receivedOffers.reset,
    getMore: receivedOffers.getMore,
    openAccountInfo,
  });

  const columns = useMemo(() => {
    const data: Columns<NormalizedOffer> = [
      {
        title: "Asset",
        dataIndex: "collateral.tokenID",
        render: (row) => (
          <Link
            to={routesMap.assetDetails}
            params={{
              tokenId: row.collateral.tokenID,
              contractAddress: row.collateral.contractAddress,
            }}
          >
            {row.collateral.name}
          </Link>
        ),
      },
      {
        title: "Offered by",
        dataIndex: "lender.nickname",
        render: (row) => (
          <Link onClick={() => handlers.openAccountInfo(row.lender.address)}>
            {row.lender.nickname}
          </Link>
        ),
      },
      {
        title: "Duration",
        dataIndex: "duration",
      },
      {
        title: "Loan value",
        dataIndex: "amount",
      },
      {
        title: "Repayment",
        dataIndex: "amountWithAPR",
      },
      {
        title: "APR",
        dataIndex: "apr",
      },
      {
        title: "",
        dataIndex: "nonce",
        render: (row) => (
          <Button
            onClick={() => handlers.acceptOffer(row.id)}
            size="small"
            kind="secondary"
            disabled={row.expired}
            title={row.expired ? "Offer expired" : ""}
          >
            Accept offer
          </Button>
        ),
      },
    ];

    return data;
  }, []);

  useEffect(() => {
    handlers.get();

    return () => handlers.reset();
  }, []);

  return (
    <>
      <Table
        onLoanMore={handlers.getMore}
        noDataText="You don't have offers from the lender yet"
        loading={pending}
        data={offers}
        columns={columns}
        indexKey="nonce"
      />
      <AcceptOfferModal />
    </>
  );
};
