import classNames from "classnames/bind";
import React, { PropsWithChildren } from "react";

import { RadioOptionValue } from "../../types";

import styles from "./styles.module.scss";

const cx = classNames.bind(styles);

type RadioPrivateProps = {
  _name?: string;
  _onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  _checked?: boolean;
};

type RadioItemProps = PropsWithChildren<{
  value: RadioOptionValue;
  disabled?: boolean;
  defaultChecked?: boolean;
  className?: string;
}> &
  RadioPrivateProps;

const RadioItem: React.FC<RadioItemProps> = React.forwardRef<HTMLInputElement, RadioItemProps>(
  ({ disabled, defaultChecked, className, children, _checked, _name, _onChange }, ref) => (
    <label className={cx("label", className)} data-checked={_checked} data-disabled={disabled}>
      <input
        ref={ref}
        className={cx("hidden-input")}
        name={_name}
        type="radio"
        disabled={disabled}
        defaultChecked={defaultChecked}
        onChange={_onChange}
        checked={_checked}
      />
      {children}
    </label>
  ),
);

RadioItem.displayName = "Radio.Item";

export { RadioItem };
export type { RadioItemProps };
