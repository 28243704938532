import { httpClient } from "@api/clients/http-client";
import { UpdateUserInfoPayload, UserInfo } from "./types";

const getInfo = (userToken: string): Promise<UserInfo> => httpClient.get(`/user/${userToken}`);

const updateInfo = (payload: UpdateUserInfoPayload) => httpClient.put("/user", payload);

export const UserService = {
  getInfo,
  updateInfo,
};
