import { Card, Info, Skeleton, Typography } from "@ui";
import { useStore } from "effector-react";
import styles from "./styles.module.scss";

export const AssetPrice = () => {
  return (
    <Card
      title={
        <>
          <Typography weight="bold" kind="body1">
            Price Information
          </Typography>

          <Info>
            The price data herein is purely for informational purposes only and should not be relied
            upon as financial, investment, legal, tax or any other professional advice. None of the
            content and information herein is presented to induce or attempt to induce any user or
            other person to buy, sell or hold any token or participate in any protocol or enter
            into, or offer to enter into, any agreement to buy or sell any token or participate in
            any protocol.
          </Info>
        </>
      }
    >
      <div className={styles.layout}>
        <div className={styles.item}>
          <Typography className={styles.itemTitle} kind="body2">
            Project floor
          </Typography>
          <Skeleton active>
            <Typography kind="title4">unknown</Typography>
          </Skeleton>
        </div>

        <div className={styles.item}>
          <Typography className={styles.itemTitle} kind="body2">
            Previous price
          </Typography>
          <Skeleton active>
            <Typography kind="title4">unknown</Typography>
          </Skeleton>
        </div>
      </div>
    </Card>
  );
};
