import {
  getERC20AllowanceForLoanState,
  getERC20Allowance,
  getERC20AllowanceForLoanFx,
} from "@features/loans/model";
import { Button, Message, ModalBody, ModalFooter, Typography } from "@ui";
import { useEvent, useStore } from "effector-react/scope";
import { TxState } from "shared/libs/create-tx-effect";
import styles from "./styles.module.scss";

export const GrantAccessesView = () => {
  const state = useStore(getERC20AllowanceForLoanFx.$state);
  const error = useStore(getERC20AllowanceForLoanState.$error);

  const isPending = state === TxState.pending || state === TxState.confirmation;

  const handlers = useEvent({
    getERC20Allowance,
  });

  return (
    <>
      <ModalBody className={styles.layout}>
        <Message>
          <Typography kind="body1">
            In order to replay, DipOcean needs permission to manage your wETH
          </Typography>
          <Typography kind="body1">
            Please click Grant below to show the metamask popup and grant permission
          </Typography>
        </Message>

        {state === TxState.pending && (
          <Message.Pending
            title="Waiting for signature..."
            description="Please approve the transaction in your wallet"
          />
        )}

        {state === TxState.confirmation && <Message.Pending title="Executing: Grant permission" />}

        {error && <Message.Error title="Error executing transaction" description={error} />}
      </ModalBody>

      <ModalFooter>
        <Button kind="secondary">Back</Button>
        <Button loading={isPending} onClick={() => handlers.getERC20Allowance()}>
          Grant
        </Button>
      </ModalFooter>
    </>
  );
};
