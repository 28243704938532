import { DetailedHTMLProps, AnchorHTMLAttributes } from "react";
import { RouteInstance, RouteParams, RouteQuery } from "atomic-router";
import { Link as AtomicRouterLink } from "atomic-router-react/scope";
import cn from "classnames/bind";

import styles from "./styles.module.scss";

const cx = cn.bind(styles);

type Props<Params extends RouteParams> = {
  to?: RouteInstance<Params> | string;
  params?: Params;
  query?: RouteQuery;
  kind?: "primary" | "secondary";
} & Exclude<DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, "href">;

export const Link = <Params extends RouteParams>({
  className,
  kind,
  to,
  ...props
}: Props<Params>) => {
  if (!to)
    return (
      <a data-kind={kind} className={cx("link", className)} {...props}>
        {props.children}
      </a>
    );

  return (
    <AtomicRouterLink
      activeClassName={cx("active")}
      to={to}
      data-kind={kind}
      className={cx("link", className)}
      {...props}
    />
  );
};
