import { Coin } from "@api/entitiesService/types";
import { Loan } from "@api/loansService/types";
import { Offer } from "@api/offersService/types";
import { precisions } from "@configs/constants";
import { getAddress } from "ethers/lib/utils";
import { calculateAPR } from "shared/libs/calculate-apr";
import { secondsToDays } from "shared/libs/format-days";
import { maskAddress } from "shared/libs/mask-address";
import { pluralize } from "shared/libs/pluralize";
import { weiToEth } from "shared/libs/wei-to-eth";

type Params = {
  offer: Offer;
  coins: Record<string, Coin>;
};

export const normalizeOffer = ({ offer, coins }: Params) => {
  const coinName = coins[offer.loanERC20Denomination].currencySymbol || "unknown";
  const apr = calculateAPR({
    loan: weiToEth(offer.loanPrincipalAmount),
    repayment: weiToEth(offer.maximumRepaymentAmount, {
      maxDigitsAfterComma: precisions.repayment,
    }),
    days: secondsToDays(offer.loanDuration),
  });
  const expired = offer.expiry * 1000 <= Date.now();

  return {
    id: offer.id,
    nonce: offer.nonce,
    collateral: offer.collateral,
    expired,
    lender: {
      address: offer.lender.address,
      nickname:
        offer.lender.nickname ||
        maskAddress(offer.lender.address, { from: 4, to: offer.lender.address.length - 5 }),
    },
    duration: pluralize(secondsToDays(offer.loanDuration), "day", "s"),
    amount: `${weiToEth(offer.loanPrincipalAmount)} ${coinName}`,
    amountWithAPR: `${weiToEth(offer.maximumRepaymentAmount, {
      maxDigitsAfterComma: precisions.repayment,
    })} ${coinName}`,
    apr: `${apr}%`,
  };
};

export type NormalizedOffer = ReturnType<typeof normalizeOffer>;
