import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import styles from "./styles.module.scss";
import cn from "classnames/bind";

const cx = cn.bind(styles);

type Props = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

export const Spinner: FunctionComponent<Props> = ({ className, ...rest }) => (
  <span className={cx("spinner", className)} {...rest}>
    <span className={cx("item")}></span>
    <span className={cx("item")}></span>
    <span className={cx("item")}></span>
    <span className={cx("item")}></span>
    <span className={cx("item")}></span>
    <span className={cx("item")}></span>
    <span className={cx("item")}></span>
    <span className={cx("item")}></span>
    <span className={cx("item")}></span>
    <span className={cx("item")}></span>
    <span className={cx("item")}></span>
    <span className={cx("item")}></span>
  </span>
);
