import cn from "classnames/bind";
import { FunctionComponent } from "react";
import { Icon, Spinner, Typography } from "shared/ui/elements";

import styles from "./styles.module.scss";

const cx = cn.bind(styles);

type Props = {
  title: string;
  description?: string | null;
};

export const Error: FunctionComponent<Props> = ({ title, description }) => (
  <div className={cx("box")}>
    <div className={cx("header")}>
      <Icon.ExclamationTriangle />
      <Typography kind="body1" className={cx("title")}>
        {title}
      </Typography>
    </div>
    <Typography kind="body2" className={cx("description")}>
      {description}
    </Typography>
  </div>
);
