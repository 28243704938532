import { DetailedHTMLProps, FunctionComponent, ImgHTMLAttributes, useRef, useState } from "react";
import cn from "classnames/bind";

import styles from "./styles.module.scss";
import { Skeleton, Typography } from "shared/ui/elements";

type Props = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const cx = cn.bind(styles);

export const Image: FunctionComponent<Props> = ({ className, src, alt, ...props }) => {
  const hasSrc = Boolean(src);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const imageRef = useRef<HTMLImageElement>(null);

  const handleOnLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setHasError(true);
    setIsLoading(false);
  };

  return (
    <Skeleton active={isLoading}>
      <div className={cx("box", className, { hasError })}>
        {hasError && (
          <Typography kind="body2" className={cx("error")}>
            Broken Image {alt && `(${alt})`}
          </Typography>
        )}
        <img
          {...props}
          src={src}
          alt={alt}
          ref={imageRef}
          className={cx("image", { hasSrc })}
          onLoad={handleOnLoad}
          onError={handleError}
        />
      </div>
    </Skeleton>
  );
};
