import { Coin } from "@api/entitiesService/types";
import { Loan } from "@api/loansService/types";
import { precisions } from "@configs/constants";
import { getAddress } from "ethers/lib/utils";
import { calculateAPR } from "shared/libs/calculate-apr";
import { secondsToDays } from "../format-days";
import { maskAddress } from "../mask-address";
import { pluralize } from "../pluralize";
import { weiToEth } from "../wei-to-eth";

type Params = {
  loan: Loan;
  account: string;
  coins: Record<string, Coin>;
};

export type NormalizedLoan = Omit<Loan, "expiresAt"> & {
  terms: Loan["terms"] & {
    apr: string;
    duration: string;
  };
  expiresAt: string;
};

export const normalizeLoan = ({ loan, account, coins }: Params): NormalizedLoan => {
  const currencySymbol = coins[loan.terms.coinAddress].currencySymbol || "unknown";
  const apr = calculateAPR({
    loan: weiToEth(loan.terms.amount),
    repayment: weiToEth(loan.terms.amountWithAPR, {
      maxDigitsAfterComma: precisions.repayment,
    }),
    days: secondsToDays(loan.terms.lendSeconds),
  });

  return {
    ...loan,
    borrower: {
      ...loan.borrower,
      nickname:
        getAddress(loan.borrower.address) === getAddress(account)
          ? "You"
          : loan.borrower.nickname ||
            maskAddress(loan.borrower.address, {
              from: 4,
              to: loan.borrower.address.length - 5,
            }),
    },
    lender: {
      ...loan.lender,
      nickname:
        getAddress(loan.lender.address) === getAddress(account)
          ? "You"
          : loan.lender.nickname ||
            maskAddress(loan.lender.address, {
              from: 4,
              to: loan.lender.address.length - 5,
            }),
    },
    terms: {
      ...loan.terms,
      amount: `${weiToEth(loan.terms.amount)} ${currencySymbol}`,
      amountWithAPR: `${weiToEth(loan.terms.amountWithAPR, {
        maxDigitsAfterComma: precisions.repayment,
      })} ${currencySymbol}`,
      apr: `${apr}%`,
      duration: pluralize(secondsToDays(loan.terms.lendSeconds), "day", "s"),
    },
    expiresAt: new Date(loan.expiresAt * 1000).toLocaleDateString(),
  };
};
