import { createDomain, Domain, Effect } from "effector";

type Statuses = "idle" | "pending" | "failed" | "succeed";

const statusesMap: Record<Statuses, Statuses> = {
  idle: "idle",
  pending: "pending",
  failed: "failed",
  succeed: "succeed",
};

type Options = {
  domain?: Domain;
};

export const createEffectState = (fx: Effect<any, any> | Effect<any, any>[], options?: Options) => {
  const domain = options?.domain || createDomain();

  const $status = domain.createStore<Statuses>(statusesMap.idle);
  const $error = domain.createStore<string | null>(null);
  const fxList = Array.isArray(fx) ? fx : [fx];

  fxList.forEach((fx) => {
    $status
      .on(fx, () => statusesMap.pending)
      .on(fx.done, () => statusesMap.succeed)
      .on(fx.fail, () => statusesMap.failed);

    $error.reset(fx).on(fx.failData, (_, error) => error.message);
  });

  return {
    $status,
    $error,
  };
};
