import { ListingService } from "@api/listingService";
import { primaryLazyLoadLimit } from "@configs/constants";
import { attach, createDomain, createEffect, createEvent, createStore, sample } from "effector";
import { createDataList } from "shared/libs/create-data-list";
import { FetchDataListParams } from "shared/libs/create-data-list/types";
import { $defaultAccount } from "shared/libs/effector-metamask";
import { toast } from "shared/libs/toast";
import { ToastOptions } from "shared/libs/toast/types";

const ownListingsDomain = createDomain();

const fetchOwnListingsFx = attach({
  source: $defaultAccount,
  effect: async (borrower, params: FetchDataListParams) => {
    return ListingService.getAll({
      borrower,
      ...params,
    });
  },
});

const ownListings = createDataList({
  domain: ownListingsDomain,
  effect: fetchOwnListingsFx,
  limit: primaryLazyLoadLimit,
});

const $cancelingAssetsMap = createStore<Record<string, boolean>>({});

const cancelListing = createEvent<string>();
const cancelListingFx = createEffect(ListingService.cancel);

sample({
  clock: cancelListing,
  target: cancelListingFx,
});

sample({
  clock: cancelListingFx,
  source: $cancelingAssetsMap,
  fn: (current, id) => ({
    ...current,
    [id]: true,
  }),
  target: $cancelingAssetsMap,
});

sample({
  clock: cancelListingFx.finally,
  source: $cancelingAssetsMap,
  fn: (current, { params }) => {
    const copy = { ...current };

    delete copy[params];

    return copy;
  },
  target: $cancelingAssetsMap,
});

sample({
  clock: cancelListingFx.done,
  target: ownListings.update,
});

sample({
  clock: cancelListingFx.done,
  fn: (): ToastOptions => ({
    title: "Asset has been unlisted",
    kind: "success",
    duration: 3000,
  }),
  target: toast.show,
});

export { ownListings, cancelListing, $cancelingAssetsMap, cancelListingFx };
