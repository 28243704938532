import { FunctionComponent } from "react";
import { Typography } from "shared/ui/elements";

import styles from "./styles.module.scss";

type Props = {
  label: string | number | null | JSX.Element;
  value: string | number | null | JSX.Element;
  kind?: "primary" | "secondary";
};

export const ReadOnlyField: FunctionComponent<Props> = ({ label, value, kind = "primary" }) => (
  <div className={styles.box} data-kind={kind}>
    <Typography className={styles.label} kind="body2">
      {label}:
    </Typography>
    <Typography className={styles.value} kind="body2" weight="bold">
      {value}
    </Typography>
  </div>
);
